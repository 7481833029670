import { useLocation } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import useMediaQuery from "../usematchqueryfortrackscreensize/useMediaQuery";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import eventCard from "../assets/eventCard.png";

const TextOutputWindow = () => {
    const location = useLocation();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get("id");
    const [serviceName, setserviceName] = useState("");
    const [senderName, setsenderName] = useState("");
    const [typeofmessage, settypeofmessage] = useState("");
    const [textmessage, settextmessage] = useState("");
    const [dateTime, setdateTime] = useState("");
    const [address, setaddress] = useState("");
    const [mapLink, setmapLink] = useState("");
    const backendUrl =
        process.env.REACT_APP_ENVIRONMENT === "production"
            ? "https://backend.zmqrcode.com"
            : "http://localhost:3000";


    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${backendUrl}/purchaseQrCodeUserForText/${id}`
                );

                if (isMounted) {
                    setData(response.data);
                }
            } catch (err) {
                if (isMounted) setError(err);
            } finally {
                if (isMounted) setLoading(false);
            }
        };

        fetchData();

        return () => {
            isMounted = false;
        };
    }, [id, backendUrl]);


    useEffect(() => {
        if (data) {
            setserviceName(data.serviceName || "Not Filled");
            setsenderName(data.senderName || "Not Filled");
            settypeofmessage(data.typeofmessage || "Not Filled");
            settextmessage(data.textmessage || "Not Filled");
            setdateTime(data.dateTime || "Not Filled");
            setaddress(data.address || "Not Filled");
            setmapLink(data.mapLink || "Not Filled");
        }
    }, [data]);

    const renderEventDetails = () => {
        if (loading) return <div>Loading...</div>;
        if (error) return <div>Error: {error.message}</div>;

        return (
            <div className="flex flex-col items-center bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen py-8 px-4">
                <div className="flex flex-col justify-center items-center p-6 sm:p-8 md:p-10 rounded-xl transform transition duration-500 hover:scale-105 hover:shadow-3xl">
                    <div
                        className="flex flex-col rounded-xl text-[#fff] shadow-lg p-6 sm:p-8 md:p-10 relative overflow-hidden perspective-3d"
                        style={{
                            backgroundImage: `url(${eventCard})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                        }}
                    >
                        {/* Background Overlay */}
                        <div className="absolute inset-0 bg-gradient-to-t from-[#690e0e] to-transparent opacity-80 z-0 hover:scale-105 transition-transform duration-700"></div>

                        {/* White Card */}
                        <div className="flex flex-col items-center w-full max-w-[700px] p-6 sm:p-8 md:p-10 bg-white shadow-lg rounded-xl border border-gray-300 z-10 transform hover:-rotate-1 transition duration-500">
                            <div className="flex flex-col items-center mb-6 sm:mb-8 md:mb-10">
                                {/* Text with hover effect */}
                                <label className="font-raleway font-bold text-[25px] sm:text-[28px] md:text-[32px] text-gray-800 tracking-wide transition-all duration-500 hover:scale-110 hover:text-[rgb(236,54,130)]">
                                    {serviceName}
                                </label>
                                <label className="font-raleway font-semibold text-[18px] sm:text-[22px] md:text-[24px] text-gray-800 transition-all duration-300 hover:scale-105 hover:text-[rgb(236,54,130)]">
                                    {`Sender Name: ${senderName}`}
                                </label>
                                <label className="font-raleway text-[16px] sm:text-[18px] text-gray-800 transition-all duration-00 hover:scale-105 hover:text-[rgb(236,54,130)]">
                                    {`Message Type: ${typeofmessage}`}
                                </label>
                            </div>

                            {/* Message Details */}
                            <div className="flex flex-col text-center items-center justify-center text-gray-800 space-y-3 sm:space-y-4">
                                <label className="font-raleway font-bold text-[16px] sm:text-[18px] transition-all duration-300 hover:scale-105 hover:text-[rgb(236,54,130)]">
                                    {`Message: ${textmessage}`}
                                </label>
                                <label className="font-raleway text-[16px] sm:text-[18px] transition-all duration-300 hover:scale-105 hover:text-[rgb(236,54,130)]">
                                    {`Date / Time: ${dateTime}`}
                                </label>
                                <label className="font-raleway text-[16px] sm:text-[18px] transition-all duration-300 hover:scale-105 hover:text-[rgb(236,54,130)]">
                                    {`Sender Address: ${address}`}
                                </label>
                                <label className="font-raleway mt-4 flex items-center">
                                    <FontAwesomeIcon
                                        icon={faMapMarkerAlt}
                                        className={`mr-2 transition-transform duration-300 ${data?.mapLink ? 'text-red-500 hover:scale-125' : 'text-gray-400'
                                            }`}
                                    />
                                    {data?.mapLink ? (
                                        <a
                                            href={data.mapLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-blue-500 underline transition-all duration-300 hover:scale-105 hover:underline-offset-4"
                                        >
                                            View on Map
                                        </a>
                                    ) : (
                                        <span className="text-gray-500">Not Available</span>
                                    )}
                                </label>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    };

    return <div>{renderEventDetails()}</div>;
};

export default TextOutputWindow;
