import React, { useState, useRef, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faTimes,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import LoginUp from "../UserRegistrayion/LoginUp";
import SignUp from "../UserRegistrayion/SignUP";
import { ColorRing } from "react-loader-spinner";

import {
  setPlanName,
  setPrice,
} from "../store/chooseBestOffers/BestOffersSlicer";
import { setInstanceId } from "../store/ServiceSavedInstance/ServiceInstanceReducer";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { setReset } from "../store/downloadButtonRefernecSliceReducer";
import CustomAlert from "../CustomAlertForSubmision";
import styles from "../module/navbar.module.css";

const StudentsForm = () => {
  const fileInputRef = useRef(null);
  const [showPassword, setShowPassword] = useState(false);
  const [files, setFiles] = useState([]);
  const [switchButton, setSwitchButton] = useState(true);
  const [isLogin, setIsLogin] = useState(false);
  const dispatch = useDispatch();
  const [filePaths, setFilePath] = useState([]);
  const [serviceName, setServiceName] = useState("");
  const profile = useSelector((state) => state.profile);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [loadingForLogoImage, setLoadingForLogoImage] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState("");
  const [buttonText, setButtonText] = useState("Submit");
  const [filesForLogo, setFilesForLogo] = useState([]);
  const [filePathForLogo,setFilePathForLogo] = useState([]);
  const userProfile = useSelector((state) => state.profile);
  const [disableButton , setDisableButton] = useState(false);

  const backendUrl =
    process.env.REACT_APP_ENVIRONMENT === "production"
      ? "https://backend.zmqrcode.com"
      : "http://localhost:3000";

  useEffect(() => {
    const queryParam = new URLSearchParams(location.search).get("name");
    if (queryParam) {
      setServiceName(queryParam);
    }
  }, [location]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };



 

  const chooseFileHandleChange = async (event, setFieldValue) => {
    localStorage.removeItem("serviceInstanceId");
    setButtonText("Submit");

    if (profile.username !== "" && event.target.name !== "schoolImgfileforlogo") {
      setLoading(true);
      const selectedFiles = Array.from(event.target.files);
      setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
      setFieldValue("files", selectedFiles);

      const formData = new FormData();
      selectedFiles.forEach((file) => {
        formData.append("file", file);
      });

      try {
        const uploadData = await postUploadFile(
          `${backendUrl}/upload`,
          formData
        );

        const data = uploadData.data;

        if (Array.isArray(data)) {
          const newFilePaths = data.map((item) => item.url);

          setFilePath((prevFilePaths) => [...prevFilePaths, ...newFilePaths]);
        } else {
          alert("Data is not an array", data);
        }

        dispatch(setReset());
      } catch (error) {
        const errorMessage = error.message || "An error occurred";
        const statusCode =
          error.response && error.response.status
            ? error.response.status.toString()
            : "No status code";
        setShowAlert(true);
        setAlertMessage(
          `Error uploading files: ${errorMessage} (Status Code: ${statusCode})`
        );
        console.error("Error uploading files:", error);
      } finally {
        setLoading(false);
      }
    } else   if (profile.username !== "" && event.target.name === "schoolImgfileforlogo") {
        setLoading(true);
        const selectedFiles = Array.from(event.target.files);
        setFilesForLogo((prevFiles) => [...prevFiles, ...selectedFiles]);
        const formData = new FormData();
        selectedFiles.forEach((file) => {
          formData.append("file", file);
        });
  
        try {
          const uploadData = await postUploadFile(
            `${backendUrl}/upload`,
            formData
          );
  
          const data = uploadData.data;
  
          if (Array.isArray(data)) {
            const newFilePaths = data.map((item) => item.url);
            setFilePathForLogo((prevFilePaths) => [...prevFilePaths, ...newFilePaths]);
          } else {
            alert("Data is not an array", data);
          }
  
          dispatch(setReset());
        } catch (error) {
          const errorMessage = error.message || "An error occurred";
          const statusCode =
            error.response && error.response.status
              ? error.response.status.toString()
              : "No status code";
          setShowAlert(true);
          setAlertMessage(
            `Error uploading files: ${errorMessage} (Status Code: ${statusCode})`
          );
          console.error("Error uploading files:", error);
        } finally {
          setLoading(false);
        }
      } else {
      setIsLogin(true);
    }
  };

  const postUploadFile = async (url, formData) => {
    try {
      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      setShowAlert(true);
      setAlertMessage("Error uploading files:", error);
      throw error;
    }
  };

  const removeFile = (index, name="school logo") => {

    if(name==='forstudent'){
    setFiles((prevFiles) => {
      const updatedFiles = prevFiles.filter((_, i) => i !== index);

      // Remove corresponding file path
      setFilePath((prevFilePaths) =>
        prevFilePaths.filter((_, i) => i !== index)
      );

      // Update localStorage after the files have been updated
      if (updatedFiles.length > 0) {
        const resultFile = updatedFiles.map((file) => ({ name: file.name }));
        localStorage.setItem("files", JSON.stringify(resultFile));
      }

      return updatedFiles;
    });

    // Clear file input if it was the last file
    if (files.length === 1 && fileInputRef.current) {
      fileInputRef.current.value = "";
    }

}

if(name === "school logo"){
    setFilesForLogo((prevFiles) => {
      const updatedFiles = prevFiles.filter((_, i) => i !== index);

      // Remove corresponding file path
      setFilePathForLogo((prevFilePaths) =>
        prevFilePaths.filter((_, i) => i !== index)
      );

      // Update localStorage after the files have been updated
      if (updatedFiles.length > 0) {
        const resultFile = updatedFiles.map((file) => ({ name: file.name }));
        localStorage.setItem("files", JSON.stringify(resultFile));
      }

      return updatedFiles;
    });

    // Clear file input if it was the last file
    if (files.length === 1 && fileInputRef.current) {
      fileInputRef.current.value = "";
    }

}

  };
  const handleSubmitStudentsForms = async (values, { resetForm }) => {
    if (profile.username !== "") {
      const formData = {
        serviceName: serviceName,
        studentName: values.studentName,
        studentDOB: values.studentDOB,
        gradeInfo: values.gradeInfo,
        schoolName: values.schoolName,
        schoolAddress: values.schoolAddress,
        schoolAddressMap: values.schoolAddressMap,
        parentName: values.parentName,
        address: values.address,
        contactNumber: values.contactNumber,
        alternateContactNumber: values.alternateContactNumber,
        mapLink: values.mapLink,
        filePaths: filePaths,
        filePathForLogo: filePathForLogo,
        QrCodePassword: values.password || "",
        userId: profile.userid,
      };
      try {
        if (filePaths.length >= 1) {
          const response = await axios.post(
            `${backendUrl}/purchaseQrCodeUserForStudentForm`,
            formData
          );
          const purchasedServiceId = response.data.url;
          if (purchasedServiceId) {
            dispatch(setInstanceId(purchasedServiceId));
            dispatch(setPlanName("Best Plan for Student Card"));
            dispatch(setPrice("99"));
          }

          setButtonText("Submitted");
        }

        resetForm();
        setFiles([]);
        setFilesForLogo([]);
        setFilePathForLogo([]);
        setFilePath([]);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      } catch (error) {
        console.error("Error creating service instance:", error);
      }
    } else {
      setIsLogin(true);
    }
  };


  useEffect(() => {
    if ((filePaths.length > 1)||(filePathForLogo.length>1)) {
      alert("You can upload a maximum of 1 image.");
      setDisableButton(true); // Disable the button
    } else {
      setDisableButton(false); // Enable the button if valid
    }
  });

//   const BestPlans = useSelector((state) => state.Bestplans);
//   useEffect(() => {
//     if (BestPlans.price !== null && BestPlans.price !== 0) {
//       setAlertMessage(
//         `Your current Plan ${BestPlans.planName} and price:${BestPlans.price}`
//       );
//       setShowAlert(true);
//     }
//   }, [BestPlans]);


useEffect(()=>{
    
})

  const setLink = (setFieldValue, name ) => {
  
    if (navigator.geolocation) {
  
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
            
          const mapLink = `https://www.google.com/maps/?q=${latitude},${longitude}`;
          

          if(name==='maplink'){
            setFieldValue("mapLink", mapLink);
          }
          if(name==='schoolmaplink'){
            setFieldValue("schoolAddressMap", mapLink)
          }
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const CustomHandleChange = (handleChange) => (event) => {
    handleChange(event);
    if (event.target.value !== "") {
      setButtonText("Submit");
      dispatch(setReset());
    }
  };
  return (
    <div className="max-w-lg mx-auto">
      <h1 className="text-2xl font-bold text-center mb-6">
        QR Code Generator for Students Info form
      </h1>
      <Formik
        initialValues={{
          studentName: "",
          studentDOB: "",
          gradeInfo: "",
          schoolName: "",
          schoolAddress: "",
          schoolAddressMap: "",
          parentName: "",
          contactNumber: "",
          alternateContactNumber: "",
          address: "",
          mapLink: "",
          filePaths: [],
          filePathForLogo: [],
        }}
        onSubmit={handleSubmitStudentsForms}
      >

        {({ values, setFieldValue, handleChange, isSubmitting }) => (
          <Form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            {/* Child Name Input */}
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="schoolName"
              >
                College/School Name
              </label>
              <Field
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                name="schoolInfo"
                placeholder="Enter the College/School Name"
              />
            </div>

            <div className="mb-4">
             <div>  
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="file"
              >
                College/School Image For Logo 
              </label>
              <input
                id="file"
                name="schoolImgfileforlogo"
                type="file"
                accept="image/*"
                multiple
                ref={fileInputRef}
                onChange={(event) =>
                  chooseFileHandleChange(event, setFieldValue)
                }
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              {loading && (
                <ColorRing
                  visible={true}
                  height="40"
                  width="30"
                  ariaLabel="comment-loading"
                  wrapperStyle={{}}
                  wrapperClass="comment-wrapper"
                  colors={[
                    "#1D91AA",
                    "#1D91AA",
                    "#1D91AA",
                    "#1D91AA",
                    "#1D91AA",
                  ]} // This applies the same color to all circles
                />
              )}
            </div>  
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="schoolAddress"
              >
                College/School Address
              </label>
              <Field
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                as="textarea"
                name="schoolAddress"
                placeholder="Enter the full address of the College/School"
              />
            </div>
            {filesForLogo.map((file, index) => (
              <div
                key={index}
                className="flex items-center justify-between mt-2"
              >
                <label className="text-gray-700 w-[80%] inline">{file.name}</label>
                {!loading && (
                    <button
                      type="button" // Ensures this button does not act as a submit button
                      onClick={() => removeFile(index)} // Calls removeFile function with the file index
                      className="text-red-500 hover:text-red-700"
                      aria-label={`Remove file at index ${index}`} // Accessibility improvement
                    >
                      <FontAwesomeIcon icon={faTimes} className="text-red" />
                    </button>
                  )}
              </div>
            ))}
            <div className="mb-4 flex items-center justify-center">
              <div className="flex flex-col w-full">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="schoolAddressMap"
                >
                  College/School Map Link
                </label>
                <Field
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  name="schoolAddressMap"
                  placeholder="Enter a Google Maps link (optional)"
                  onChange={CustomHandleChange(handleChange)}
                />
              </div>
              <button
                type="button"
                className="ml-2 border-none flex justify-center items-center mt-5 p-[5px]"
                style={{ width: "0px" }}
                onClick={() => setLink(setFieldValue, "schoolmaplink")}
              >
                <FontAwesomeIcon icon={faGlobe} size="1x" color="#1D91AA" />
              </button>
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="studentName"
              >
                Student Name
              </label>
              <Field
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                name="studentName"
                placeholder="Enter the student's name"
              />
            </div>
            {/* Child Date of Birth Input */}
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="studentDOB"
              >
                Student Date of Birth
              </label>
              <Field
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="date"
                name="studentDOB"
                placeholder="Enter the student's date of birth"
              />
            </div>
            {/* File Input */}
            <div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="file"
              >
                Student Image For Profile
              </label>
              <input
                id="file"
                name="fileforLogo"
                type="file"
                multiple
                ref={fileInputRef}
                onChange={(event) =>
                  chooseFileHandleChange(event, setFieldValue)
                }
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              {loading && (
                <ColorRing
                  visible={true}
                  height="40"
                  width="30"
                  ariaLabel="comment-loading"
                  wrapperStyle={{}}
                  wrapperClass="comment-wrapper"
                  colors={[
                    "#1D91AA",
                    "#1D91AA",
                    "#1D91AA",
                    "#1D91AA",
                    "#1D91AA",
                  ]} // This applies the same color to all circles
                />
              )}
            </div>
            </div>
            {files.map((file, index) => (
              <div
                key={index}
                className="flex items-center justify-between mt-2"
              >
                <label className="text-gray-700 w-[80%] inline">{file.name}</label>
                {!loading && (
                    <button
                      type="button" // Ensures this button does not act as a submit button
                      onClick={() => removeFile(index,'forstudent')} // Calls removeFile function with the file index
                      className="text-red-500 hover:text-red-700"
                      aria-label={`Remove file at index ${index}`} // Accessibility improvement
                    >
                      <FontAwesomeIcon icon={faTimes} className="text-red" />
                    </button>
                  )}
              </div>
            ))}
            {/* Class of Child Input */}
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="gradeInfo"
              >
                Grade / Class of Student
              </label>
              <Field
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                name="gradeInfo"
                placeholder="Enter the class or grade of the child"
              />
            </div>
            {/* School Information Input */}
           
            {/* School Address Input */}
       

            {/* Parent Name Input */}
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="parentName"
              >
              Father's Name
              </label>
              <Field
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                name="parentName"
                placeholder="Enter the parent's name"
              />
            </div>

            {/* Parent Contact Input */}
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="parentContact"
              >
                Contact Number
              </label>
              <Field
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                name="contactNumber"
                placeholder="Enter the parent's contact number"
              />
            </div>

            {/* Parent Email Input */}
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="alternateContactNumbe"
              >
                Alternate Contact Number
              </label>
              <Field
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                name="alternateContactNumber"
                placeholder="Enter the parent's email"
              />
            </div>

            {/* Address Input */}
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="address"
              >
                Home Address
              </label>
              <Field
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                as="textarea"
                name="address"
                placeholder="Enter the address"
              />
            </div>
            {/* Map Link Input */}
            <div className="mb-4 flex items-center justify-center">
              <div className="flex flex-col w-full">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="mapLink"
                >
                  Home Map Link
                </label>
                <Field
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  name="mapLink"
                  placeholder="Enter a Google Maps link (optional)"
                  onChange={CustomHandleChange(handleChange)}
                />
              </div>
              <button
                type="button"
                className="ml-2 border-none flex justify-center items-center mt-5 p-[5px]"
                style={{ width: "0px" }}
                onClick={() => setLink(setFieldValue,'maplink')}
              >
                <FontAwesomeIcon icon={faGlobe} size="1x" color="#1D91AA" />
              </button>
            </div>
            {/* Password input */}
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="password"
              >
                Password
              </label>
              <div className="flex items-center relative">
                <Field
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Enter the Password"
                  onChange={CustomHandleChange(handleChange)}
                />
                {/* Password visibility toggle button outside, positioned next to the input */}
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="ml-2 text-gray-600 hover:text-gray-900"
                  style={{ width: "0px" }}
                >
                  <FontAwesomeIcon
                    icon={showPassword ? faEye : faEyeSlash}
                    size="1x"
                    color="#1D91AA"
                  />
                </button>
              </div>
            </div>

            {/* Submit button */}
            <div className="flex items-center justify-between">
             {  !disableButton &&  <button
                className=" text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
                style={{ backgroundColor: "#1D91AA" }}
                disabled={isSubmitting}
              >
                {buttonText}
              </button>
              }
            </div>
          </Form>
        )}
      </Formik>
      {showAlert && (
        <CustomAlert
          message={alertMessage}
          onClose={() => {
            setShowAlert(false);
          }}
        />
      )}
      {isLogin && (
        <div className="flex justify-center items-center h-screen">
          {/* Trigger button */}
          <button
            onClick={() => setIsLogin(true)}
            className="bg-blue-500 text-white p-3 rounded-lg"
          >
            Open Sign-Up/Login
          </button>

          {/* Modal */}
          {userProfile.username === "" && isLogin && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
              <div className="bg-white p-8 rounded-lg w-96">
                <div>
                  {/* Switch buttons */}
                  <div className="flex justify-end">
                    <button
                      onClick={() => setIsLogin(false)}
                      className="text-gray-600 hover:text-red-500"
                    >
                      X
                    </button>
                  </div>
                  <div className={styles.formsignupandlogin}>
                    <div className="flex flex-row">
                      <button
                        className={
                          switchButton ? styles.selected : styles.notselected
                        }
                        style={{
                          fontSize: "14px", // font-size becomes fontSize
                          margin: "15px", // margin
                          padding: "15px", // padding
                        }}
                        onClick={() => setSwitchButton(true)}
                      >
                        Sign Up
                      </button>
                      <button
                        className={
                          !switchButton ? styles.selected : styles.notselected
                        }
                        onClick={() => setSwitchButton(false)}
                      >
                        Login
                      </button>
                    </div>
                  </div>

                  {/* Conditional Form Rendering */}
                  {switchButton ? <SignUp /> : <LoginUp />}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default StudentsForm;
