import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebook, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { Link, useLocation } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import styles from './module/navbar.module.css';
import { useState } from 'react';
import axios from 'axios'; // Import axios for API call
import CustomAlert from './CustomAlertForSubmision';

const Footer = () => {
    // const openLocation = () => {
    //     const googleMapsURL = `https://www.google.com/maps?q=16.3048505,80.4435594`;
    //     window.open(googleMapsURL, '_blank');
    // };

    const [showAlert, setShowAlert] = useState(false);
    const [emailTextId, setEmailTextId] = useState("");

    const handleChange = (e) => {
        setEmailTextId(e.target.value);
    };


    //const devHost = '3.110.81.55'; // Use only the common part for both HTTP and HTTPS

    // Check if the window location starts with the devHost
    // const backendUrl = window.location.origin.startsWith(`https://${devHost}`) || window.location.origin.startsWith(`http://${devHost}`)
    //     ? process.env.REACT_APP_API_DEV_URL
    //     : process.env.REACT_APP_API_PROD_URL;

    const backendUrl = process.env.REACT_APP_ENVIRONMENT === 'production' ? 'https://backend.zmqrcode.com' : 'http://localhost:3000';


    const handleSubmit = async () => {
        const updatedData = JSON.stringify({ email: emailTextId }); // Correctly format data
        try {
            const response = await axios.post(`${backendUrl}/reachus`, updatedData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });// Log response to ensure it's successful
            setShowAlert(true); // Show custom alert on successful submission
            setEmailTextId(""); // Reset input field
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };

    const isEmailEmpty = emailTextId.trim() === "";
    const buttonColor = isEmailEmpty ? '#6a6d6d' : '#1D91AA';

    const location = useLocation();
    if ((location.pathname !== '/ClientRegistrationForm') && (location.pathname !== '/QrCodeGenerationform') && (location.pathname !== '/ThankyouMessage')) {
        return (
            <div className="bg-[#047171] text-white flex w-full flex-col p-6 justify-center items-center">
                <div className='flex justify-center align-center flex-col'>
                    <label className="text-2xl font-bold font-raleway mb-4 sm:text-xl">
                        Stay connected! Sign up for our newsletter to be the first to receive exclusive offers, updates, and expert tips.
                    </label>
                    <div className="flex mb-6 justify-center">
                        <input
                            className="border-none ml-2 p-1 text-black focus:outline-none w-[50%]"
                            placeholder="Enter Your Email Address"
                            value={emailTextId}
                            onChange={handleChange}
                        />
                        <button
                            className="bg-[#fff] p-2 rounded-r"
                            disabled={isEmailEmpty}
                            style={{ backgroundColor: buttonColor }}
                            onClick={handleSubmit} // Call handleSubmit on button click
                        >
                            <FontAwesomeIcon icon={faPaperPlane} size="lg" color="#fff" />
                        </button>
                    </div>
                    <div className='flex flex-col justify-center items-center'>
                        <div className="flex flex-col mb-6">
                            <label className="text-2xl font-bold font-raleway mb-4">
                                Follow Us for Insights and Announcements!
                            </label>
                            <div className="flex justify-center mt-2">
                                <a href="https://www.linkedin.com/company/zm-qr-code-services" className="mr-2 p-1" target="_blank"
                                    rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faLinkedin} size="2x" color="#fff" />
                                </a>
                                <a href='https://x.com/i/flow/login?redirect_after_login=%2Fzmqrcode' className="mr-2 p-1" target="_blank"
                                    rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faTwitter} size="2x" color="#fff" />
                                </a>
                                <a href='https://www.instagram.com/zmqrcode?igsh=YzljYTk1ODg3Zg==' className="mr-2 p-1" target="_blank"
                                    rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faInstagram} size="2x" color="#fff" />
                                </a>
                                <a href='https://www.facebook.com/people/ZMQRCodes/61559950102078/' className="mr-2 p-1" target="_blank"
                                    rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faFacebook} size="2x" color="#fff" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className={styles.contactUsContainer}>
                        <div className="flex flex-col items-center m-4 w-full sm:w-auto">
                            <label className="text-2xl font-bold font-raleway text-white mb-4">
                                Contact Us
                            </label>
                            <div className="flex flex-col items-center justify-center mt-2">
                                <div className='flex items-center mb-2'>
                                    <FontAwesomeIcon icon={faEnvelope} className="mr-2" color='#fff' />
                                    <p className='text-white font-bold font-raleway'>officalzmqrcodeservices@zmqrcode.com</p>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col items-center m-4 w-full sm:w-auto">
                            <label className="text-2xl font-bold font-raleway text-white mb-4">
                                Company
                            </label>
                            <div className="flex flex-col justify-center mt-2">
                                <Link to="/services" className="text-white text-sm py-1">QR CODE SERVICES</Link>
                                <AnchorLink href="#about-section" className="text-white text-sm py-1">About Us</AnchorLink>
                                <AnchorLink href="#contact-section" className="text-white text-sm py-1">Contact Us</AnchorLink>
                                <Link to="/faq" className="text-white text-lg py-2 px-4">FAQ</Link>
                                <Link to="/comingsoon" className="text-white text-lg py-2 px-4">Pricing</Link>
                                <Link to="/comingsoon" className="text-white text-sm py-1">Languages</Link>
                            </div>
                        </div>

                        <div className="flex flex-col items-center m-4 w-full sm:w-auto">
                            <label className="text-2xl font-bold font-raleway text-white mb-4">
                                Terms
                            </label>
                            <div className="flex flex-col justify-center mt-2">
                                <Link to='/terms&co' className='text-white text-sm py-1'>Terms And Conditions</Link>
                                <Link to='/privacypolicy' className='text-white text-sm py-1'>Privacy Policy</Link>
                                <Link to='/terms&co' className='text-white text-sm py-1'>Cookie Policy</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col p-[16px] sm:items-center justify-between sm:mt-0">
                    <div className='flex flex-col items-center mb-6'>
                        <img
                            src='/ZMQRCODEORIGINALOGO.jpg'
                            className='w-[155px] h-[95px] rounded-[11px] mb-4'
                            alt='Icon'
                        />
                        <div className={styles.footerInfoContainer}>
                            <p className='text-2xl font-bold font-raleway text-white mb-4 sm:mb-2'>
                                Generate a unique QR Code for your brand with us...
                            </p>
                            <label className='text-2xl font-bold font-raleway text-white mt-6 sm:text-xl'>
                                © 2024. All Rights Reserved. ZM QR Code Services
                            </label>
                        </div>
                    </div>
                </div>
                {showAlert && (
                    <CustomAlert
                        message={"Thanks for reaching out. Our team will get back to you shortly."}
                        onClose={() => setShowAlert(false)} // Properly set the state to false on close
                    />
                )}
            </div>
        );
    }
};

export default Footer;
