import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRupeeSign, faUsers } from '@fortawesome/free-solid-svg-icons';
import { setPrice, setPlanName } from '../components/store/chooseBestOffers/BestOffersSlicer'; // Adjust the path as needed
import StarRating from './StarRating'; // Adjust the path as needed

const PlansCard = ({ props }) => {
    const { name, description, price, storageRange, reviews = {} } = props;
    const { count = 0, rating = 0 } = reviews;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleClick = () => {
        dispatch(setPrice(price));
        dispatch(setPlanName(name));
        navigate('/services');
    };

    return (
        <div className="flex flex-col m-[15px] justify-center items-center w-full rounded-lg p-4 transition-all duration-150 ease-in-out transform group hover:bg-[#1D91AA] hover:text-white hover:shadow-lg">
            <div className="flex flex-col justify-center items-center">
                <label className="text-[20px] md:text-[25px] font-bold font-raleway transition-colors duration-150 ease-in-out group-hover:text-white">
                    {name}
                </label>
                <p className="text-[12px] md:text-[15px] font-extrabold font-raleway transition-colors duration-150 ease-in-out group-hover:text-white">
                    {description}
                </p>
            </div>
            <div className="flex items-center space-x-2 mt-2">
                <FontAwesomeIcon icon={faRupeeSign} size="lg" className="transition-colors duration-150 ease-in-out text-[#1D91AA] group-hover:text-white" />
                <label className="text-[28px] md:text-[34px] text-[#1D91AA] font-semibold font-raleway transition-colors duration-150 ease-in-out group-hover:text-white">
                    {price}
                </label>
                <label className="text-[28px] md:text-[34px] text-[#1D91AA] font-semibold font-raleway transition-colors duration-150 ease-in-out group-hover:text-white">
                    /
                </label>
                <label className="text-[20px] md:text-[25px] text-[#1D91AA] font-semibold font-raleway transition-colors duration-150 ease-in-out group-hover:text-white">
                    {storageRange}
                </label>
            </div>
            <div className="flex flex-col items-center mt-2">
                <div className="flex items-center space-x-2">
                    <FontAwesomeIcon icon={faUsers} size="sm" className="transition-colors duration-150 ease-in-out text-[#1D91AA] group-hover:text-white" />
                    <p className="text-[12px] md:text-[15px] text-[#1D91AA] font-semibold font-raleway transition-colors duration-150 ease-in-out group-hover:text-white">
                        Users: {count}
                    </p>
                </div>
                <StarRating rating={rating} color="customBlue" />
            </div>
            <button
                className="flex items-center justify-center mt-4 px-4 py-2 rounded-md transition-all duration-150 ease-in-out transform hover:scale-105 bg-white text-[#1D91AA] border-[#1D91AA] border-2 shadow-md hover:bg-[#1D91AA] hover:text-white hover:border-white"
                onClick={handleClick}
            >
                <span className="font-raleway text-[24px] md:text-[32px] font-medium">Buy Now</span>
            </button>
        </div>
    );
};

export default PlansCard;
