import { useLocation } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import petCard from "../assets/eventCard.png";

const PetOutputWindow = () => {
    const location = useLocation();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [paths, setFilePath] = useState([]);
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get("id");
   
    const backendUrl =
        process.env.REACT_APP_ENVIRONMENT === "production"
            ? "https://backend.zmqrcode.com"
            : "http://localhost:3000";

    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${backendUrl}/purchaseQrCodeUserForPet/${id}`
                );

                if (isMounted) {
                    setData(response.data);
                    const filePaths = response.data.filePaths || [];
                    let parsedFilePaths = [];

                    if (typeof filePaths === "string") {
                        try {
                            parsedFilePaths = JSON.parse(filePaths);
                        } catch (err) {
                            console.error("Error parsing filePaths:", err);
                        }
                    } else {
                        parsedFilePaths = filePaths;
                    }
                    setFilePath(parsedFilePaths);
                }
            } catch (err) {
                if (isMounted) setError(err);
            } finally {
                if (isMounted) setLoading(false);
            }
        };

        fetchData();

        return () => {
            isMounted = false;
        };
    }, [id, backendUrl]);

    const renderPetDetails = () => {
        if (loading) return <div>Loading...</div>;
        if (error) return <div>Error: {error.message}</div>;
        if (paths.length === 0) return <div>No Pet Images Available</div>;

        const handleDownload = (link) => {
            const downloadLink = document.createElement("a");
            downloadLink.href = link;
            downloadLink.download = "pet-image";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        };

        return (
            <div className="flex flex-col items-center bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen py-8 px-4">
            <div className="flex flex-col justify-center items-center p-6 sm:p-8 md:p-10 rounded-xl transform transition duration-500 hover:scale-105 hover:shadow-3xl">
                <div
                    className="flex flex-col rounded-xl text-[#fff] shadow-lg p-6 sm:p-8 md:p-10 relative overflow-hidden perspective-3d"
                    style={{
                        backgroundImage: `url(${petCard})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                >
                    {/* Background Overlay */}
                    <div className="absolute inset-0 bg-gradient-to-t from-[#690e0e] to-transparent opacity-80 z-0 hover:scale-100 transition-transform duration-700"></div>

                    {/* White Card */}
                    <div className="flex flex-col items-center hover:bg-gradient-to-br from-[#f6dce8] to-[#ee5b98] w-full max-w-[700px] p-6 sm:p-8 md:p-10 bg-white shadow-lg rounded-xl border border-gray-300 z-10 transform hover:-rotate-1 transition duration-500">
                        <div className="flex flex-col items-center mb-6 sm:mb-8 md:mb-10">
                            {/* Text with hover effect */}
                            {/* <label className="font-raleway font-bold text-[24px] sm:text-[28px] md:text-[32px] text-gray-800 tracking-wide transition-all duration-500 hover:scale-110 hover:text-[rgb(236,54,130)]">
                                {`${data?.serviceName}`}
                            </label> */}
                            <label className="font-raleway font-bold text-[30px] sm:text-[34px] md:text-[38px] text-gray-800 tracking-wide transition-all duration-500 hover:scale-110 hover:text-[rgb(236,54,130)]">
                                {`${data?.PetName}`}
                            </label>
                            <label className="font-raleway text-[16px] sm:text-[18px] text-gray-800 transition-all duration-00 hover:scale-105 hover:text-[rgb(236,54,130)]">
                            <span className="font-semibold">Breed:</span>{" "}
                            {`${data?.breedName || "Not Available"}`}
                            </label>
                        </div>

                        {/* Image Grid */}
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6 md:gap-8 lg:gap-10 mb-6 sm:mb-8 md:mb-10">
                            {paths.map((link, index) => {
                                // Check if this is the last row and it has one image
                                const isSingleImageInRow =
                                    paths.length % 2 !== 0 && index === paths.length - 1;

                                return (
                                    <div
                                        key={index}
                                        className={`flex flex-col items-center bg-gray-50 p-4 sm:p-6 rounded-lg shadow-xl transform transition duration-500 hover:scale-105 hover:shadow-2xl hover:bg-gradient-to-br from-[#fcfaff] to-[#d53067] ${isSingleImageInRow
                                                ? "lg:flex lg:justify-center lg:col-span-2"
                                                : ""
                                            }`}
                                    >
                                        <img
                                            src={link}
                                            alt={`Pet Image ${index + 1}`}
                                            className="w-full h-[200px] sm:h-[145px] md:h-[240px] object-contain rounded-lg mb-4 border-4 border-gray-200 shadow-md transform hover:translate-y-[-10px] transition-all duration-500"
                                        />
                                        <label className="font-raleway text-[14px] sm:text-[16px] md:text-[18px] text-gray-700 transition-all duration-300 hover:scale-105 hover:text-[rgb(236,54,130)]">
                                            {`Pet Image ${index + 1}`}
                                        </label>
                                        <div
                                            className="flex items-center cursor-pointer mt-2 bg-[#38b8b8] hover:bg-[#2a9898] transition duration-300 p-2 sm:p-3 rounded-full shadow-md hover:shadow-lg transform hover:scale-110"
                                            onClick={() => handleDownload(link)}
                                        >
                                            <FontAwesomeIcon
                                                icon={faDownload}
                                                className="h-[12px] sm:h-[16px] w-[16px] sm:w-[20px] text-white"
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>

                        {/* Pet Details */}
                        <div className="flex flex-col text-center items-center justify-center text-gray-800 space-y-3 sm:space-y-4">
                            <label className="font-raleway text-[16px] sm:text-[18px] transition-all duration-300 hover:scale-105 ">
                            <span className="font-semibold">Description:</span>{" "}   
                            {`${data?.Description || "Not Available"}`}
                            </label>
                            <label className="font-raleway text-[16px] sm:text-[18px] transition-all duration-300 hover:scale-105">
                            <span className="font-semibold">Owner Name:</span>{" "}     
                            {`${data?.ownerName || "Not Available"}`}
                            </label>
                            <label className="font-raleway text-[16px] sm:text-[18px] transition-all duration-300 hover:scale-105">
                            <span className="font-semibold">Email:</span>{" "}  
                            {`${data?.emailId || "Not Available"}`}
                            </label>
                            <label className="font-raleway text-[16px] sm:text-[18px] transition-all duration-300 hover:scale-105">
                            <span className="font-semibold">Phone:</span>{" "}
                            {`${data?.phoneNo || "Not Available"}`}
                            </label>
                            <label className="font-raleway text-[16px] sm:text-[18px] transition-all duration-300 hover:scale-105">
                            <span className="font-semibold">Date / Time:</span>{" "}
                            {`${data?.dateTime || "Not Available"}`}
                            </label>
                            <label className="font-raleway text-[16px] sm:text-[18px] transition-all duration-300 hover:scale-105">
                            <span className="font-semibold">Address:</span>{" "}    
                            {`${data?.address || "Not Available"}`}
                            </label>
                            <label className="font-raleway mt-4 flex items-center">
                                    <FontAwesomeIcon
                                        icon={faMapMarkerAlt}
                                        className={`mr-2 transition-transform duration-300 ${data?.mapLink ? 'text-red-500 hover:scale-125' : 'text-gray-400'
                                            }`}
                                    />
                                    {data?.mapLink ? (
                                        <a
                                            href={data.mapLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-blue-500 underline transition-all duration-300 hover:scale-105 hover:underline-offset-4"
                                        >
                                            View on Map
                                        </a>
                                    ) : (
                                        <span className="text-gray-500">Not Available</span>
                                    )}
                                </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
    };

    return <div>{renderPetDetails()}</div>;
};

export default PetOutputWindow;
