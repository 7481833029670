const plansForTB = [
    {
        "storageRange": "1 TB",
        "price": 79999,
        "name": "Mega Plan",
        "description": "Unmatched storage capacity for the most demanding users.",
        "reviews": {
            "count": 40000,
            "rating": 4.5
        }
    }
];

const convertBitsToTB = (byts) => {
    const sizeOffile = (byts / (Math.pow(1024, 4))).toFixed(2); // Convert to TB
    // Debugging

    for (let plan of plansForTB) {
        const [storageSize] = plan.storageRange.split(' '); // Extract the numeric part of storage in TB

        // Check if the file size fits within the current plan's range
        if (sizeOffile <= parseFloat(storageSize)) {
            return { price: plan.price, name: plan.name };; // Return the plan that fits the file size
        }
    }

    // If no plan fits, return a message or handle as needed
    return "No suitable plan found for the given file size.";
};

export default convertBitsToTB;
