import React, { useState, useRef, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faTimes, faGlobe } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import LoginUp from "../UserRegistrayion/LoginUp";
import SignUp from "../UserRegistrayion/SignUP";
import convertBitsToMb from "../convertbits/convertBitsToMb";
import convertBitsToGB from "../convertbits/convertbitstoGb";
import convertBitsToTB from "../convertbits/convertBitsToTB";
import {
    setPlanName,
    setPrice,
} from "../store/chooseBestOffers/BestOffersSlicer";
import { setInstanceId } from "../store/ServiceSavedInstance/ServiceInstanceReducer";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { ColorRing } from "react-loader-spinner";
import { setReset } from "../store/downloadButtonRefernecSliceReducer";
import CustomAlert from "../CustomAlertForSubmision";
import styles from "../module/navbar.module.css";

const EventForm = () => {
    const fileInputRef = useRef(null);
    const [showPassword, setShowPassword] = useState(false);
    const [files, setFiles] = useState([]);
    const [switchButton, setSwitchButton] = useState(true);
    const [isLogin, setIsLogin] = useState(false);
    const dispatch = useDispatch();
    const [filePaths, setFilePath] = useState([]);
    const [serviceName, setServiceName] = useState("");
    const profile = useSelector((state) => state.profile);
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [showAlert, setShowAlert] = useState("");
    const [buttonText, setButtonText] = useState("Submit");
    const userProfile = useSelector((state) => state.profile);

    const backendUrl =
        process.env.REACT_APP_ENVIRONMENT === "production"
            ? "https://backend.zmqrcode.com"
            : "http://localhost:3000";

    useEffect(() => {
        const queryParam = new URLSearchParams(location.search).get("name");
        if (queryParam) {
            setServiceName(queryParam);
        }
    }, [location]);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    useEffect(() => {
        const totalSize = files.reduce((acc, file) => acc + file.size, 0); // Total size in bytes
        if (totalSize > 0 && totalSize <= 52428800) {
            const plan = convertBitsToMb(totalSize, profile.usagecount);
            dispatch(setPlanName(plan.name));
            dispatch(setPrice(plan.price));
        } else if (totalSize > 52428800 && totalSize <= 96262144000) {
            const plan = convertBitsToGB(totalSize);
            dispatch(setPlanName(plan.name));
            dispatch(setPrice(plan.price));
        } else if (totalSize > 96262144000 && totalSize > 96262144000) {
            const plan = convertBitsToTB(totalSize);
            dispatch(setPlanName(plan.name));
            dispatch(setPrice(plan.price));
        }
    }, [files, dispatch]);

    const chooseFileHandleChange = async (event, setFieldValue) => {
        localStorage.removeItem("serviceInstanceId");
        setButtonText("Submit");
        if (profile.username !== "") {
            setLoading(true);
            const selectedFiles = Array.from(event.target.files);
            setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
            setFieldValue("files", selectedFiles);

            const formData = new FormData();
            selectedFiles.forEach((file) => {
                formData.append("file", file);
            });

            try {
                const uploadData = await postUploadFile(
                    `${backendUrl}/upload`,
                    formData
                );

                const data = uploadData.data;

                if (Array.isArray(data)) {
                    const newFilePaths = data.map((item) => item.url);

                    setFilePath((prevFilePaths) => [...prevFilePaths, ...newFilePaths]);
                } else {
                    alert("Data is not an array", data);
                }

                dispatch(setReset());
            } catch (error) {
                const errorMessage = error.message || "An error occurred";
                const statusCode =
                    error.response && error.response.status
                        ? error.response.status.toString()
                        : "No status code";
                setShowAlert(true);
                setAlertMessage(
                    `Error uploading files: ${errorMessage} (Status Code: ${statusCode})`
                );
                console.error("Error uploading files:", error);
            } finally {
                setLoading(false);
            }
        } else {
            setIsLogin(true);
        }
    };

    const postUploadFile = async (url, formData) => {
        try {
            const response = await axios.post(url, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            return response.data;
        } catch (error) {
            setShowAlert(true);
            setAlertMessage("Error uploading files:", error);
            throw error;
        }
    };

    const removeFile = (index) => {
        setFiles((prevFiles) => {
            const updatedFiles = prevFiles.filter((_, i) => i !== index);

            // Remove corresponding file path
            setFilePath((prevFilePaths) =>
                prevFilePaths.filter((_, i) => i !== index)
            );

            // Update localStorage after the files have been updated
            if (updatedFiles.length > 0) {
                const resultFile = updatedFiles.map((file) => ({ name: file.name }));
                localStorage.setItem("files", JSON.stringify(resultFile));
            }

            return updatedFiles;
        });

        // Clear file input if it was the last file
        if (files.length === 1 && fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    };

    const handleSubmitService = async (values, { resetForm }) => {

        if (profile.username !== "") {
            const formData = {
                serviceName: serviceName,
                eventName: values.eventName,
                organizerName: values.organizerName,
                typeOfEvent: values.typeOfEvent,
                Description: values.Description,
                dateTime: values.dateTime,
                place: values.state,
                filePaths: filePaths,
                address: values.address,
                mapLink: values.mapLink,
                QrCodePassword: values.password || "",
                userId: profile.userid,
            };
            try {
                if (filePaths.length >= 1) {
                    const response = await axios.post(
                        `${backendUrl}/purchaseQrCodeUserForEvent`,
                        formData
                    );
                    const purchasedServiceId = response.data.link;
                    if (purchasedServiceId) {
                        dispatch(setInstanceId(purchasedServiceId));
                    }
                    console.log(purchasedServiceId);

                    setButtonText("Submitted");

                }

                resetForm();
                setFiles([]); // Clear the files array
                setFilePath([]); // Clear the file paths
                if (fileInputRef.current) {
                    fileInputRef.current.value = ""; // Reset input field
                }
            } catch (error) {
                console.error("Error creating service instance:", error);
            }
        } else {
            setIsLogin(true);
        }
    };

    const BestPlans = useSelector((state) => state.Bestplans);
    useEffect(() => {
        if (BestPlans.price !== null && BestPlans.price !== 0) {
            setAlertMessage(
                `Your current Plan ${BestPlans.planName} and price:${BestPlans.price}`
            );
            setShowAlert(true);
        }
    }, [BestPlans]);

    const setLink = (setFieldValue) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    const mapLink = `https://www.google.com/maps/?q=${latitude},${longitude}`;
                    setFieldValue("mapLink", mapLink);
                },
                (error) => {
                    console.error("Error getting location:", error);
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
    };

    const CustomHandleChange = (handleChange) => (event) => {
        handleChange(event);
        if (event.target.value !== '') {
            setButtonText('Submit');
            dispatch(setReset());
        }
    };

    return (
        <div className="max-w-lg mx-auto">
            <h1 className="text-2xl font-bold text-center mb-6">QR Code Generator for Event</h1>
            <Formik
                initialValues={{
                    eventName: "",
                    organizerName: "",
                    Description: "",
                    typeOfEvent: "",
                    dateTime: "",
                    state: "",
                    address: "",
                    mapLink: "",
                    password: "",
                    files: [],
                }}
                onSubmit={handleSubmitService}
            >
                {({ setFieldValue, handleChange, isSubmitting }) => (
                    <Form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                        {/* Restaurant Name input */}
                        <div className="mb-4">
                            <label
                                className="block text-gray-700 text-sm font-bold mb-2"
                                htmlFor="eventName"
                            >
                                Event Name
                            </label>
                            <Field
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="text"
                                name="eventName"
                                placeholder="Enter the Event Name"
                            />
                        </div>

                        {/* orgainizer name input */}
                        <div className="mb-4">
                            <label
                                className="block text-gray-700 text-sm font-bold mb-2"
                                htmlFor="organizerName"
                            >
                                Orgainizer Name
                            </label>
                            <Field
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="text"
                                name="organizerName"
                                placeholder="Enter the OrganizerName"
                            />
                        </div>

                        {/* Type of Event Input */}
                        <div className="mb-4">
                            <label
                                className="block text-gray-700 text-sm font-bold mb-2"
                                htmlFor="typeOfEvent"
                            >
                                Type of Event
                            </label>
                            <Field
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="text"
                                name="typeOfEvent"
                                placeholder="e.g., Conference, Wedding, Festival"
                            />
                        </div>
                        {/* File input for event cards */}
                        <div>
                            <div className="mb-6">
                                <label
                                    className="block text-gray-700 text-sm font-bold mb-2"
                                    htmlFor="file"
                                >
                                    Add Event Cards/Images
                                </label>
                                <input
                                    id="file"
                                    name="file"
                                    type="file"
                                    accept="image/*"
                                    multiple
                                    ref={fileInputRef}
                                    onChange={(event) =>
                                        chooseFileHandleChange(event, setFieldValue)
                                    }
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                />
                                {loading && (
                                    <ColorRing
                                        visible={true}
                                        height="40"
                                        width="30"
                                        ariaLabel="comment-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="comment-wrapper"
                                        colors={['#1D91AA', '#1D91AA', '#1D91AA', '#1D91AA', '#1D91AA']} // This applies the same color to all circles
                                    />
                                )}
                            </div>
                            <div className="mt-2">
                                {files.map((file, index) => (
                                    <div
                                        key={index}
                                        className="flex items-center justify-between mt-2"
                                    >
                                        <span className="text-gray-700">{file.name}</span>
                                        <button
                                            type="button"
                                            onClick={() => removeFile(index)}
                                            className="text-red-500 hover:text-red-700"
                                        >
                                            <FontAwesomeIcon
                                                icon={faTimes}
                                                className="text-customBlue"
                                            />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* Description Input */}
                        <div className="mb-4">
                            <label
                                className="block text-gray-700 text-sm font-bold mb-2"
                                htmlFor="Description"
                            >
                                Description
                            </label>
                            <Field
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                as="textarea"
                                name="Description"
                                placeholder="Enter a brief description of the event"
                            />
                        </div>


                        {/* Date and Time Input */}
                        <div className="mb-4">
                            <label
                                className="block text-gray-700 text-sm font-bold mb-2"
                                htmlFor="dateTime"
                            >
                                Date and Time
                            </label>
                            <Field
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="dateTime-local"
                                name="dateTime"
                                placeholder="Enter the date and time of the event"
                            />
                        </div>

                        {/* Place Input */}
                        <div className="mb-4">
                            <label
                                className="block text-gray-700 text-sm font-bold mb-2"
                                htmlFor="state"
                            >
                                State
                            </label>
                            <Field
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="text"
                                name="state"
                                placeholder="Enter state of the Event"
                            />
                        </div>

                        {/* Address Input */}
                        <div className="mb-4">
                            <label
                                className="block text-gray-700 text-sm font-bold mb-2"
                                htmlFor="address"
                            >
                                Full Address
                            </label>
                            <Field
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                as="textarea"
                                name="address"
                                placeholder="Enter the address of the event"
                            />
                        </div>

                        {/* Map Link Input */}
                        <div className="mb-4 flex items-center justify-center">
                            <div className="flex flex-col w-full">
                                <label
                                    className="block text-gray-700 text-sm font-bold mb-2"
                                    htmlFor="mapLink"
                                >
                                    Map Link
                                </label>
                                <Field
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    type="text"
                                    name="mapLink"
                                    placeholder="Enter a Google Maps link (optional)"
                                    onChange={CustomHandleChange(handleChange)}
                                />
                            </div>
                            <button
                                type="button"
                                className="ml-2 border-none flex justify-center items-center mt-5 p-[5px]"
                                style={{ width: "0px" }}
                                onClick={() => setLink(setFieldValue)}
                            >
                                <FontAwesomeIcon icon={faGlobe} size="1x" color="#1D91AA" />
                            </button>
                        </div>


                        {/* Password input */}
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                                Password
                            </label>
                            <div className="flex items-center relative">
                                <Field
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    type={showPassword ? 'text' : 'password'}
                                    name="password"
                                    placeholder="Enter the Password"
                                />
                                {/* Password visibility toggle button outside, positioned next to the input */}
                                <button
                                    type="button"
                                    onClick={togglePasswordVisibility}
                                    className="ml-2 text-gray-600 hover:text-gray-900"
                                    style={{ width: "0px" }}
                                >
                                    <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} size="1x" color="#1D91AA" />
                                </button>
                            </div>
                        </div>


                        {/* Submit button */}
                        <div className="flex items-center justify-between">
                            <button
                                className=" text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                type="submit"
                                style={{ backgroundColor: "#1D91AA" }}
                                disabled={isSubmitting}
                            >
                                {buttonText}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
            {showAlert && <CustomAlert message={alertMessage} onClose={() => { setShowAlert(false); }} />}
            {isLogin && (
                <div className="flex justify-center items-center h-screen">
                    {/* Trigger button */}
                    <button
                        onClick={() => setIsLogin(true)}
                        className="bg-blue-500 text-white p-3 rounded-lg"
                    >
                        Open Sign-Up/Login
                    </button>


                    {/* Modal */}
                    {((userProfile.username === '') && isLogin) && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                            <div className="bg-white p-8 rounded-lg w-96">
                                <div>
                                    {/* Switch buttons */}
                                    <div className="flex justify-end">
                                        <button
                                            onClick={() => setIsLogin(false)}
                                            className="text-gray-600 hover:text-red-500"
                                        >
                                            X
                                        </button>
                                    </div>
                                    <div className={styles.formsignupandlogin}>
                                        <div className="flex flex-row">
                                            <button
                                                className={switchButton ? styles.selected : styles.notselected} style={{
                                                    fontSize: "14px", // font-size becomes fontSize
                                                    margin: "15px",   // margin
                                                    padding: "15px",  // padding
                                                }}
                                                onClick={() => setSwitchButton(true)}
                                            >
                                                Sign Up
                                            </button>
                                            <button
                                                className={!switchButton ? styles.selected : styles.notselected}
                                                onClick={() => setSwitchButton(false)}

                                            >
                                                Login
                                            </button>
                                        </div>
                                    </div>


                                    {/* Conditional Form Rendering */}
                                    {switchButton ? <SignUp /> : <LoginUp />}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default EventForm;
