import NavBar from "../NavBar";
import AboutUsHomePage from "../AboutUsHomePage";
import ContactUs from "../ContactUs";
import UserProfileSideBar from "./userProfilesidebar";
import UserProfileContent from "./UserProfileContent";
import useMediaQuery  from '../usematchqueryfortrackscreensize/useMediaQuery'
import UserProfielsidebarForMobile from "./UserProfielsidebarMobile";


const QrcodePurchased = () => {
    const isMobile = useMediaQuery('(max-width: 960px)');


    return (
        <div className="justify-center items-center">
            <NavBar />
            <div className="flex flex-row w-full" >
            { isMobile ? (<div className="flex flex-col items-center justify-center w-[90%] m-[16px]" >
                <UserProfielsidebarForMobile/>
                <UserProfileContent className="flex flex-col w-full m-4 rounded-lg h-auto items-center p-2 shadow-lg"/>
            </div>
            ) : (<div className="flex flex-row w-full" >
                 <UserProfileSideBar/> 
                <UserProfileContent className="flex flex-col w-[60%] m-4 rounded-lg h-auto items-center p-2 shadow-lg"/>
            </div>)
            }
            </div>
            <AboutUsHomePage />
            <ContactUs />
        </div>
    );

}

export default QrcodePurchased;