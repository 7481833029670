import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { setInstanceId } from '../store/ServiceSavedInstance/ServiceInstanceReducer';
import LoginUp from "../UserRegistrayion/LoginUp";
import SignUp from "../UserRegistrayion/SignUP";
import { setPlanName, setPrice } from '../store/chooseBestOffers/BestOffersSlicer';
import { setReset } from '../store/downloadButtonRefernecSliceReducer';

const LinkedinForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [serviceName, setServiceName] = useState("");
  const location = useLocation();
  const profile = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const [buttonText, setButtonText] = useState("Submit");
  const [isLogin, setIsLogin] = useState(false);
  const [switchButton, setSwitchButton] = useState(true);

  // Get service name from URL query parameter
  useEffect(() => {
    const queryParam = new URLSearchParams(location.search).get("name");
    if (queryParam) {
      setServiceName(queryParam);
    }
  }, [location]);

  const backendUrl = process.env.REACT_APP_ENVIRONMENT === 'production' ? 'https://backend.zmqrcode.com' : 'http://localhost:3000';
  console.log(backendUrl);
  // Form submission handler
  const handleSubmitForUrl = async (values, { resetForm }) => {
    if (profile.userid !== '') {
      const formData = {
        serviceName: serviceName,
        UrlName: values.websiteName || '',
        UrlText: values.url,
        QrCodePassword: values.password || '',
        userId: profile.userid
      };
      console.log(formData);
      try {
        const response = await axios.post(`${backendUrl}/purchaseQrCodeUserForUrl`, formData);
        const purchasedServiceUrl = response.data.url; // Assuming response.data.url contains the URL
        dispatch(setInstanceId(purchasedServiceUrl));
        setButtonText("Submitted");
        dispatch(setPrice(99));
        dispatch(setPlanName("Basic Starter plan"))
      } catch (error) {
        console.error(error);
      }
    } else {
      setIsLogin(true);
    }
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(prev => !prev);
  };

  const CustomHandleChange = (handleChange) => (event) => {
    handleChange(event);
    if (event.target.value !== '') {
      setButtonText('Submit');
      dispatch(setReset())
    }
  }


  return (
    <div className="max-w-lg mx-auto">
      <h1 className="text-2xl font-bold text-center mb-6">QR Code Generator for Linkedin</h1>
      <Formik
        initialValues={{
          websiteName: '',
          url: '',
          password: ''
        }}
        onSubmit={handleSubmitForUrl}
      >
        {({ values, handleChange, isSubmitting }) => (
          <Form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            {/* Website Name input */}
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="websiteName">
                Linkedin Profile
              </label>
              <Field
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                name="websiteName"
                placeholder="NA"
                onChange={CustomHandleChange(handleChange)}
              />
            </div>

            {/* URL input */}
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="url">
                Profile Link
              </label>
              <Field
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                name="url"
                placeholder="NA"
                onChange={CustomHandleChange(handleChange)}
              />
            </div>

            {/* Password input */}
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                Password
              </label>
              <div className="flex items-center relative">
                <Field
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  placeholder="Enter the Password"
                  onChange={CustomHandleChange(handleChange)}
                />
                {/* Password visibility toggle button outside, positioned next to the input */}
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="ml-2 text-gray-600 hover:text-gray-900"
                  style={{ width: "0px" }}
                >
                  <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} size="1x" color="#1D91AA" />
                </button>
              </div>
            </div>


            {/* Submit button */}
            <div className="flex items-center justify-between">
              <button
                className="text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
                style={{ backgroundColor: '#1D91AA' }}
                disabled={isSubmitting}
              >
                {buttonText}
              </button>
            </div>
          </Form>
        )}
      </Formik>
      {isLogin && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg p-8 w-96">
            {switchButton ? (
              <LoginUp
                setIsLogin={setIsLogin}
                setSwitchButton={setSwitchButton}
              />
            ) : (
              <SignUp
                setIsLogin={setIsLogin}
                setSwitchButton={setSwitchButton}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default LinkedinForm;
