/* eslint-disable jsx-a11y/img-redundant-alt */
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import useMediaQuery from "../usematchqueryfortrackscreensize/useMediaQuery";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const OutputwindowforBusinessInfo = () => {
  const location = useLocation();
  const [data, setData] = useState(null); // State to hold the fetched data
  const [loading, setLoading] = useState(true); // State to track loading status
  const [error, setError] = useState(null);
  const [businessInfo, setBusinessInfo] = useState({
    serviceName: "",
    businessName: "",
    openTiming: "",
    closeTiming: "",
    businessDescription: "",
    address: "",
    mapLink: "",
    ownerName: "",
    ownerContactNumber: "",
    filePaths: "",
  });

  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id"); // Get the 'id' parameter
  const cardRef = useRef(null); // Reference to the container to capture
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const backendUrl =
    process.env.REACT_APP_ENVIRONMENT === "production"
      ? "https://backend.zmqrcode.com"
      : "http://localhost:3000";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${backendUrl}/purchaseQrCodeUserForBusinessInfo/${id}` // Replace with your API URL
        );
        setData(response.data); // Set the fetched data to state

        let parsedFilePaths = [];
        const filePaths = response.data.menuCard;

        if (typeof filePaths === "string") {
          try {
            parsedFilePaths = JSON.parse(filePaths); // Parse string to array
          } catch (err) {
            console.error("Error parsing filePaths:", err);
          }
        }
        // Set the file paths to state
      } catch (err) {
        setError(err); // Set the error if the request fails
      } finally {
        setLoading(false); // Set loading to false after the request is complete
      }
    };

    fetchData(); // Call the fetch function
  }, [id]); // Adding id as a dependency to refetch if it changes

  useEffect(() => {
    if (data && typeof data === "object") {
      // Ensure `data` is an object or array
      setBusinessInfo((prevState) => ({
        ...prevState,
        ...data, // Merge fetched data with initial state
      }));
    }
  }, [data]);

  useEffect(() => {
    // Set truncation state based on description length
    if (businessInfo.businessDescription.length > 100) {
      setIsTruncated(true);
    } else {
      setIsTruncated(false);
    }
  }, [businessInfo.businessDescription]);

  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };

  const handleDownload = (tag) => {
    const link = document.createElement("a");
    link.href = tag;
    link.download = "jpg";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const renderBusinessInfo = () => {
    if (!businessInfo.filePaths || businessInfo.filePaths.length === 0) {
      return <div>No Menu Cards Available</div>;
    }
    return (
      <div ref={cardRef} className="flex flex-col items-center">
        <div
          className="flex flex-col justify-center items-center p-4 rounded-md"
          // Reference the div to capture it
        >
          <div
            className="flex flex-col justify-center items-center p-[1rem] m-[1rem] rounded-md bg-[#690e0e] text-[#fff]"
            style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
          >
            <div className="flex">
              <h1 className="font-raleway text-[3rem] font-[600]">
                Business Details
              </h1>
            </div>
            <div className="flex flex-col bg-[#fff] text-[#000] text-[1rem] font-[700] p-[1rem] rounded-[11px]">
              <p>{`Business Name: ${businessInfo.businessName}`}</p>
              <p>{`Service Name: ${businessInfo.serviceName}`}</p>
              <p>{`Opening Time: ${businessInfo.openTiming}`}</p>
              <p>{`Closing Time: ${businessInfo.closeTiming}`}</p>
              <p>{`Address: ${businessInfo.address}`}</p>
              <p>{`Owner Name: ${businessInfo.ownerName}`}</p>
              <p>{`Owner Contact: ${businessInfo.ownerContactNumber}`}</p>
              <p>
                {`Business Description: `}
                {isTruncated && !isExpanded
                  ? `${businessInfo.businessDescription.substring(0, 100)}...`
                  : businessInfo.businessDescription}
              </p>
              {isTruncated && (
                <button onClick={toggleDescription}>
                  {isExpanded ? "Read Less" : "Read More"}
                </button>
              )}
            </div>
            {businessInfo.filePaths.length > 0 && (
              <div className="flex flex-col justify-center mt-4">
                {businessInfo.filePaths.map((file, index) => (
                  <div key={index} className="p-2">
                    <img
                      src={file}
                      alt={`Menu Card ${index + 1}`}
                      className="w-[26rem] h-[20rem] rounded-[11px] ease-in-out hover:w-[28rem] hover:h-[22rem] cursor-pointer "
                    />

                    <button
                      onClick={() => handleDownload(file)}
                      className="mt-2 p-2 bg-[#1d91aa] text-white rounded"
                    >
                      <FontAwesomeIcon icon={faDownload} />
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  // Return loading, error, or business information view
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return <div>{renderBusinessInfo()}</div>;
};

export default OutputwindowforBusinessInfo;
