import NavBar from "./NavBar";
import AboutUsHomePage from "./AboutUsHomePage";
import ContactUs from "./ContactUs";
import React from "react";
import { ColorRing } from "react-loader-spinner";

const ComingSoon = () => {
  return (
    <div>
      <NavBar />

      <div
        className="w-full flex-col"
        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        <div className="flex rounded-[11px] m-[14px] shadow-[0px_5px_15px_rgba(0,_0,_0,_0.35)] flex-col">
          <div className="flex justify-center items-center flex flex-col">
            {" "}
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="comment-loading"
              wrapperStyle={{}}
              wrapperClass="comment-wrapper"
              colors={["#517b7b", "#517b7b", "#517b7b", "#517b7b", "#517b7b"]} // This applies the same color to all circles
            />
            <label className="text-3xl font-bold font-raleway">
              Coming Soon
            </label>
            <p className="text-[13px] font-bold font-raleway ml-4">
              We’re working hard to bring you something amazing. Stay tuned for
              updates and be the first to know when we launch!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
