import React, { useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const backendUrl = process.env.REACT_APP_ENVIRONMENT === 'production' ? 'https://backend.zmqrcode.com' : 'http://localhost:3000';

const PasswordOfProductForText = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [error, setError] = useState(null);

    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id'); 
    const [passwordVisible, setPasswordVisible] = useState(false);
    const togglePasswordVisibility = () => setPasswordVisible(!passwordVisible);
  

    const handleChange = async (text) => {
        const password = text.target.value; 
        try {
            const response = await axios.get(`${backendUrl}/purchaseQrCodeUserForText/${id}`);
            if (response.data && password === response.data.QrCodePassword) {
                // If password matches, navigate to the event page
                navigate(`/textoutputwindow?id=${id}`);
            } else {
                setError('Invalid password. Please try again.'); // Show error for invalid password
            }
        } catch (err) {
            console.error('Error verifying password:', err);
            setError('An error occurred while verifying the password. Please try again later.');
        }
    };

    return (
        <div className='flex flex-col justify-center items-center w-full'>
        
        <div className="flex items-center border rounded-lg m-[5px] p-3">
                    <input
                        className="border-none ml-2 p-3  focus:outline-none"
                        type={passwordVisible ? 'text' : 'password'}
                        name="Enter Your Password"
                        onChange={handleChange}
                        placeholder="Enter The Password"
                    />
                    <FontAwesomeIcon
                        icon={passwordVisible ? faEyeSlash : faEye}
                        size="1x"
                        color="#1D91AA"
                        onClick={togglePasswordVisibility}
                        className="cursor-pointer"
                    />
                </div>
                {error && <div className="text-red-500 text-sm">{error}</div>}{" "}

        </div>
    );
};

export default PasswordOfProductForText;
