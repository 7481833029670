import { combineReducers } from 'redux';
import profileReducer from '../store/userProfile/userSetProfileReducer';
import authLoginAndLogoutReducer from '../store/userProfile/userprofilesliceLogin';
import stickactiveReducer from '../store/userProfile/stickactive/stickactive'; 
import QrCodeCustmizablestoreReducer from '../store/QrCodeCustmizable/QrCodecustmizableSlicer';
import BestOffersSlicer from './chooseBestOffers/BestOffersSlicer';
import serviceInstanceSlice from './ServiceSavedInstance/ServiceInstanceReducer';
import  downloadText from './downloadButtonRefernecSliceReducer';

const rootReducer = combineReducers({
    profile: profileReducer,  // This ensures `state.profile` exists
    auth: authLoginAndLogoutReducer,
    stickactive: stickactiveReducer,
    QrCodeCustmizable: QrCodeCustmizablestoreReducer,
    Bestplans: BestOffersSlicer,
    serviceInstance: serviceInstanceSlice,
    downloadText:  downloadText
});

export default rootReducer;
