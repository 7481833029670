import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPencil, faTrashAlt, faDownload, faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import useMediaQuery from "../usematchqueryfortrackscreensize/useMediaQuery";
import { useSelector } from "react-redux";
import axios from "axios";
import CustomAlert from "../CustomAlertForSubmision";
import useLoadScript from "../reusableHook/useLoadScript";




const MyQRCodeProfile = () => {
  const { loaded, error } = useLoadScript(
    "https://checkout.razorpay.com/v1/checkout.js"
  );
  const [password, setPassword] = useState("");
  const [selectedPlan, setSelectedPlan] = useState(false);
  const [servicesData, setServicesData] = useState([]); // State to store services data
  const isMobile = useMediaQuery("(max-width: 960px)");
  const userProfile = useSelector((state) => state.profile);
  const [changeInServices, setChangeService] = useState(false);
  const [id, setChooseServiceId] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [serviceName, setServiceName] = useState("");

  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => setPasswordVisible(!passwordVisible);



  const backendUrl = process.env.REACT_APP_ENVIRONMENT === 'production' ? 'https://backend.zmqrcode.com' : 'http://localhost:3000';

  useEffect(() => {
    const getServicesData = async () => {
      const userid = { userid: userProfile.userid };
      console.log(userid)
      try {
        const response = await axios.post(
          `${backendUrl}/findAllServicesPuchasedByUsers`,
          userid
        );
        setServicesData(response.data); // Update state with fetched data
        setChangeService(false);
      } catch (error) {
        console.error("Error fetching services data:", error);
      }
    };

    if (userProfile.userid) {
      getServicesData();
    }
  }, [userProfile, changeInServices]);

  const handleChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmitPassword = async () => {

    let endPoint;

    if (selectedPlan) {
      if (!password) {
        setShowAlert(true);
        setAlertMessage("Password cannot be empty.");
        return;
      }

      const changePassword = { id, password };

      switch (serviceName) {
        case 'Url':
          endPoint = "changepasswordforprotectedOnesForUrl";
          break;
        case 'YouTube':
          endPoint = "changepasswordforprotectedOnesForUrl";
          break;
        case 'FaceBook':
          endPoint = "changepasswordforprotectedOnesForUrl";
          break;
        case 'Instagram':
          endPoint = "changepasswordforprotectedOnesForUrl";
          break;
        case 'X/Twitter':
          endPoint = "changepasswordforprotectedOnesForUrl";
          break;
        case 'Linkedin':
          endPoint = "changepasswordforprotectedOnesForUrl";
          break;
        case 'FeedBack Forms':
          endPoint = "changepasswordforprotectedOnesForUrl";
          break;
        case 'Github':
          endPoint = "changepasswordforprotectedOnesForUrl";
          break;
        case 'Link':
          endPoint = "changepasswordforprotectedOnesForLink";
          break;
        case 'Location':
          endPoint = "changepasswordforprotectedOnesForLink";
          break;
        case 'Landing Page':
          endPoint = "changepasswordforprotectedOnesForLink";
          break;
        case 'Meeting':
          endPoint = "changepasswordforprotectedOnesForMeeting";
          break;
        case 'Google Meet':
          endPoint = "changepasswordforprotectedOnesForMeeting";
          break;
        case 'Zoom Meet':
          endPoint = "changepasswordforprotectedOnesForMeeting";
          break;
        case 'Microsoft Teams':
          endPoint = "changepasswordforprotectedOnesForMeeting";
          break;
        case 'MenuCard':
          endPoint = "changepasswordforprotectedOnesForMenu";
          break;
        case 'Business Card':
          endPoint = "changepasswordforprotectedOnesForBussinessCard";
          break;
        case 'Event':
          endPoint = "changepasswordforprotectedOnesForEvent";
          break;
        case 'Text Message':
          endPoint = "changepasswordforprotectedOnesForText";
          break;
        case 'Kids Safety QR Tag':
          endPoint = "changepasswordforprotectedOnesForKids";
          break;
        case 'Property QR Tag':
          endPoint = "changepasswordforprotectedOnesForProperty";
          break;
        case "Vehicle's QR Tag":
          endPoint = "changepasswordforprotectedOnesForVehicle";
          break;
        case 'Discount':
          endPoint = "changepasswordforprotectedOnesForDiscountCard"
          break;
        case 'SMS':
          endPoint = "changepasswordforprotectedOnesForSms";
          break;
        case 'Pet Id Tag':
          endPoint = "changepasswordforprotectedOnesForPet";
          break;
        case 'Student Form':
          endPoint = "changepasswordforprotectedOnesForStudentForm";
          break;
        case 'BusinessInfoQr':
          endPoint = "changepasswordforprotectedOnesForBusinessInfo";
          break;
        default:
          // Optionally handle other cases or provide a default action
          endPoint = "changepasswordforprotectedOnes";
          break;
      }


      try {


        const response = await axios.post(
          `${backendUrl}/${endPoint}`,
          changePassword
        );
        if (response.status === 200) {
          setShowAlert(true);
          setAlertMessage("Your QR Code password has been changed successfully.");
          setPassword(""); // Clear password field after successful change
        } else {
          setShowAlert(true);
          setAlertMessage("Failed to change the password. Please try again.");
        }
      } catch (error) {
        setShowAlert(true);
        setAlertMessage(`Error: ${error.message}`);
      } finally {
        setSelectedPlan(false); // Reset selectedPlan
      }
    }
  };

  const handleDelete = async (service) => {
    let endPointForDelete;

    switch (service.serviceName) {
      case 'Url':
        endPointForDelete = "deleteserviceByIdForUrl";
        break;
      case 'YouTube':
        endPointForDelete = "deleteserviceByIdForUrl";
        break;
      case 'FaceBook':
        endPointForDelete = "deleteserviceByIdForUrl";
        break;
      case 'Instagram':
        endPointForDelete = "deleteserviceByIdForUrl";
        break;
      case 'Linkedin':
        endPointForDelete = "deleteserviceByIdForUrl";
        break;
      case 'X/Twitter':
        endPointForDelete = "deleteserviceByIdForUrl";
        break;
      case 'FeedBack Forms':
        endPointForDelete = "deleteserviceByIdForUrl";
        break;
      case 'Github':
        endPointForDelete = "deleteserviceByIdForUrl";
        break;
      case 'Link':
        endPointForDelete = "deleteserviceByIdForLink";
        break;
      case 'Location':
        endPointForDelete = "deleteserviceByIdForLink";
        break;
      case 'link':
        endPointForDelete = "deleteserviceByIdForLink";
        break;
      case 'Landing Page':
        endPointForDelete = "deleteserviceByIdForLink";
        break;
      case 'Meeting':
        endPointForDelete = "deleteserviceByIdForMeeting";
        break;
      case 'Google Meet':
        endPointForDelete = "deleteserviceByIdForMeeting";
        break;
      case 'Zoom Meet':
        endPointForDelete = "deleteserviceByIdForMeeting";
        break;
      case 'Microsoft Teams':
        endPointForDelete = "deleteserviceByIdForMeeting";
        break;
      case 'MenuCard':
        endPointForDelete = "deleteserviceByIdForMenu";
        break;
      case 'Business Card':
        endPointForDelete = "deleteserviceByIdForBussinessCard";
        break;
      case 'Event':
        endPointForDelete = "deleteserviceByIdForEvent";
        break;
      case 'Text Message':
        endPointForDelete = "deleteserviceByIdForText";
        break;
      case 'Kids Safety QR Tag':
        endPointForDelete = "deleteserviceByIdForKids";
        break;
      case 'Property QR Tag':
        endPointForDelete = "deleteserviceByIdForProperty";
        break;
      case "Vehicle's QR Tag":
        endPointForDelete = "deleteserviceByIdForVehicle";
        break;
      case 'Discount':
        endPointForDelete = "deleteserviceByIdForDiscountCard";
        break;
      case 'Wifi':
        endPointForDelete = "deleteserviceByIdForWifiCard";
        break;
      case 'SMS':
        endPointForDelete = "deleteserviceByIdForSms";
        break;
      case 'Pet Id Tag':
        endPointForDelete = "deleteserviceByIdForPet";
        break;
      case 'Student Form':
        endPointForDelete = "deleteserviceByIdForStudentForm";
        break;
      case 'BusinessInfoQr':
        endPointForDelete = "deleteserviceByIdForBusinessInfo";
        break;
      
      default:
        // Optionally handle other cases or provide a default action
        endPointForDelete = "deleteserviceById";
    }
    try {
      const serviceId = service.id;

      const response = await axios.delete(
        `${backendUrl}/${endPointForDelete}?id=${serviceId}`
      );
      console.log(response.status);
      // Log the response

      // Update state to reflect deletion
      setServicesData(servicesData.filter((item) => item.id !== serviceId));
    } catch (error) {
      console.error("Service deletion failed:", error.message);
    }
  };

  const EditPasswordOFQrCode = (service) => {
    const serviceId = service.id;
    setServiceName(service.serviceName);
    setSelectedPlan(true);
    setChooseServiceId(serviceId);
  };

  const handlePayment = async (service) => {
    const serviceid = service.id;

    // Check if Razorpay SDK has loaded
    if (!loaded) {
      alert("Razorpay SDK is still loading...");
      return;
    }

    if (error) {
      alert("Failed to load Razorpay SDK. Please try again.");
      return;
    }

    try {
      // Create the order on the backend
      const orderResponse = await axios.post(`${backendUrl}/orders`, {
        amount: service.serviceName !== 'Url' ? 99 * 100 : 99 * 100, // Amount in paise
        currency: "INR"
      });

      const { order_id, currency, amount } = orderResponse.data;
      let endPointForPayment;
      // Define Razorpay payment options
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: amount,
        currency: currency,
        name: "Annual renewal price 99 INR for ONE GB",
        description: "Test Transaction for QR Code",
        order_id: order_id, // Attach the order ID from the backend
        handler: async function (response) {

          // Post-payment actions based on service type
          const data = { id: serviceid };
          try {

            switch (service.serviceName) {
              case 'Url':
                endPointForPayment = "renewServiceForUrl";
                break;
              case 'YouTube':
                endPointForPayment = "renewServiceForUrl";
                break;
              case 'FaceBook':
                endPointForPayment = "renewServiceForUrl";
                break;
              case 'Instagram':
                endPointForPayment = "renewServiceForUrl";
                break;
              case 'Linkedin':
                endPointForPayment = "renewServiceForUrl";
                break;
              case 'X/Twitter':
                endPointForPayment = "renewServiceForUrl";
                break;
              case 'FeedBack Forms':
                endPointForPayment = "renewServiceForUrl";
                break;
              case 'Github':
                endPointForPayment = "renewServiceForUrl";
                break;
              case 'Link':
                endPointForPayment = "renewServiceForLink";
                break;
              case 'Location':
                endPointForPayment = "renewServiceForLink";
                break;
              case 'Landing Page':
                endPointForPayment = "renewServiceForLink";
                break;
              case 'Meeting':
                endPointForPayment = "renewServiceForMeeting";
                break;
              case 'Google Meet':
                endPointForPayment = "renewServiceForMeeting";
                break;
              case 'Zoom Meet':
                endPointForPayment = "renewServiceForMeeting";
                break;

              case 'Microsoft Teams':
                endPointForPayment = "renewServiceForMeeting";
                break;

              case 'MenuCard':
                endPointForPayment = "renewServiceForMenu";
                break;
              case 'Business Card':
                endPointForPayment = "renewServiceForBussinessCard";
                break;
              case 'Event':
                endPointForPayment = "renewServiceForEvent";
                break;
              case 'Text Message':
                endPointForPayment = "renewServiceForText";
                break;
              case 'Kids Safety QR Tag':
                endPointForPayment = "renewServiceForKids";
                break;
              case "Vehicle's QR Tag":
                endPointForPayment = "renewServiceForVehicle";
                break;
              case "Property QR Tag":
                endPointForPayment = "renewServiceForProperty";
                break;
              case 'Wifi':
                endPointForPayment = "renewServiceForWifiCard";
                break;
              case 'Discount':
                endPointForPayment = "renewServiceForDiscountCard";
                break;
              
              case 'SMS':
                endPointForPayment = "renewServiceForSms";
                break;
              case 'Pet Id Tag':
                endPointForPayment = "renewServiceForPet";
                break;
              case 'Student Form':
                endPointForPayment = "renewServiceStudentForm";
                break;
              case 'Business Qr Tag':
                endPointForPayment = "renewServiceForBusinessInfo";
                break;
              default:
                // Optionally handle other cases or provide a default action
                endPointForPayment = "renewService";
            }

            const responseData = await axios.post(`${backendUrl}/${endPointForPayment}`, data);



            if (responseData.status === 200) {
              setAlertMessage("Your QR is renewed successfully.");
              setShowAlert(true);
              setChangeService(true);
            } else {
              console.error("Service renewal failed:", responseData.status);
            }
          } catch (error) {
            setAlertMessage("Error during service renewal: " + error.message);
            setShowAlert(true);
          }
        },
        prefill: {
          name: userProfile.username,
          email: userProfile.email,
        },
        theme: {
          color: "#F37254",
        },
      };

      // Open the Razorpay payment modal
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();

    } catch (error) {
      console.error("Error while creating order:", error.response?.data || error.message);
      alert(`Error while creating order: ${error.response?.data?.message || "Please try again."}`);
    }
  };

  const handleDownload = async (service) => {
    try {
      const response = await fetch(`${backendUrl}/getUploadForUserByFileId/${service.QrCodeImage}`);
      if (!response.ok) throw new Error('Failed to download the image');

      const blob = await response.blob();
      const downloadUrl = URL.createObjectURL(blob);

      // Create a temporary link element
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = `${service.serviceName || 'downloaded-qr-code'}.jpg`;
      link.style.display = 'none';

      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
      URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Error downloading the image:', error);
    }
  };

  const confirmDelete = (service) => {
    // Display confirmation dialog
    if (window.confirm("Are you sure you want to delete this QR code?")) {
      handleDelete(service);
    }
  };


  return (
    <div className="p-4 border-2 border-transparent justify-center h-[max-fit]">
      {servicesData.length === 0 ? (
        <div className="text-center font-raleway text-lg text-gray-500">
          No QR Code Purchased
        </div>
      ) : (
        <ul className={`${isMobile ? "flex flex-col" : "flex flex-wrap"}`}>
          {console.log(servicesData)}
          {servicesData.map((service) => (
            <li
              key={service.id}
              className={`${isMobile ? "w-full" : "w-[25%]"
                } p-2 m-2 border-2 border-[#f0ebeb] shadow-lg`}
            >
              <img
                src={`${backendUrl}/getUploadForUserByFileId/${service.QrCodeImage}`}
                alt={service.serviceName}
                className="w-full h-auto"
              />
              <div>
                <h3 className="font-raleway text-lg m-4 font-bold text-center">
                  {service.serviceName}
                </h3>
                {(service.serviceName !== 'Url' && service.serviceName !== 'Link' && service.serviceName !== 'Location' && service.serviceName !== 'FeedBack Forms' && service.serviceName !== 'Landing Page' &&
                  service.serviceName !== 'Meeting' && service.serviceName !== 'Google Meet' && service.serviceName !== 'Zoom Meet' && service.serviceName !== 'Microsoft Teams' && service.serviceName !== 'Github'
                  && service.serviceName !== 'YouTube' && service.serviceName !== 'FaceBook' && service.serviceName !== 'Instagram' && service.serviceName !== 'X/Twitter' && service.serviceName !== 'Linkedin'
                  && service.serviceName !== 'MenuCard' && service.serviceName !== 'Event' && service.serviceName !== 'Business Card' && service.serviceName !== 'Text Message' && service.serviceName !== 'Discount' && service.serviceName !== 'Student Form' && service.serviceName !== 'Business Qr Tag'
                  && service.serviceName !== 'SMS' && service.serviceName !== 'Pet Id Tag' && service.serviceName !== 'Kids Safety QR Tag' && service.serviceName !== "Vehicle's QR Tag" && service.serviceName !== 'Property QR Tag') && <p>{`File Title: ${service.fileTitle !== '' ? service.fileTitle : 'Not Filled'}`}</p>}
                {service.serviceName === 'Url' && <p>{`Url Title: ${service.fileTitle !== '' ? service.UrlName : 'Not Filled'}`}</p>}
                {service.serviceName === 'YouTube' && <p>{`Channel Name: ${service.fileTitle !== '' ? service.UrlName : 'Not Filled'}`}</p>}
                {service.serviceName === 'FaceBook' && <p>{`Profile Name: ${service.fileTitle !== '' ? service.UrlName : 'Not Filled'}`}</p>}
                {service.serviceName === 'Github' && <p>{`Profile Name: ${service.fileTitle !== '' ? service.UrlName : 'Not Filled'}`}</p>}
                {service.serviceName === 'Linkedin' && <p>{`Profile Name: ${service.fileTitle !== '' ? service.UrlName : 'Not Filled'}`}</p>}
                {service.serviceName === 'X/Twitter' && <p>{`Profile Name: ${service.fileTitle !== '' ? service.UrlName : 'Not Filled'}`}</p>}
                {service.serviceName === 'Instagram' && <p>{`Profile Name: ${service.fileTitle !== '' ? service.UrlName : 'Not Filled'}`}</p>}
                {service.serviceName === 'Link' && <p>{`Link Title: ${service.fileTitle !== '' ? service.linkName : 'Not Filled'}`}</p>}
                {service.serviceName === 'Location' && <p>{`Place Name: ${service.fileTitle !== '' ? service.linkName : 'Not Filled'}`}</p>}
                {service.serviceName === 'FeedBack Forms' && <p>{`FeedBack Form Title: ${service.fileTitle !== '' ? service.UrlName : 'Not Filled'}`}</p>}
                {service.serviceName === 'Landing Page' && <p>{`Landing Page Title: ${service.fileTitle !== '' ? service.linkName : 'Not Filled'}`}</p>}
                {service.serviceName === 'Meeting' && (
                  <>
                    <p>{`Meeting Title: ${service.CompanyName || 'Not Filled'}`}</p>
                    <p>{`Meeting Timing: ${service.meetingTime || 'Not Filled'}`}</p>
                  </>
                )}
                {service.serviceName === 'Google Meet' && (
                  <>
                    <p>{`Meeting Title: ${service.CompanyName || 'Not Filled'}`}</p>
                    <p>{`Meeting Timing: ${service.meetingTime || 'Not Filled'}`}</p>
                  </>
                )}
                {service.serviceName === 'Zoom Meet' && (
                  <>
                    <p>{`Meeting Title: ${service.CompanyName || 'Not Filled'}`}</p>
                    <p>{`Meeting Timing: ${service.meetingTime || 'Not Filled'}`}</p>
                  </>
                )}
                {service.serviceName === 'Microsoft Teams' && (
                  <>
                    <p>{`Meeting Title: ${service.CompanyName || 'Not Filled'}`}</p>
                    <p>{`Meeting Timing: ${service.meetingTime || 'Not Filled'}`}</p>
                  </>
                )}
                {
                  service.serviceName === 'MenuCard' && (
                    <>
                      <p>{`Restaurant: ${service.resturantName || 'Not Filled'}`}</p>
                      <p>{`Address: ${service.address || 'Not Filled'}`}</p>
                    </>
                  )
                }
                {
                  service.serviceName === 'Event' && (
                    <>
                      <p>{`Event Name: ${service.eventName || 'Not Filled'}`}</p>
                      <p>{`Date/Time: ${service.dateTime || 'Not Filled'}`}</p>
                    </>
                  )
                }

                {
                  service.serviceName === 'Text Message' && (
                    <>
                      <p>{`Sender Name: ${service.senderName || 'Not Filled'}`}</p>
                      <p>{`Date/Time: ${service.dateTime || 'Not Filled'}`}</p>
                    </>
                  )
                }
                {
                  service.serviceName === 'SMS' && (
                    <>
                      <p>{`Sender Name: ${service.senderName || 'Not Filled'}`}</p>
                      <p>{`Date/Time: ${service.dateTime || 'Not Filled'}`}</p>
                    </>
                  )
                }
                {
                  service.serviceName === 'Pet Id Tag' && (
                    <>
                      <p>{`Pet Name: ${service.PetName || 'Not Filled'}`}</p>
                      <p>{`Date/Time: ${service.dateTime || 'Not Filled'}`}</p>
                    </>
                  )
                }  {
                  service.serviceName === 'Property QR Tag' && (
                    <>
                      <p>{`Property Name: ${service.propertyName || 'Not Filled'}`}</p>
                      <p>{`Owner Name: ${service.ownerName || 'Not Filled'}`}</p>
                    </>
                  )
                }

                {
                  service.serviceName === 'Kids Safety QR Tag' && (
                    <>
                      <p>{`Kid Name: ${service.childName || 'Not Filled'}`}</p>
                      <p>{`parent Name: ${service.parentName || 'Not Filled'}`}</p>
                    </>
                  )
                }
                {
                  service.serviceName === "Vehicle's QR Tag" && (
                    <>
                      <p>{`Model Name: ${service.modelName || 'Not Filled'}`}</p>
                      <p>{`Owner Name: ${service.ownerName || 'Not Filled'}`}</p>
                    </>
                  )
                }
                {
                  service.serviceName === 'Business Card' && (
                    <>
                      <p>{`Name: ${service.personName || 'Not Filled'}`}</p>
                      <p>{`Business: ${service.businessName || 'Not Filled'}`}</p>
                    </>
                  )
                }
                {
                  service.serviceName === 'Discount' && (
                    <>
                      <p>{`Company Name: ${service.companyName || 'Not Filled'}`}</p>
                    </>
                  )
                }
                 {
                  service.serviceName === 'Student Form' && (
                    <>
                      <p>{`Student Name: ${service.studentName || 'Not Filled'}`}</p>
                    </>
                  )
                }
                  {
                  service.serviceName === 'Business Qr Tag' && (
                    <>
                      <p>{`Bussiness Name: ${service.businessName || 'Not Filled'}`}</p>
                    </>
                  )
                }
              </div>
              <div className="flex flex-row items-center justify-between px-4">
                <p className="font-raleway text-base font-semibold flex items-center">
                  <span className="ml-2">{service.scans || 0} Scans</span>
                </p>

                {service.QrCodePassword !== "" && (
                  <div className="flex flex justify-between items-center">
                    <p className="font-raleway text-[13px] font-semibold">
                      Change Password
                    </p>
                    <button
                      className="border-none p-0 m-0 focus:outline-none"
                      aria-label="Edit"
                      onClick={() => EditPasswordOFQrCode(service)}
                    >
                      <FontAwesomeIcon
                        icon={faPencil}
                        className="h-[13px] w-[13px] pl-[5px] text-[#4bdede]"
                      />
                    </button>
                  </div>
                )}
              </div>
              <p className="font-raleway text-base m-4 font-bold text-center">
                Expires on: {service.QrCodeExpiryDate}
              </p>
              <div className="flex justify-around mt-4">
                <div
                  onClick={() => handlePayment(service)}
                  className="flex items-center cursor-pointer"
                >
                  <FontAwesomeIcon
                    icon={faEdit}
                    className="h-[16px] w-[20px] p-2 bg-[#4bdede] rounded-full text-white border-2 border-white"
                  />
                  <span className="font-raleway text-[16px] ml-2">Renew</span>
                </div>
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => confirmDelete(service)} // Add confirmation here
                >
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    className="h-[16px] w-[20px] p-2 bg-[#f55656] rounded-full text-white border-2 border-white"
                  />
                  <span className="font-raleway text-[16px] ml-2">Delete</span>
                </div>
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => handleDownload(service)}
                >
                  <FontAwesomeIcon
                    icon={faDownload}
                    className="h-[16px] w-[20px] p-2 bg-[#4bdede] rounded-full text-white border-2 border-white"
                  />
                  <span className="font-raleway text-[16px] ml-2"></span>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}

      {selectedPlan && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-[#1D91AA] text-white p-6 rounded-lg shadow-lg w-80">
            <label className="font-raleway text-300 m-4 font-bold text-white">
              Password
            </label>
            <div className="flex items-center border rounded-lg m-[5px] p-3 justify-space">
              <input
                className="border-none ml-2 p-3 w-[90%] rounded-mg mr-[5px] focus:outline-none text-[black]"
                type={passwordVisible ? 'text' : 'password'}
                name="Enter Your Password"
                onChange={handleChange}
                placeholder="Enter The Password"
              />
              <FontAwesomeIcon
                icon={passwordVisible ? faEyeSlash : faEye}
                size="1x"
                color="#fff"
                onClick={togglePasswordVisibility}
                className="cursor-pointer"
              />
            </div>
            <button
              className="bg-white text-customBlue hover:bg-customBlue hover:text-white font-bold py-2 px-4 rounded flex flex-start w-[65px] m-[16px]"
              onClick={handleSubmitPassword}
            >
              Save
            </button>
          </div>
        </div>
      )}
      {showAlert && (
        <CustomAlert message={alertMessage} onClose={() => setShowAlert(false)} />
      )}
    </div>
  );
};

export default MyQRCodeProfile;
