import { useLocation } from "react-router-dom";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import useMediaQuery from "../usematchqueryfortrackscreensize/useMediaQuery";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const OutPutWindowForDiscount = () => {
  const location = useLocation();
  const [data, setData] = useState(null); // State to hold the fetched data
  const [loading, setLoading] = useState(true); // State to track loading status
  const [error, setError] = useState(null);
  const [paths, setFilePath] = useState([]); // State for file paths
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id"); // Get the 'id' parameter
  const isMobile = useMediaQuery("(max-width: 960px)");
  const [companyName, setCompanyName] = useState("");
  const [productName, setProductName] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState("");
  const [fromStart, setfromStart] = useState("");
  const [fromEnd, setFromEnd] = useState("");
  const cardRef = useRef(null); // Reference to the container to capture
  const [serviceName, setServiceName] = useState("");

  const backendUrl =
    process.env.REACT_APP_ENVIRONMENT === "production"
      ? "https://backend.zmqrcode.com"
      : "http://localhost:3000";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${backendUrl}/purchaseQrCodeUserForDiscountCard/${id}` // Replace with your API URL
        );
        setData(response.data); // Set the fetched data to state
        console.log(response.data);
        const filePaths = response.data.itemsCard;
        let parsedFilePaths = [];

        if (typeof filePaths === "string") {
          try {
            parsedFilePaths = JSON.parse(filePaths); // Parse string to array
          } catch (err) {
            console.error("Error parsing filePaths:", err);
          }
        } else {
          parsedFilePaths = filePaths; // If it's already an array, use it as is
        }

        setFilePath(parsedFilePaths); // Set the file paths to state
      } catch (err) {
        setError(err); // Set the error if the request fails
      } finally {
        setLoading(false); // Set loading to false after the request is complete
      }
    };

    fetchData(); // Call the fetch function
  }, [id]); // Adding id as a dependency to refetch if it changes

  useEffect(() => {
    if (data) {
      setServiceName(data.serviceName);
      setCompanyName(data.companyName || "Not Filled");
      setProductName(data.productName || "Not Filled");
      setDiscountPercentage(data.discountPercentage || "Not Filled");
      setfromStart(data.fromStart || "Not Filled");
      setFromEnd(data.fromEnd || "Not Filled");
    }
  }, [data]); // Dependency array with 'data'

  // Early return for loading and error states

  const renderMenuCard = () => {
    if (paths.length === 0) {
      return <div>No Menu Cards Available</div>;
    }

    const handleDownload = (tag) => {
      const link = document.createElement("a");
      link.href = tag;
      link.download = "jpg";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    return (
      <div ref={cardRef} className="flex flex-col items-center">
        <div
          className="flex flex-col justify-center items-center p-4 rounded-md"
          // Reference the div to capture it
        >
          <div
            className={`flex flex-col rounded-md bg-[#690e0e] text-[#fff]  ${isMobile ? "w-full" : "w-[358px]"}`}
            style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
          >
            <div className="flex w-full items-center flex-col p-4">
              <div className="flex flex-col items-center">
                <div className="flex flex-col items-center gap-6">
                  <label className="font-raleway font-semibold text-2xl">
                    {`Company Name: ${companyName}`}
                  </label>
                  <label className="font-raleway font-semibold text-lg">
                    {`Product Name ${productName}`}
                  </label>
                  <div className="flex flex-col items-center">
                    <label className="font-semibold text-3xl m-[15px]">Discount</label>
                    <div className="relative top-[15px] flex justify-center items-center w-24 h-24 bg-gradient-to-r from-[#FF6A3D] to-[#FFCE00] text-white rounded-full shadow-lg animate-bounce">
                      <div className="absolute inset-0 rounded-full bg-gradient-to-r from-[#FF6A3D] to-[#FFCE00] animate-pulse opacity-75 blur-md"></div>
                      <span className="relative z-10 text-3xl font-bold tracking-wide animate-[flash_1.5s_infinite]">
                        {discountPercentage}% OFF
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justifiy-center items-center">
                <div
                 className={`flex flex-row flex-wrap justify-center items-center ${isMobile ? "flex-col" : ""}`}

                >
                  {paths.map((link, index) => (
                    <div key={index} className="m-[12px] flex flex-col p-2 items-center bg-[#fff] rounded-md font-semibold w-[145px] transform hover:scale-105" >
                      <img
                        src={`${link}`}
                        alt={`Products ${index + 1}`}
                        className={`max-w-full h-auto ${isMobile ? "w-[398px]" : "w-[60%]"} m-[5px]`}
                      />
                      <div className="flex  flex-col items-center">
                      <label className="font-raleway text-black font-semibold">{`${productName} Images ${index + 1}`}</label>

                        <div
                          className="flex cursor-pointer"
                          onClick={() => handleDownload(link)} // Trigger download on click
                        >
                          <FontAwesomeIcon
                            icon={faDownload}
                            className="h-[16px] w-[20px] p-2 bg-[#4bdede] rounded-full text-white border-2 border-white"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="flex flex-col">
                  <label className="font-raleway font-semibold">
                    {`From Start: ${fromStart}`}
                  </label>
                  <label className="font-raleway font-semibold">
                    {`From End: ${fromEnd}`}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return <div>{renderMenuCard()}</div>;
};

export default OutPutWindowForDiscount;
