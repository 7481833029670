import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    serviceId: '', // Holds the UUID as a string
};

const serviceInstanceSlice = createSlice({
    name: 'serviceInstance',
    initialState,
    reducers: {
        setInstanceId(state, action) {
            if (action.payload) {
                state.serviceId = action.payload; // Should be a UUID string
                localStorage.setItem("serviceInstanceId", JSON.stringify(action.payload));
            } else {
                console.error('No payload provided for setInstanceId action');
            }
        },
        setReset(state) {
            return initialState; // Resetting to the initial state
        }
    }
});

export const { setInstanceId, setReset } = serviceInstanceSlice.actions;
export default serviceInstanceSlice.reducer;
