import { createSlice } from "@reduxjs/toolkit";

const initialState = { // Corrected this line
    downloadText: ""
};

const downloadText = createSlice({
    name: 'downloadText',
    initialState, // Corrected this line
    reducers: {
        setDownloadText(state, action) {
            state.downloadText = action.payload;
        },
        setReset(state) {
            return initialState; // Resetting to the initial state
        }
    }
});

export const { setDownloadText, setReset } = downloadText.actions;
export default downloadText.reducer;
