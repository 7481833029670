import React, { useRef, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faUser } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { setProfile } from './store/userProfile/userProfileActions';
import languages from "./resources/languages.json";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { resetProfile } from "./store/userProfile/userProfileActions";

const MobileNavBar = ({ setToggleHamBurger }) => {
  const newRef = useRef(null);
  const dialogRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const profile = useSelector((state) => state.profile);

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleDialogToggle = () => {
    setIsDialogOpen((prev) => !prev);
  };

  const handleClickOutsideForDialog = (event) => {
    if (dialogRef.current && !dialogRef.current.contains(event.target)) {
      setIsDialogOpen(false);
    }
  };

  const handleLogout = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete('token');
    localStorage.removeItem('profile');
    localStorage.removeItem('userProfile');
    localStorage.removeItem("freeMb");

    dispatch(resetProfile());
    navigate('/');
    setIsDropdownOpen(false);
    setIsDialogOpen(false);
};
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideForDialog);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideForDialog);
    };
  }, []);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (newRef.current && !newRef.current.contains(e.target)) {
        setToggleHamBurger(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [setToggleHamBurger]);

  const OpenviewProfile = () => {
    navigate("/userprofile");
  };

  useEffect(() => {
    // Retrieve the profile data from localStorage
    const profileData = JSON.parse(localStorage.getItem('profile'));
  
    // Check if profileData exists and its fields are not empty
    if (
      profileData &&
      profileData.username?.trim() !== "" &&
      profileData.email?.trim() !== "" &&
      profileData.mobile?.trim() !== "" &&
      profileData.area?.trim() !== ""
    ) {
      // Log the profile data and dispatch the action to set the profile in Redux store

      dispatch(setProfile({
        userid: profileData.userid,
        username: profileData.username,
        email: profileData.email,
        usagecount: profileData.usagecount,
        image: profileData.image,
        token: profileData.token
      }));
    }
  }, [dispatch]);


  return (
    <div
      className="w-[70%] bg-customBlue p-4 z-50 fixed top-0 right-0 h-full flex flex-col items-start shadow-lg overflow-y-auto"
      ref={newRef}
    >
   
      <FontAwesomeIcon
        icon={faTimes}
        className="text-white text-2xl cursor-pointer hover:text-gray-300 self-end mb-4 p-2"
        onClick={() => setToggleHamBurger(false)}
      />
      <ul className="list-none flex flex-col space-y-4 w-full font-raleway">
        <li className="w-full">
        {profile.username && (
        <div className="flex justify-center item-center">
          <button
            onClick={handleDialogToggle}
            className="w-[58px] flex justify-center items-center hover:bg-gray-100 p-1 mt-4 rounded-full transition-colors duration-200"
          >
            <FontAwesomeIcon
              icon={faUser}
              size="1x"
              className="text-white hover:text-customBlue "
            />
          </button>
        </div>
      )}

    </li>

         {/* User Icon */}
         {profile.username && (
            <div className="relative mt-4 flex justify-center">
              {/* Profile Dialog */}
              {isDialogOpen && (
                <div
                  ref={dialogRef}
                  className="absolute top-[calc(100%+10px)] bg-white p-4 m-2 rounded-lg shadow-md right-0 w-54 z-50"
                >
                  <h3 className="text-lg font-bold text-center">
                    Hi, {profile.username}
                  </h3>
                  <p className="text-center text-sm text-gray-600">
                    Email: {profile.email}
                  </p>
                  <div className="flex flex-col items-center mt-4 space-y-2">
                    <button
                      className="w-full bg-blue-500 text-white  font-raleway font-[16px] py-2 rounded-lg hover:bg-blue-600 transition-colors duration-200"
                      onClick={OpenviewProfile}
                    >
                      <span className="p-[9px] font-raleway">
                        View Your Profile
                      </span>
                    </button>
                    <button
                      className="w-full bg-gray-200 text-gray-700 py-2 rounded-lg hover:bg-gray-300 transition-colors duration-200"
                      onClick={handleLogout}
                    >
                      Logout
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        <li className="w-full">
          <Link
            to="/"
            className="text-white text-[15px] p-[5px] hover:bg-white hover:text-customBlue rounded"
          >
            Home
          </Link>
        </li>
        <li className="w-full">
          <Link
            to="/services"
            className="text-white text-[15px] p-[5px] hover:bg-white hover:text-customBlue rounded"
          >
            Generate QR code
          </Link>
        </li>
        <li className="w-full">
          <Link
            to="/aboutus"
            className="text-white text-[15px] p-4 hover:bg-white hover:text-customBlue rounded m-4"
          >
            About Us
          </Link>
        </li>
        <li className="w-full">
          <Link
            to="/contact-us"
            className="text-white text-[15px] p-4 hover:bg-white hover:text-customBlue rounded m-4"
          >
            Contact Us
          </Link>
        </li>
        <li className="w-full">
          <Link
            to="/faq"
            className="block text-white text-[18px] py-[10px] px-[15px] hover:bg-white hover:text-customBlue rounded transition-colors duration-200 w-full"
          >
            FAQ
          </Link>
        </li>
        <li className="w-full">
          <Link
            to="/pricing"
            className="block text-white text-[18px] py-[10px] px-[15px] hover:bg-white hover:text-customBlue rounded transition-colors duration-200 w-full"
          >
            Pricing
          </Link>
        </li>
        <li className="w-full">
          <Link
            to="/refund"
            className="text-white text-[15px] p-4 hover:bg-white hover:text-customBlue rounded m-4"
          >
            Refund Policy
          </Link>
        </li>
        <li className="w-full">
          <div className="relative text-white flex-col text-[15px] p-1 m-4 hover:bg-white hover:text-customBlue rounded">
            <button
              onClick={handleDropdownToggle}
              className=" flex items-center justify-center"
            >
              Languages
              <span
                className={`transition-transform ${
                  isDropdownOpen ? "rotate-180" : ""
                }`}
              >
                ▼
              </span>
            </button>
           <div className="flex p-2">
            {isDropdownOpen && (
              <ul className="absolute top-full left-0 bg-white text-black w-full m-2 p-2 rounded shadow-lg z-55">
                {languages.map((item) => (
                  <li key={item.code} className="p-4 hover:bg-gray-200 m-4">
                    <Link to="/comingsoon">{item.name}</Link>
                  </li>
                ))}
              </ul>
            )}
          </div>

          </div> 

     
        </li>
      </ul>
    </div>
  );
};

export default MobileNavBar;
