import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    price: 0,
    planName: '', // Plan name should be a string
};

const QrCodeCustmizablestoreReducer = createSlice({
    name: 'prices',
    initialState,
    reducers: {
        setPrice(state, action) {
            localStorage.setItem('price',JSON.stringify(action.payload));
            state.price = action.payload;
        },
        setPlanName(state, action) {
            localStorage.setItem('planName',JSON.stringify(action.payload));
            state.planName = action.payload; // Corrected this line to update planName
        },
        setResetPrice(state) {
            return initialState; // Resets state to the initial state
        }
    }
});

// Export all actions, including setPlanName
export const { setPrice, setPlanName, setResetPrice } = QrCodeCustmizablestoreReducer.actions;
export default QrCodeCustmizablestoreReducer.reducer;