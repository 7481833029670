import React, { useState, useRef, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faTimes, faGlobe } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import LoginUp from "../UserRegistrayion/LoginUp";
import SignUp from "../UserRegistrayion/SignUP";
import {
  setPlanName,
  setPrice,
} from "../store/chooseBestOffers/BestOffersSlicer";
import { setInstanceId } from "../store/ServiceSavedInstance/ServiceInstanceReducer";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { ColorRing } from "react-loader-spinner";
import { setReset } from "../store/downloadButtonRefernecSliceReducer";
import CustomAlert from "../CustomAlertForSubmision";
import styles from "../module/navbar.module.css";

const VehicleForm = () => {
  const fileInputRef = useRef(null);
  const [showPassword, setShowPassword] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [switchButton, setSwitchButton] = useState(true);
  const [isLogin, setIsLogin] = useState(false);
  const dispatch = useDispatch();

  //For  choose Files
  const [files, setFiles] = useState([]);
  const [filesForRc, setFilesForRC] = useState([]);
  const [filesForDriving, setFilesForDriving] = useState([]);
  const [filesForHealth, setFilesForHealth] = useState([]);
  const [filesForOwner, setFilesForOwner] = useState([]);


  // after upload get file paths
  const [filePaths, setFilePath] = useState([]);
  const [filePathForRC, setFilePathForRC] = useState([]);
  const [filePathForDriving, setFilePathForDriving] = useState([]);
  const [filePathForHealth, setFilePathForHealth] = useState([]);
  const [filePathForOwner, setFilePathForOwner] = useState([]);

  const [serviceName, setServiceName] = useState("");
  const profile = useSelector((state) => state.profile);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [loadingForRC, setLoadingForRC] = useState(false);
  const [loadingForHealth, setLoadingForHealth] = useState(false);
  const [loadingForOwner, setLoadingForOwner] = useState(false);
  const [loadingForDriving, setLoadingForDriving] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState("");
  const [buttonText, setButtonText] = useState("Submit");
  const userProfile = useSelector((state) => state.profile);

  const backendUrl =
    process.env.REACT_APP_ENVIRONMENT === "production"
      ? "https://backend.zmqrcode.com"
      : "http://localhost:3000";

  useEffect(() => {
    const queryParam = new URLSearchParams(location.search).get("name");
    if (queryParam) {
      setServiceName(queryParam);
    }
  }, [location]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  
  const chooseFileHandleChange = async (event, setFieldValue) => {
    localStorage.removeItem("serviceInstanceId");
    setButtonText("Submit");
    if (profile.username !== "" && (event.target.name !== "fileForRc" && event.target.name !== "fileForDriving" && event.target.name !== "fileForHealth" && event.target.name !== "fileForOwner")) {
      setLoading(true);
      const selectedFiles = Array.from(event.target.files);
      setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
      setFieldValue("files", selectedFiles);

      const formData = new FormData();
      selectedFiles.forEach((file) => {
        formData.append("file", file);
      });

      try {
        const uploadData = await postUploadFile(
          `${backendUrl}/upload`,
          formData
        );

        const data = uploadData.data;

        if (Array.isArray(data)) {
          const newFilePaths = data.map((item) => item.url);

          setFilePath((prevFilePaths) => [...prevFilePaths, ...newFilePaths]);
        } else {
          alert("Data is not an array", data);
        }

        dispatch(setReset());
      } catch (error) {
        const errorMessage = error.message || "An error occurred";
        const statusCode =
          error.response && error.response.status
            ? error.response.status.toString()
            : "No status code";
        setShowAlert(true);
        setAlertMessage(
          `Error uploading files: ${errorMessage} (Status Code: ${statusCode})`
        );
        console.error("Error uploading files:", error);
      } finally {
        setLoading(false);
      }
    } else
      if (profile.username !== "" && (event.target.name !== "file" && event.target.name !== "fileForDriving" && event.target.name !== "fileForHealth" && event.target.name !== "fileForOwner")) {
        setLoadingForRC(true);
        const selectedFiles = Array.from(event.target.files);
        setFilesForRC((prevFiles) => [...prevFiles, ...selectedFiles]);
        setFieldValue("files", selectedFiles);

        const formData = new FormData();
        selectedFiles.forEach((file) => {
          formData.append("file", file);
        });

        try {
          const uploadData = await postUploadFile(
            `${backendUrl}/upload`,
            formData
          );

          const data = uploadData.data;

          if (Array.isArray(data)) {
            const newFilePaths = data.map((item) => item.url);

            setFilePathForRC((prevFilePaths) => [...prevFilePaths, ...newFilePaths]);
          } else {
            alert("Data is not an array", data);
          }

          dispatch(setReset());
        } catch (error) {
          const errorMessage = error.message || "An error occurred";
          const statusCode =
            error.response && error.response.status
              ? error.response.status.toString()
              : "No status code";
          setShowAlert(true);
          setAlertMessage(
            `Error uploading files: ${errorMessage} (Status Code: ${statusCode})`
          );
          console.error("Error uploading files:", error);
        } finally {
          setLoadingForRC(false);
        }
      }
      else
        if (profile.username !== "" && (event.target.name !== "file" && event.target.name !== "fileForRC" && event.target.name !== "fileForHealth" && event.target.name !== "fileForOwner")) {
          setLoadingForDriving(true);
          const selectedFiles = Array.from(event.target.files);
          setFilesForDriving((prevFiles) => [...prevFiles, ...selectedFiles]);
          setFieldValue("files", selectedFiles);

          const formData = new FormData();
          selectedFiles.forEach((file) => {
            formData.append("file", file);
          });

          try {
            const uploadData = await postUploadFile(
              `${backendUrl}/upload`,
              formData
            );

            const data = uploadData.data;

            if (Array.isArray(data)) {
              const newFilePaths = data.map((item) => item.url);

              setFilePathForDriving((prevFilePaths) => [...prevFilePaths, ...newFilePaths]);
            } else {
              alert("Data is not an array", data);
            }

            dispatch(setReset());
          } catch (error) {
            const errorMessage = error.message || "An error occurred";
            const statusCode =
              error.response && error.response.status
                ? error.response.status.toString()
                : "No status code";
            setShowAlert(true);
            setAlertMessage(
              `Error uploading files: ${errorMessage} (Status Code: ${statusCode})`
            );
            console.error("Error uploading files:", error);
          } finally {
            setLoadingForDriving(false);
          }
        }
        else
          if (profile.username !== "" && (event.target.name !== "file" && event.target.name !== "fileForRC" && event.target.name !== "fileForDriving" && event.target.name !== "fileForOwner")) {
            setLoadingForHealth(true);
            const selectedFiles = Array.from(event.target.files);
            setFilesForHealth((prevFiles) => [...prevFiles, ...selectedFiles]);
            setFieldValue("files", selectedFiles);

            const formData = new FormData();
            selectedFiles.forEach((file) => {
              formData.append("file", file);
            });

            try {
              const uploadData = await postUploadFile(
                `${backendUrl}/upload`,
                formData
              );

              const data = uploadData.data;

              if (Array.isArray(data)) {
                const newFilePaths = data.map((item) => item.url);

                setFilePathForHealth((prevFilePaths) => [...prevFilePaths, ...newFilePaths]);
              } else {
                alert("Data is not an array", data);
              }

              dispatch(setReset());
            } catch (error) {
              const errorMessage = error.message || "An error occurred";
              const statusCode =
                error.response && error.response.status
                  ? error.response.status.toString()
                  : "No status code";
              setShowAlert(true);
              setAlertMessage(
                `Error uploading files: ${errorMessage} (Status Code: ${statusCode})`
              );
              console.error("Error uploading files:", error);
            } finally {
              setLoadingForHealth(false);
            }
          }
          else
            if (profile.username !== "" && (event.target.name !== "file" && event.target.name !== "fileForRC" && event.target.name !== "fileForDriving" && event.target.name !== "fileForHealth")) {
              setLoadingForOwner(true);
              const selectedFiles = Array.from(event.target.files);
              setFilesForOwner((prevFiles) => [...prevFiles, ...selectedFiles]);
              setFieldValue("files", selectedFiles);

              const formData = new FormData();
              selectedFiles.forEach((file) => {
                formData.append("file", file);
              });

              try {
                const uploadData = await postUploadFile(
                  `${backendUrl}/upload`,
                  formData
                );

                const data = uploadData.data;

                if (Array.isArray(data)) {
                  const newFilePaths = data.map((item) => item.url);

                  setFilePathForOwner((prevFilePaths) => [...prevFilePaths, ...newFilePaths]);
                } else {
                  alert("Data is not an array", data);
                }

                dispatch(setReset());
              } catch (error) {
                const errorMessage = error.message || "An error occurred";
                const statusCode =
                  error.response && error.response.status
                    ? error.response.status.toString()
                    : "No status code";
                setShowAlert(true);
                setAlertMessage(
                  `Error uploading files: ${errorMessage} (Status Code: ${statusCode})`
                );
                console.error("Error uploading files:", error);
              } finally {
                setLoadingForOwner(false);
              }
            } else {
              setIsLogin(true);
            }
  };


  useEffect(() => {
    console.log(filesForRc);
    if ((files.length > 4) || (filesForDriving.length > 2) || (filesForHealth.length > 2) || (filesForOwner.length > 1) || (filesForRc.length > 2)) {
      alert("You exceed your Number Of Images.Please Check Once!!!");
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [files,filesForDriving,filePathForHealth,filePathForOwner,filesForRc]);

  const postUploadFile = async (url, formData) => {
    try {
      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      setShowAlert(true);
      setAlertMessage("Error uploading files:", error);
      throw error;
    }
  };

  const removeFile = (index, name) => {
    if (name === 'filesForRc') {
      setFilesForRC((prevFiles) => {
        const updatedFiles = prevFiles.filter((_, i) => i !== index);

        // Remove corresponding file path
        setFilePathForRC((prevFilePaths) =>
          prevFilePaths.filter((_, i) => i !== index)
        );

        // Update localStorage after the files have been updated
        if (updatedFiles.length > 0) {
          const resultFile = updatedFiles.map((file) => ({ name: file.name }));
          localStorage.setItem("files", JSON.stringify(resultFile));
        }

        return updatedFiles;
      });
    }

    if (name === 'filesForDriving') {
      setFilesForDriving((prevFiles) => {
        const updatedFiles = prevFiles.filter((_, i) => i !== index);

        // Remove corresponding file path
        setFilePathForDriving((prevFilePaths) =>
          prevFilePaths.filter((_, i) => i !== index)
        );

        // Update localStorage after the files have been updated
        if (updatedFiles.length > 0) {
          const resultFile = updatedFiles.map((file) => ({ name: file.name }));
          localStorage.setItem("files", JSON.stringify(resultFile));
        }

        return updatedFiles;
      });
    }

    if (name === 'filesForHealth') {
      setFilesForHealth((prevFiles) => {
        const updatedFiles = prevFiles.filter((_, i) => i !== index);

        // Remove corresponding file path
        setFilePathForHealth((prevFilePaths) =>
          prevFilePaths.filter((_, i) => i !== index)
        );

        // Update localStorage after the files have been updated
        if (updatedFiles.length > 0) {
          const resultFile = updatedFiles.map((file) => ({ name: file.name }));
          localStorage.setItem("files", JSON.stringify(resultFile));
        }

        return updatedFiles;
      });
    }


    if (name === 'filesForOwner') {
      setFilesForOwner((prevFiles) => {
        const updatedFiles = prevFiles.filter((_, i) => i !== index);

        // Remove corresponding file path
        setFilePathForOwner((prevFilePaths) =>
          prevFilePaths.filter((_, i) => i !== index)
        );

        // Update localStorage after the files have been updated
        if (updatedFiles.length > 0) {
          const resultFile = updatedFiles.map((file) => ({ name: file.name }));
          localStorage.setItem("files", JSON.stringify(resultFile));
        }

        return updatedFiles;
      });
    }

    if (name === 'files') {
      setFiles((prevFiles) => {
        const updatedFiles = prevFiles.filter((_, i) => i !== index);

        // Remove corresponding file path
        setFilePath((prevFilePaths) =>
          prevFilePaths.filter((_, i) => i !== index)
        );

        // Update localStorage after the files have been updated
        if (updatedFiles.length > 0) {
          const resultFile = updatedFiles.map((file) => ({ name: file.name }));
          localStorage.setItem("files", JSON.stringify(resultFile));
        }

        return updatedFiles;
      });
    }
    // Clear file input if it was the last file
    if (files.length === 1 && fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };


  const handleSubmitPetService = async (values, { resetForm }) => {
    if (profile.username !== "") {
      const formData = {
        serviceName: serviceName,
        modelName: values.modelName,
        typeOfVehicle: values.typeOfVehicle,
        RcNumber: values.RcNumber,
        BuyingDate: values.BuyingDate,
        description: values.description,
        DriverName: values.DriverName,
        ownerName: values.ownerName,
        dob: values.dob,
        emailId: values.emailId,
        contactNumber: values.contactNumber,
        alternateContactNumber: values.alternateContactNumber,
        address: values.address,
        mapLink: values.mapLink,
        filePaths: filePaths,
        filePathsRc: filePathForRC,
        filePathsDriving: filePathForDriving,
        filePathsHealth: filePathForHealth,
        filePathsOwner: filePathForOwner,
        QrCodePassword: values.password || "",
        userId: profile.userid,
      };
      try {
        if ((filePaths.length >= 1) || (filePathForDriving.length >= 1) || (filePathForHealth.length >= 1) || filePathForOwner.length >= 1 || (filePathForRC.length >= 1)) {
          const response = await axios.post(
            `${backendUrl}/purchaseQrCodeUserForVehicle`,
            formData
          );
          const purchasedServiceId = response.data.link;
          if (purchasedServiceId) {
            dispatch(setInstanceId(purchasedServiceId));
          }
          console.log(purchasedServiceId);
          dispatch(setPlanName("Best Plan For Vehicle Qr Code Sevice"));
          dispatch(setPrice("99"));

          setButtonText("Submitted");

        }

        resetForm();
        setFiles([]);
        setFilesForRC([]);
        setFilesForDriving([]);
        setFilesForOwner([]);
        setFilesForHealth([]);
        setFilePath([]);
        setFilePathForDriving([]);
        setFilePathForHealth([]);
        setFilePathForOwner([]);
        setFilePathForRC([]);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      } catch (error) {
        console.error("Error creating service instance:", error);
      }
    } else {
      setIsLogin(true);
    }
  };

  const BestPlans = useSelector((state) => state.Bestplans);
  useEffect(() => {
    if (BestPlans.price !== null && BestPlans.price !== 0) {
      setAlertMessage(
        `Your current Plan ${BestPlans.planName} and price:${BestPlans.price}`
      );
      setShowAlert(true);
    }
  }, [BestPlans]);

  const setLink = (setFieldValue) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const mapLink = `https://www.google.com/maps/?q=${latitude},${longitude}`;
          setFieldValue("mapLink", mapLink);
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const CustomHandleChange = (handleChange) => (event) => {
    handleChange(event);
    if (event.target.value !== '') {
      setButtonText('Submit');
      dispatch(setReset());
    }
  };


  return (
    <div className="max-w-lg mx-auto">
      <h1 className="text-2xl font-bold text-center mb-6">QR Code Generator for Vechile's</h1>
      <Formik
        initialValues={{
          modelName: "",
          typeOfVehicle: "",
          RcNumber: "",
          BuyingDate: "",
          filePaths: [],
          description: "",
          DriverName: "",
          ownerName: "",
          emailId: "",
          contactNumber: "",
          alternateContactNumber: "",
          address: "",
          mapLink: "",
          password: "",
        }}
        onSubmit={handleSubmitPetService}
      >
        {({ values, setFieldValue, handleChange, isSubmitting }) => (
          <Form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            {/* Restaurant Name input */}
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="modelName"
              >
                Vehicle Model Name
              </label>
              <Field
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                name="modelName"
                placeholder="Enter the Vehicle Model Name"
              />
            </div>

            {/* orgainizer name input */}
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="typeOfVehicle"
              >
                Types Of Vehicle
              </label>
              <Field
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                name="typeOfVehicle"
                placeholder="Enter the Types of Vehicle"
              />
            </div>
            {/* Date and Time Input */}
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="BuyingDate"
              >
                Date Of Buying
              </label>
              <Field
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="Date"
                name="BuyingDate"
                placeholder="Enter the Buying date"
              />
            </div>

            {/* File input for images */}
            <div>
              <div className="mb-6">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="file"
                >
                  Add Maximum 4 Vehicle Images
                </label>
                <input
                  id="file"
                  name="file"
                  type="file"
                  accept="image/*"
                  multiple
                  ref={fileInputRef}
                  onChange={(event) => {
                    const selectedFiles = event.target.files;
                    if (selectedFiles.length > 4) {
                      alert("You can only select up to 4 images.");
                      event.target.value = '';
                    } else {
                      chooseFileHandleChange(event, setFieldValue);
                    }
                  }}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {loading && (
                  <ColorRing
                    visible={true}
                    height="40"
                    width="30"
                    ariaLabel="comment-loading"
                    wrapperStyle={{}}
                    wrapperClass="comment-wrapper"
                    colors={['#1D91AA', '#1D91AA', '#1D91AA', '#1D91AA', '#1D91AA']} // This applies the same color to all circles
                  />
                )}
              </div>
              <div className="mt-2">
                {files.map((file, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between mt-2"
                  >
                    <span className="text-gray-700">{file.name}</span>
                    <button
                      type="button"
                      onClick={() => removeFile(index, "files")}
                      className="text-red-500 hover:text-red-700"
                    >
                      <FontAwesomeIcon
                        icon={faTimes}
                        className="text-customBlue"
                      />
                    </button>
                  </div>
                ))}
              </div>
            </div>
            {/* Description Input */}
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="description"
              >
                Description of Vehicle
              </label>
              <Field
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                as="textarea"
                name="description"
                placeholder="Enter a brief description of the Vehicle"
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="RcNumber"
              >
                RC Number
              </label>
              <Field
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                name="RcNumber"
                placeholder="Enter the RC Number"
              />
            </div>
            {/* File input for images */}
            <div>
              <div className="mb-6">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="file"
                >
                  Add Maximum 2 RC Images
                </label>
                <input
                  id="file"
                  name="fileForRc"
                  type="file"
                  accept="image/*"
                  multiple
                  ref={fileInputRef}
                  onChange={(event) => {
                    const selectedFiles = event.target.files;
                    if (selectedFiles.length > 2) {
                      alert("You can only select up to 2 images.");
                      event.target.value = '';
                    } else {
                      chooseFileHandleChange(event, setFieldValue);
                    }
                  }}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />

                {loadingForRC && (
                  <ColorRing
                    visible={true}
                    height="40"
                    width="30"
                    ariaLabel="comment-loading"
                    wrapperStyle={{}}
                    wrapperClass="comment-wrapper"
                    colors={['#1D91AA', '#1D91AA', '#1D91AA', '#1D91AA', '#1D91AA']} // This applies the same color to all circles
                  />
                )}
              </div>
              <div className="mt-2">
                {filesForRc.map((file, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between mt-2"
                  >
                    <span className="text-gray-700">{file.name}</span>
                    <button
                      type="button"
                      onClick={() => removeFile(index, "filesForRc")}
                      className="text-red-500 hover:text-red-700"
                    >
                      <FontAwesomeIcon
                        icon={faTimes}
                        className="text-customBlue"
                      />
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="DriverName"
              >
                Driver Name
              </label>
              <Field
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                name="DriverName"
                placeholder="Enter the Driver Name"
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="ownerName"
              >
                Owner Name
              </label>
              <Field
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                name="ownerName"
                placeholder="Enter the Owner Name"
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="dob"
              >
                DOB OF Owner/Driver
              </label>
              <Field
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="Date"
                name="dob"
                placeholder="Enter the Date Of Birth"
              />
            </div>
            {/* File input for images */}
            <div>
              <div className="mb-6">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="file"
                >
                  Add Owner/Driver Image
                </label>
                <input
                  id="file"
                  name="fileForOwner"
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  onChange={(event) =>
                    chooseFileHandleChange(event, setFieldValue)
                  }
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {loadingForOwner && (
                  <ColorRing
                    visible={true}
                    height="40"
                    width="30"
                    ariaLabel="comment-loading"
                    wrapperStyle={{}}
                    wrapperClass="comment-wrapper"
                    colors={['#1D91AA', '#1D91AA', '#1D91AA', '#1D91AA', '#1D91AA']} // This applies the same color to all circles
                  />
                )}
              </div>
              <div className="mt-2">
                {filesForOwner.map((file, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between mt-2"
                  >
                    <span className="text-gray-700">{file.name}</span>
                    <button
                      type="button"
                      onClick={() => removeFile(index, "filesForOwner")}
                      className="text-red-500 hover:text-red-700"
                    >
                      <FontAwesomeIcon
                        icon={faTimes}
                        className="text-customBlue"
                      />
                    </button>
                  </div>
                ))}
              </div>
            </div>
            {/* File input for images */}
            <div>
              <div className="mb-6">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="file"
                >
                  Add Maximum 2 Driving Licence Images
                </label>
                <input
                  id="file"
                  name="fileForDriving"
                  type="file"
                  accept="image/*"
                  multiple
                  ref={fileInputRef}
                  onChange={(event) => {
                    const selectedFiles = event.target.files;
                    if (selectedFiles.length > 2) {
                      alert("You can only select up to 2 images.");
                      event.target.value = '';
                    } else {
                      chooseFileHandleChange(event, setFieldValue);
                    }
                  }}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />

                {loadingForDriving && (
                  <ColorRing
                    visible={true}
                    height="40"
                    width="30"
                    ariaLabel="comment-loading"
                    wrapperStyle={{}}
                    wrapperClass="comment-wrapper"
                    colors={['#1D91AA', '#1D91AA', '#1D91AA', '#1D91AA', '#1D91AA']} // This applies the same color to all circles
                  />
                )}
              </div>
              <div className="mt-2">
                {filesForDriving.map((file, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between mt-2"
                  >
                    <span className="text-gray-700">{file.name}</span>
                    <button
                      type="button"
                      onClick={() => removeFile(index, "filesForDriving")}
                      className="text-red-500 hover:text-red-700"
                    >
                      <FontAwesomeIcon
                        icon={faTimes}
                        className="text-customBlue"
                      />
                    </button>
                  </div>
                ))}
              </div>
            </div>
            {/* File input for images */}
            <div>
              <div className="mb-6">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="file"
                >
                  Add Maximum 2 Health Insurance Images
                </label>
                <input
                  id="file"
                  name="fileForHealth"
                  type="file"
                  accept="image/*"
                  multiple
                  ref={fileInputRef}
                  onChange={(event) => {
                    const selectedFiles = event.target.files;
                    if (selectedFiles.length > 2) {
                      alert("You can only select up to 2 images.");
                      event.target.value = '';
                    } else {
                      chooseFileHandleChange(event, setFieldValue);
                    }
                  }}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />

                {loadingForHealth && (
                  <ColorRing
                    visible={true}
                    height="40"
                    width="30"
                    ariaLabel="comment-loading"
                    wrapperStyle={{}}
                    wrapperClass="comment-wrapper"
                    colors={['#1D91AA', '#1D91AA', '#1D91AA', '#1D91AA', '#1D91AA']} // This applies the same color to all circles
                  />
                )}
              </div>
              <div className="mt-2">
                {filesForHealth.map((file, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between mt-2"
                  >
                    <span className="text-gray-700">{file.name}</span>
                    <button
                      type="button"
                      onClick={() => removeFile(index, "filesForHealth")}
                      className="text-red-500 hover:text-red-700"
                    >
                      <FontAwesomeIcon
                        icon={faTimes}
                        className="text-customBlue"
                      />
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="emailId"
              >
                Email Id
              </label>
              <Field
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="email"
                name="emailId"
                placeholder="Enter the Email Id"
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="contactNumber"
              >
                Contact Number
              </label>
              <Field
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="phone"
                name="contactNumber"
                placeholder="Enter the Contact Number"
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="alternateContactNumber"
              >
                Alternate Contact Number
              </label>
              <Field
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="phone"
                name="alternateContactNumber"
                placeholder="Enter the Alternate Contact Number"
              />
            </div>

            {/* Address Input */}
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="address"
              >
                Owner/Driver Address
              </label>
              <Field
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                as="textarea"
                name="address"
                placeholder="Enter the address"
              />
            </div>

            {/* Map Link Input */}
            <div className="mb-4 flex items-center justify-center">
              <div className="flex flex-col w-full">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="mapLink"
                >
                  Owner/Driver Map Link
                </label>
                <Field
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  name="mapLink"
                  placeholder="Enter a Google Maps link (optional)"
                  onChange={CustomHandleChange(handleChange)}
                />
              </div>
              <button
                type="button"
                className="ml-2 border-none flex justify-center items-center mt-5 p-[5px]"
                style={{ width: "0px" }}
                onClick={() => setLink(setFieldValue)}
              >
                <FontAwesomeIcon icon={faGlobe} size="1x" color="#1D91AA" />
              </button>
            </div>


            {/* Password input */}
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                Password
              </label>
              <div className="flex items-center relative">
                <Field
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  placeholder="Enter the Password"
                  onChange={CustomHandleChange(handleChange)}
                />
                {/* Password visibility toggle button outside, positioned next to the input */}
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="ml-2 text-gray-600 hover:text-gray-900"
                  style={{ width: "0px" }}
                >
                  <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} size="1x" color="#1D91AA" />
                </button>
              </div>
            </div>


            {/* Submit button */}
            <div className="flex items-center justify-between">
              {!disableButton && <button
                className=" text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
                style={{ backgroundColor: "#1D91AA" }}
                disabled={isSubmitting}
              >
                {buttonText}
              </button>
              }
            </div>
          </Form>
        )}
      </Formik>
      {showAlert && <CustomAlert message={alertMessage} onClose={() => { setShowAlert(false); }} />}
      {isLogin && (
        <div className="flex justify-center items-center h-screen">
          {/* Trigger button */}
          <button
            onClick={() => setIsLogin(true)}
            className="bg-blue-500 text-white p-3 rounded-lg"
          >
            Open Sign-Up/Login
          </button>


          {/* Modal */}
          {((userProfile.username === '') && isLogin) && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
              <div className="bg-white p-8 rounded-lg w-96">
                <div>
                  {/* Switch buttons */}
                  <div className="flex justify-end">
                    <button
                      onClick={() => setIsLogin(false)}
                      className="text-gray-600 hover:text-red-500"
                    >
                      X
                    </button>
                  </div>
                  <div className={styles.formsignupandlogin}>
                    <div className="flex flex-row">
                      <button
                        className={switchButton ? styles.selected : styles.notselected} style={{
                          fontSize: "14px", // font-size becomes fontSize
                          margin: "15px",   // margin
                          padding: "15px",  // padding
                        }}
                        onClick={() => setSwitchButton(true)}
                      >
                        Sign Up
                      </button>
                      <button
                        className={!switchButton ? styles.selected : styles.notselected}
                        onClick={() => setSwitchButton(false)}

                      >
                        Login
                      </button>
                    </div>
                  </div>


                  {/* Conditional Form Rendering */}
                  {switchButton ? <SignUp /> : <LoginUp />}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default VehicleForm;
