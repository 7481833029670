import React from "react";
import { Link } from "react-router-dom";
import NavBar from "./NavBar";

const RefundPolicy = () => {
  return (
    <div>
      <NavBar />
      <div className="bg-gray-100 py-8 px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg p-8">
          <h2 className="text-3xl font-bold mb-4 text-gray-800 text-center">
            Refund Policy
          </h2>
          <p className="text-gray-600 mb-6">
            We appreciate your purchase.Please read the details of our refund
            policy below.
          </p>
          <div className="flex justify-center items-center font-raleway flex-col">
            <p>
              Refunds for digital services are often restricted due to the
              instant nature of access. Once a digital service (like software,
              e-books, online courses, etc.) is purchased and downloaded, it can
              be difficult to reclaim or revoke access, which is why many
              companies have strict no-refund policies for these items...zm
              qrcode services not refunds for any digital qr code service...
            </p>
            <p className="p-5 m-2 font-bold">
              For any further questions or clarifications regarding our refund
              policy, please reach out to us at{" "}
              <Link to="/contact-us" className="text-blue-500 underline">
                Contact Us
              </Link>
              . We are here to assist you.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefundPolicy;
