import { useLocation } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import React from "react";
import eventCard from "../assets/eventCard.png";

const SmsOutputWindow = () => {
    const location = useLocation();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [textMessage, setTextMessage] = useState("");
    const [isExpanded, setIsExpanded] = useState(false);

    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get("id");
    const backendUrl =
        process.env.REACT_APP_ENVIRONMENT === "production"
            ? "https://backend.zmqrcode.com"
            : "http://localhost:3000";

    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${backendUrl}/purchaseQrCodeUserForSms/${id}`
                );

                if (isMounted) {
                    setData(response.data);
                }
            } catch (err) {
                if (isMounted) setError(err);
            } finally {
                if (isMounted) setLoading(false);
            }
        };

        fetchData();

        return () => {
            isMounted = false;
        };
    }, [id, backendUrl]);

    useEffect(() => {
        if (data) {
            setTextMessage(data.textmessage || "Not Filled");
        }
    }, [data]);

    const toggleExpand = () => {
        setIsExpanded((prev) => !prev);
    };

    const renderEventDetails = () => {
        if (loading) return <div>Loading...</div>;
        if (error) return <div>Error: {error.message}</div>;

        // Truncated message logic
        const truncatedMessage =
            textMessage.length > 50 && !isExpanded
                ? `${textMessage.substring(0, 50)}...`
                : textMessage;
                return (
    
                    <div className="flex flex-col items-center bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen py-8 px-6">
                        {/* Container with hover effect */}
                        <div className="flex flex-col justify-center items-center p-6 sm:p-8 md:p-10 rounded-xl transform transition duration-500 hover:scale-110 hover:shadow-3xl">
                            <div
                                className="flex flex-col rounded-xl text-[#fff] shadow-lg p-6 sm:p-8 md:p-10 relative overflow-hidden perspective-3d group"
                                style={{
                                    backgroundImage: `url(${eventCard})`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                }}
                            >
                                {/* Animated Background Overlay */}
                                <div className="absolute inset-0 bg-gradient-to-t from-[#690e0e] via-transparent to-transparent opacity-80 z-0 group-hover:scale-110 transition-transform duration-1000 ease-in-out animate-pulse"></div>
                
                                {/* Glow Effect */}
                                <div className="absolute -inset-2 rounded-xl bg-gradient-to-r from-pink-500 to-purple-500 opacity-50 blur-md group-hover:opacity-80 transition duration-700"></div>
                
                                {/* Card Content */}
                                <div className="flex flex-col items-center w-full max-w-[700px] p-6 sm:p-8 md:p-10 bg-white shadow-lg rounded-xl border border-gray-300 z-10 transform transition-all duration-500 ease-out hover:shadow-2xl">
                                    {/* Animated Icon */}
                                    <div className="relative flex justify-center items-center w-16 h-16 bg-gradient-to-r from-indigo-500 to-purple-500 text-white rounded-full shadow-lg animate-bounce mb-4">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-8 w-8"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M12 8c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3z"
                                            />
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M19 13l-2-2-2 2M5 13l2-2 2 2M19 13l-7 7-7-7"
                                            />
                                        </svg>
                                    </div>
                
                                    {/* Message Details */}
                                    <div className="relative flex flex-col text-center items-center justify-center text-gray-800 space-y-3 sm:space-y-4">
                                        <label className="font-raleway font-semibold text-[15px] sm:text-[18px] md:text-[18px] text-gray-800 transition-all duration-300 hover:scale-105 hover:text-[rgb(236,54,130)]">
                                            {truncatedMessage}
                                        </label>
                                    </div>
                
                                    {/* Read More/Read Less Button */}
                                    {textMessage.length > 50 && (
                                        <button
                                            onClick={toggleExpand}
                                            className="mt-6 px-6 py-3 bg-gradient-to-r from-pink-500 to-purple-500 text-white font-bold rounded-lg shadow-md transform transition duration-300 hover:scale-105 hover:shadow-lg"
                                        >
                                            {isExpanded ? "Read Less" : "Read More"}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                 
                );
                
    };

    return <div>{renderEventDetails()}</div>;
};

export default SmsOutputWindow;
