import React from 'react';


const AlertForAfterPayment = ({ message, OnClose}) => {
    

    const onClosePopUp = () => {
        // Ensure that the URL or environment variable is correctly used.
        const redirectUrl = process.env.REACT_APP_ENVIRONMENT_URL_FOR_FRONTEND;
        if (redirectUrl) {
            window.location.href = process.env.REACT_APP_FOR_REDIRECT_SERVICE;
        } else {
            console.error('Redirect URL is not defined.');
        }
    };
    

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-[#1D91AA] text-white p-6 rounded-lg shadow-lg w-80">
                <p className="mb-4">{message}</p>
                <button
                    onClick={onClosePopUp}
                    className="bg-black text-[#1D91AA] px-4 py-2 rounded-lg font-semibold transition-colors duration-300 hover:bg-[#1D91AA] hover:text-white"
                >
                    OK
                </button>
            </div>
        </div>
    );
};

export default AlertForAfterPayment;
