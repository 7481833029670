// userSetProfileReducer.js
import { SET_PROFILE, RESET_PROFILE } from './userProfileactionTypes';

const initialStateProfileState = {
    username: '',
    email: '',
    usagecount: 0,
    token: '',
    userid:'',
    image: ''
};

// Corrected reducer function
const profileReducer = (state = initialStateProfileState, action) => {
    switch (action.type) {
        case SET_PROFILE:
            return {
                ...state,
                username: action.payload.username || state.username,
                email: action.payload.email || state.email,
                usagecount: action.payload.usagecount || state.usagecount,
                token: action.payload.token || state.token,
                userid: action.payload.userid || state.userid,
                image: action.payload.image || state.image
            };
        case RESET_PROFILE:
            return initialStateProfileState;
        default:
            return state;
    }
};

export default profileReducer;
