import React from "react";
import NavBar from "../NavBar";
import { useNavigate } from "react-router-dom";
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useMediaQuery from "../usematchqueryfortrackscreensize/useMediaQuery";

const Pricing = () => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width: 960px)');

    const moreinfo = () => {
        navigate('/industry');
    };

    const moreinfoI = () => {
        navigate('/pricingpageforindividuals');
    };

    return (
        <>
            <NavBar />
            <div className="flex justify-center items-center shadow-[0_5px_15px_rgba(0,0,0,0.35)] m-4 rounded-[11px]">
                <div className="container">
                    <div className={` ${isMobile? 'flex flex-col':'flex flex-row '} 'p-4 justify-center items-center'`}>
                        <div className="bg-beige border border-gray-300 rounded-lg shadow-lg max-w-lg p-8 text-center justify-center font-raleway m-[16px]">
                            <h1 className="text-blue-500 text-4xl font-raleway p-4 font-raleway font-700">For Industry</h1>
                            <span className="p-4 m-4 font-raleway font-300">Designed for businesses that need advanced QR code generation and management features. This plan offers scalable solutions for creating, tracking, and analyzing QR codes, with capabilities tailored to meet the complex needs of larger organizations
                            </span>
                            <br />
                            <div>
                                <h3 className="font-raleway font-bold text-xl text-orange-500 p-4 text-[1.5rem]">Storage</h3>
                                <p>upto 1TB</p>
                            </div>
                            <div>
                                <h3 className="font-raleway font-bold text-xl text-blue-500 p-4 text-[1.5rem]">Features</h3>
                                <ul className="list-none pl-0 flex justify-center flex-col items-center">
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faCheck} className="text-green-500 mr-2" />
                                        Custom Branding
                                    </li>
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faCheck} className="text-green-500 mr-2" />
                                        Bulk QR Code Generation
                                    </li>
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faCheck} className="text-green-500 mr-2" />
                                        Advanced Analytics
                                    </li>
                                </ul>
                            </div>
                            <div className="flex flex-col  flex justify-center items-center">
                                <h3 className="font-bold mb-4 p-4 text-[1.7rem] font-raleway">Benefits</h3>
                                <ul className="list-none p-0 flex justify-center items-center flex-col">
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faCheck} className="text-green-500 mr-2" />
                                        Enhanced Brand Visibility
                                    </li>
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faCheck} className="text-green-500 mr-2" />
                                        Efficient Operations
                                    </li>
                                    <button
                                    className="bg-white text-customBlue hover:bg-customBlue hover:text-white font-bold p-4 m-4 rounded flex justify-center items-center w-[165px] m-[16px]"
                                    onClick={moreinfo}
                                >
                                    More info
                                </button>
                                </ul>
                 
                            </div>
                        </div>

                        <div className="bg-beige border border-gray-300 rounded-lg shadow-lg max-w-lg p-[23px] text-center justify-center font-raleway flex-col">
                            <h1 className="text-blue-500 text-4xl font-raleway p-4 font-raleway font-700">For Individual</h1>
                            <span className="p-4 m-4 font-raleway font-300">Ideal for personal use, offering an easy and affordable way to generate and use QR codes. This plan is perfect for individuals who need QR codes for personal projects, event invitations, or small-scale marketing efforts.
                            </span>
                            <br />
                            <div>
                                <h3 className="font-raleway font-bold text-xl text-orange-500 p-4 text-[1.5rem]">Storage</h3>
                                <p>Required Storage Database</p>
                            </div>

                            <div className="flex flex-col justify-center items-center">
                                <h3 className="font-raleway font-bold text-xl text-blue-500 p-4 text-[1.5rem]">Features</h3>
                                <ul className="list-none pl-0">
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faCheck} className="text-green-500 mr-2" />
                                        Cost-Effective
                                    </li>
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faCheck} className="text-green-500 mr-2" />
                                        Ease of Use
                                    </li>
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faCheck} className="text-green-500 mr-2" />
                                        Versatility
                                    </li>
                                </ul>
                            </div>
                            <div className="flex flex-col justify-center align-center">
                                <h3 className="font-bold mb-4 p-4 text-[1.7rem] font-raleway">Benefits</h3>
                                <ul className="list-none p-0 flex justify-center items-center flex-col">
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faCheck} className="text-green-500 mr-2" />
                                        Cost-Effective
                                    </li>
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faCheck} className="text-green-500 mr-2" />
                                        Ease of Use
                                    </li>
                                    <li className="flex items-center mb-2">
                                        <FontAwesomeIcon icon={faCheck} className="text-green-500 mr-2" />
                                        Versatility
                                    </li>
                                    <button className="bg-white text-customBlue hover:bg-customBlue hover:text-white font-bold py-2 px-4 rounded flex justify-center items-center w-[165px] m-[16px]" onClick={moreinfoI}>More info</button>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Pricing;
