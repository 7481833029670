import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faWindowClose  } from '@fortawesome/free-solid-svg-icons';
// import { Html5QrcodeScanner } from 'html5-qrcode';
import QrScanner
    from 'qr-scanner';
const ScanQRBox = ({ handleClose }) => {
   
    const handleFileQrImageUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                const url = URL.createObjectURL(file);
                const result = await QrScanner.scanImage(url);
                if (result) {
                    window.location.href = result;
                } else {
                    alert('No QR code detected. Please try a different image.');
                }
                URL.revokeObjectURL(url);
            } catch (error) {
                console.error('Error scanning QR code:', error);
                alert('Failed to scan QR code from image. Please try again.');
            }
        }
    };


    return (
        <div className="fixed inset-0 m-[16px] p-4 flex flex-col items-center justify-center bg-[#1D91AA] bg-opacity-50 z-50">
            <div className="text-white p-6 bg-[#1D91AA] rounded-lg shadow-lg w-[80%] h-[80%] flex flex-col justify-center items-center">
                <div className='flex flex-col justify-center items-center'>
                    <FontAwesomeIcon icon={faImage} className="mb-4 w-[158px] h-[95px]" />
                    <input
                        type="file"
                        name="uploadfile"
                        id="img"
                        onChange={handleFileQrImageUpload}
                        style={{ display: 'none' }}
                        className='cursor-pointer'
                    />
                    <label htmlFor="img" className="font-raleway font-semibold mt-[16px] cursor-pointer">
                        Click me to upload QR image
                    </label>
                </div>

                {/* {!isScannerActive && ( */}
                    {/* <FontAwesomeIcon
                        icon={faCamera}
                        className="w-[54px] h-[34px] p-[16px] cursor-pointer"
                        //onClick={startScanner} // Start scanner on camera icon click
                    /> */}
                {/* )}

                {isScannerActive && (
                    <>
                        <div id="reader" style={{ width: '100%', height: '400px' }}></div>
                        <button onClick={stopScanner} className="mt-4 text-white">
                            Stop Scanner
                        </button>
                    </>
                )} */}
            </div>

            <button onClick={handleClose} className="mt-4 text-white">
                <FontAwesomeIcon icon={faWindowClose} className="w-[24px] h-[24px]" />
            </button>
        </div>
    );
};

export default ScanQRBox;
