import { useRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import useMediaQuery from "../usematchqueryfortrackscreensize/useMediaQuery";
import QRCodeStyling from "qr-code-styling";
import qrcodelogoimgfiles from "../resources/qrcodelogoimgfiles.json";
import {
  setImage,
  setData,
} from "../store/QrCodeCustmizable/QrCodecustmizableSlicer";
import axios from "axios";
import LoginUp from "../UserRegistrayion/LoginUp";
import SignUp from "../UserRegistrayion/SignUP";
import styles from "../module/navbar.module.css";
import useLoadScript from "../reusableHook/useLoadScript";
import CustomAlert from "../CustomAlertForSubmision";
import AlertForAfterPayment from "../AlertForAfterPayment";

const CustomizableQrCodewindowContentforLogos = () => {
  const qrCodeRef = useRef(null);
  const qrCodeInstance = useRef(null);
  const [filetype, setFiletype] = useState("png");
  const qrCodeState = useSelector((state) => state.QrCodeCustmizable);
  const isMobile = useMediaQuery("(max-width: 960px)");
  const dispatch = useDispatch();

  const userProfile = useSelector((state) => state.profile);
  const [isLogin, setIsLogin] = useState(false);
  const [switchButton, setSwitchButton] = useState(true);
  const { loaded, error } = useLoadScript(
    "https://checkout.razorpay.com/v1/checkout.js"
  );
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const downloadText = useSelector((state) => state.downloadText.downloadText);
  const [showAlertForAfterPayment, setShowAlertForAfterPayment] = useState(false);
  const [showAlertForAfterPaymentMessage, setShowAlertForAfterPaymentMessage] = useState(false);


  useEffect(() => {
    if (!qrCodeRef.current) return;

    qrCodeInstance.current = new QRCodeStyling({
        width: qrCodeInstance.width,
        height: qrCodeInstance.height,
        margin: qrCodeState.margin,
        image: qrCodeState.image, // Use state image
        data: qrCodeState.data,
        dotsOptions: {
            color: qrCodeState.dotsOptions.color,
            type: qrCodeState.dotsOptions.type,
        },
        cornersDotOptions: {
            color: qrCodeState.cornersDotOptions.color,
            type: qrCodeState.cornersDotOptions.type,
        },
        cornersSquareOptions: {
            color: qrCodeState.cornersSquareOptions.color,
            type: qrCodeState.cornersSquareOptions.type,
        },
        backgroundOptions: {
            color: qrCodeState.backgroundOptions.color,
        },
        imageOptions: {
            crossOrigin: qrCodeState.imageOptions.crossOrigin,
            margin: qrCodeState.imageOptions.margin,
            imageSize: qrCodeState.imageOptions.imageSize,
            hideBackgroundDots: qrCodeState.imageOptions.hideBackgroundDots,
        },
         errorCorrectionLevel: 'H'
    });

    qrCodeInstance.current.append(qrCodeRef.current);

    return () => {
        qrCodeInstance.current = null;
    };
}, [qrCodeState]);

 // Update QR code instance when state changes
 useEffect(() => {
    if (qrCodeInstance.current) {
        qrCodeInstance.current.update({
            width: qrCodeState.width,
            height: qrCodeState.height,
            image: qrCodeState.image,
            margin: qrCodeState.margin,
            data: qrCodeState.data,
            dotsOptions: {
                color: qrCodeState.dotsOptions.color,
                type: qrCodeState.dotsOptions.type,
            },
            cornersDotOptions: {
                color: qrCodeState.cornersDotOptions.color,
                type: qrCodeState.cornersDotOptions.type,
            },
            cornersSquareOptions: {
                color: qrCodeState.cornersSquareOptions.color,
                type: qrCodeState.cornersSquareOptions.type,
            },
            backgroundOptions: {
                color: qrCodeState.backgroundOptions.color,
            },
            imageOptions: {
                crossOrigin: qrCodeState.imageOptions.crossOrigin,
                margin: qrCodeState.imageOptions.margin,
                imageSize: qrCodeState.imageOptions.imageSize,
                hideBackgroundDots: qrCodeState.imageOptions.hideBackgroundDots,
            },
             errorCorrectionLevel: 'H'
        });
    }
}, [qrCodeState]);

const handleChosseLogo = (path) => {
  dispatch(setImage(path));
};

  const serviceInstanceId = useSelector(
    (state) => state.serviceInstance.serviceId
  );

  const bestPlans = useSelector((state) => state.Bestplans);

  const profile = useSelector((state) => state.profile);

  const backendUrl = process.env.REACT_APP_ENVIRONMENT === 'production'? 'https://backend.zmqrcode.com' : 'http://localhost:3000';  

 
const handlePayment = async () => {
  // Check if the user is logged in
  if (userProfile.username !== '') {
    // Check if Razorpay script is loaded
    if (!loaded) {
      alert('Razorpay SDK is still loading...');
      return;
    }

    // Check if there was an error loading Razorpay script
    if (error) {
      alert('Failed to load Razorpay SDK. Please try again.');
      return;
    }

    try {
      // Get order ID from the backend
      const { data } = await axios.post(`${backendUrl}/orders`, {
        amount:bestPlans.price===0?1*100 : bestPlans.price * 100,
        currency: "INR",
      });

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID, // Live key
        amount: data.amount,
        currency: data.currency,
        order_id: data.order_id,
        name: `ZM QR Code Service - ${bestPlans.planName}`,
        description: "Test Transaction for QR Code",
        handler: async function (response) {
          alert("Payment Successful! Payment ID: " + response.razorpay_payment_id);
          try {
            const formData = {
              username: profile.username,
              email: profile.email,
              planName: bestPlans.planName,
              price: bestPlans.price,
              transationId: response.razorpay_payment_id,
            };
          
            // POST request with formData
            const paymentRemainder = await axios.post(
              `${backendUrl}/paymentDone`,
              formData
            );
            if (paymentRemainder.status === 201) {
              setShowAlertForAfterPayment(true);
            setShowAlertForAfterPaymentMessage(`Thank you choosing Our Plan ${bestPlans.planName} , price: ${bestPlans.price}`);
          }
            localStorage.removeItem('price');
            localStorage.removeItem('planName');
          } catch (error) {
            setShowAlert(true);
            setAlertMessage(error.message || "An unexpected error occurred.");
          }
          if(serviceInstanceId===""){
            setShowAlert(true);
            setAlertMessage("Please Submit the form");
          }
          if(serviceInstanceId!==""){
          dispatch(setData(serviceInstanceId));
          }
        },
        prefill: {
          name: userProfile.username,
          email: userProfile.email,
        },
        theme: {
          color: "#F37254",
        },
      };

      if(serviceInstanceId!==""){
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    }
    else if(serviceInstanceId===""){
      console.log(serviceInstanceId);
      setShowAlert(true);
      setAlertMessage("Please Submit the form");
    }
   } catch (error) {
      alert("Error while creating order. Please try again.");
    }
    } else {
      // If the user is not logged in, show the login modal
      setIsLogin(true);
    }
  };

  const onExtensionChange = (event) => {
    setFiletype(event.target.value);
  };



  const handleLogoUpdate = async (event) => {
    try {
      if (!event.target.files[0]) {
        console.error("No file selected");
        return;
      }

      const formData = new FormData();
      formData.append("file", event.target.files[0]); // Ensure the key matches server-side

      const data = await postUploadImage(
        `${backendUrl}/uploadFileForUser`,
        formData
      );

      const urlOfImage = `${backendUrl}/getUploadForUserByFileId/${data.uploadedFiles[0].fileId}`;
      if(data!==''){
        localStorage.setItem('logoUrl',JSON.stringify(urlOfImage));
      }
      dispatch(setImage(urlOfImage));
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };
  
  const postUploadImage = async (url, formData) => {
    try {
      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Set content type for form data
        },
      });

      return response.data; // Return the data directly from the response
    } catch (error) {
      console.error("Error during upload:", error);
      throw error; // Rethrow error to handle in the caller
    }
  };

  return (
    <div className="flex flex-col">
      <div
        className={`${
          isMobile
            ? "flex flex-col-reverse justify-center w-[90%]"
            : "flex flex-row justify-between w-full"
        }`}
      >
        <div className="flex flex-col">
          <ul
            className={`${
              isMobile
                ? "grid grid-cols-5 gap-2 justify-center"
                : "flex flex-wrap p-2"
            }`}
          >
            {qrcodelogoimgfiles.map((item, index) => (
              <li
                key={index}
                className={`${
                  isMobile
                    ? "flex-col font-raleway"
                    : "p-2 m-2 flex-col font-raleway"
                }`}
              >
                <button
                  className="bg-transparent border-none"
                  aria-label={`Choose QR code body ${index}`}
                  onClick={() => handleChosseLogo(item.img)}
                >
                  <img
                    src={item.img}
                    alt={`QR code body ${index}`}
                    loading="lazy"
                    className={`${
                      isMobile
                        ? "w-[50px] h-[50px] p-[2px]"
                        : "w-[65px] h-[65px] p-4"
                    } rounded-full border-2 border-solid border-gray-300 shadow-lg bg-white`}
                  />
                </button>
              </li>
            ))}
            <li>
              <button
                className={`bg-transparent border-none relative m-${
                  isMobile ? "[16px]" : "[36px]"
                }`}

                //onClick={handleUpload}
              >
                {/* <label
                  className={
                    isMobile
                      ? "flex justify-center items-center text-[13px] q w-[85px]"
                      : "text-customBlue font-raleway"
                  }
                >
                  Choose Logo
                </label> */}
                <input
                  type="file"
                  accept="image/*" // Restrict file types to images only
                  onChange={handleLogoUpdate}
                  className={`absolute inset-0 opacity-0 cursor-pointer w-[${
                    isMobile ? "35px" : "92px"
                  }] h-[${isMobile ? "45px" : "90px"}]`}
                />
              </button>
            </li>
          </ul>
        </div>
        <div className="flex flex-col justify-center items-center p-4 m-4">
          <div ref={qrCodeRef} style={{ borderRadius: "50%" }}></div>
          <label className="block p-4">
            <select
              onChange={onExtensionChange}
              value={filetype}
              className="w-auto rounded-lg border border-gray-400 border-opacity-50 focus:outline-none text-black"
            >
              <option value="png">PNG</option>
              <option value="jpeg">JPEG</option>
              <option value="webp">WEBP</option>
            </select>
          </label>

          <button
            className="w-full p-2 font-raleway text-white rounded-md text-sm bg-[#1D91AA]"
            onClick={handlePayment}
          >
            {downloadText === "" ? "Download" : downloadText}
          </button>
        </div>
        {userProfile.username === "" && isLogin && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-8 rounded-lg w-96">
              <div>
                {/* Switch buttons */}
                <div className="flex justify-end">
                  <button
                    onClick={() => setIsLogin(false)}
                    className="text-gray-600 hover:text-red-500"
                  >
                    X
                  </button>
                </div>
                <div className={styles.formsignupandlogin}>
                  <div className="flex flex-row">
                    <button
                      className={
                        switchButton ? styles.selected : styles.notselected
                      }
                      style={{
                        fontSize: "14px", // font-size becomes fontSize
                        margin: "15px", // margin
                        padding: "15px", // padding
                      }}
                      onClick={() => setSwitchButton(true)}
                    >
                      Sign Up
                    </button>
                    <button
                      className={
                        !switchButton ? styles.selected : styles.notselected
                      }
                      onClick={() => setSwitchButton(false)}
                    >
                      Login
                    </button>
                  </div>
                </div>

                {/* Conditional Form Rendering */}
                {switchButton ? <SignUp /> : <LoginUp />}
              </div>
            </div>
          </div>
        )}
      </div>
      {showAlert && (
        <CustomAlert
          message={alertMessage}
          onClose={() => setShowAlert(false)}
        />
      )}
      {showAlertForAfterPayment && <AlertForAfterPayment message={showAlertForAfterPaymentMessage} onClose={()=>{setShowAlertForAfterPayment(false)}}/>}
    </div>
  );
};

export default CustomizableQrCodewindowContentforLogos;