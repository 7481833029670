import React, { useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";



const PasswordOfProductForLinkPassword = () => {
  const location = useLocation();
  const [error, setError] = useState(null); // State to manage error messages

  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => setPasswordVisible(!passwordVisible);


  const backendUrl = process.env.REACT_APP_ENVIRONMENT ==='production'? 'https://backend.zmqrcode.com' : 'http://localhost:3000';  

  const handleChange = async (event) => {
    const password = event.target.value;

    try {
      const response = await axios.get(
        `${backendUrl}/purchasedQrForLink/${id}`
      );
      if (response.data && password === response.data.QrCodePassword) {
        window.location.href = response.data.linkText;
      } else {
        setError("Incorrect password. Please try again."); // Set error message if password is incorrect
      }
    } catch (err) {
      console.error("Error fetching password:", err);
      setError("An error occurred while verifying the password."); // Handle error if the request fails
    }
  };

  return (
    <div className="flex justify-center items-center w-full">
       <div className='flex  flex-col justify-center items-center'>
        {/* <div 
            className="w-full h-auto flex flex-col p-4 justify-center items-center rounded-[11px] m-[25px] p-[16px] shadow-[0px_5px_15px_rgba(0,0,0,0.35)]" >
            <input 
                type="password" 
                className="m-4 p-2 border border-solid border-[#EBEBEB] rounded" 
                placeholder="Enter Your Password" 
                required 
                onChange={handleChange}
            />
            
            {error && <div className="text-red-500 text-sm">{error}</div>} {/* Display error message if exists */}
            <div className="flex items-center border rounded-lg w-full m-[5px] p-3">
                        <input
                            className="border-none ml-2 p-3 w-[70%] focus:outline-none"
                            type={passwordVisible ? 'text' : 'password'}
                            name="Enter Your Password"
                            onChange={handleChange}
                            placeholder="Enter The Password"
                        />
                        <FontAwesomeIcon
                            icon={passwordVisible ? faEyeSlash : faEye}
                            size="1x"
                            color="#1D91AA"
                            onClick={togglePasswordVisibility}
                            className="cursor-pointer"
                        />
                    </div>
                    {error && <div className="text-red-500 text-sm">{error}</div>} 

        </div>
    </div>
  );
};

export default PasswordOfProductForLinkPassword;
