/* eslint-disable jsx-a11y/img-redundant-alt */
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import useMediaQuery from "../usematchqueryfortrackscreensize/useMediaQuery";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const OutPutWindowForMenu = () => {
  const location = useLocation();
  const [data, setData] = useState(null); // State to hold the fetched data
  const [loading, setLoading] = useState(true); // State to track loading status
  const [error, setError] = useState(null);
  const [paths, setFilePath] = useState([]); // State for file paths
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id"); // Get the 'id' parameter
  const isMobile = useMediaQuery("(max-width: 960px)");
  const [restaurantName, setRestaurantName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [foodType, setFoodType] = useState("");
  const [foodDetails, setFoodDetails] = useState("");
  const [address, setAddress] = useState("");
  const cardRef = useRef(null); // Reference to the container to capture
  const [serviceName,setServiceName] = useState("");
  const backendUrl =
    process.env.REACT_APP_ENVIRONMENT === "production"
      ? "https://backend.zmqrcode.com"
      : "http://localhost:3000";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${backendUrl}/purchaseQrCodeUserForMenu/${id}` // Replace with your API URL
        );
        console.log(`${backendUrl}/purchaseQrCodeUserForMenu/${id}`);
        setData(response.data); // Set the fetched data to state
        console.log(response.data);

        const filePaths = response.data.menuCard;
        let parsedFilePaths = [];

        if (typeof filePaths === "string") {
          try {
            parsedFilePaths = JSON.parse(filePaths); // Parse string to array
          } catch (err) {
            console.error("Error parsing filePaths:", err);
          }
        } else {
          parsedFilePaths = filePaths; // If it's already an array, use it as is
        }

        setFilePath(parsedFilePaths); // Set the file paths to state
      } catch (err) {
        setError(err); // Set the error if the request fails
      } finally {
        setLoading(false); // Set loading to false after the request is complete
      }
    };

    fetchData(); // Call the fetch function
  }, [id]); // Adding id as a dependency to refetch if it changes

  useEffect(() => {
    if (data) {
      setServiceName(data.serviceName);
      setRestaurantName(data.resturantName || "Not Filled");
      setMobileNumber(data.mobileNumber || "Not Filled");
      setFoodType(data.foodType || "Not Filled");
      setFoodDetails(data.foodDetails || "Not Filled");
      setAddress(data.address || "Not Filled");
    }
  }, [data]); // Dependency array with 'data'

  // Early return for loading and error states




  const renderMenuCard = () => {
    if (paths.length === 0) {
      return <div>No Menu Cards Available</div>;
    }

    const handleDownload = (tag) => {
      const link = document.createElement("a");
      link.href = tag;
      link.download =  "jpg";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
  
    return (
      <div ref={cardRef} className="flex flex-col items-center">
        <div
          className="flex flex-col justify-center items-center p-4 rounded-md"
          // Reference the div to capture it
        >
          <div
            className="flex flex-col rounded-md bg-[#690e0e] text-[#fff]"
            style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
          >
            <div className="flex w-full items-center flex-col inline-block p-4">
              <div className="flex flex-col items-center">
                <div className="flex flex-col">
                  <label className="font-raleway font-semibold text-[31px]">
                    {`Restaurant Name: ${restaurantName}`}
                    {console.log(data)}
                  </label>
                  <label className="font-raleway font-semibold">
                    {`Mobile Number: ${mobileNumber}`}
                  </label>
                  <label className="font-raleway font-semibold">
                    {`Food Details: ${data.foodDetails || "Not Filled"}`}
                  </label>
                </div>
              </div>
              <div className="flex flex-col">
                <div
                  className={`flex flex-col ${
                    isMobile ? " items-center flex-col" : ""
                  }`}
                >
                  {paths.map((link, index) => (
                    <div key={index} className="mb-[15px] flex flex-col p-2">
                      <img
                        src={`${link}`}
                        alt={`Menu Card ${index + 1}`}
                        className="max-w-full h-auto w-[798px] m-2 rounded-lg shadow-lg hover:scale-105 transition-transform duration-300 ease-in-out"
                      />
                      <div className="flex flex-col items-center">
                        <label className="font-raleway font-semibold">{`Menu Card Images ${
                          index + 1
                        }`}</label>
                        <div
                          className="flex items-center cursor-pointer"
                          onClick={() => handleDownload(link)} // Trigger download on click
                        >
                          <FontAwesomeIcon
                            icon={faDownload}
                            className="h-[16px] w-[20px] p-2 bg-[#4bdede] rounded-full text-white border-2 border-white"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="flex flex-col">
                  <label className="font-raleway font-semibold">
                    {`Food Details: ${foodDetails}`}
                  </label>
                  <label className="font-raleway font-semibold">
                    {`Food Type: ${foodType}`}
                  </label>
                  <label className="font-raleway font-semibold">
                    {`Address: ${address}`}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return <div>{renderMenuCard()}</div>;
};

export default OutPutWindowForMenu;
