import { useLocation } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

const KidsOutputWindow = () => {
    const location = useLocation();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [paths, setFilePath] = useState([]);
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get("id");

    const backendUrl =
        process.env.REACT_APP_ENVIRONMENT === "production"
            ? "https://backend.zmqrcode.com"
            : "http://localhost:3000";

    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${backendUrl}/purchaseQrCodeUserForKids/${id}`
                );

                if (isMounted) {
                    setData(response.data);
                    const filePaths = response.data.filePaths || [];
                    let parsedFilePaths = [];

                    if (typeof filePaths === "string") {
                        try {
                            parsedFilePaths = JSON.parse(filePaths);
                        } catch (err) {
                            console.error("Error parsing filePaths:", err);
                        }
                    } else {
                        parsedFilePaths = filePaths;
                    }
                    setFilePath(parsedFilePaths);
                }
            } catch (err) {
                if (isMounted) setError(err);
            } finally {
                if (isMounted) setLoading(false);
            }
        };

        fetchData();

        return () => {
            isMounted = false;
        };
    }, [id, backendUrl]);

    const renderKidDetails = () => {
        if (loading) return <div>Loading...</div>;
        if (error) return <div>Error: {error.message}</div>;
        if (paths.length === 0) return <div>No Kid Images Available</div>;

        return (
            <div className="flex items-center justify-center bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen py-8 px-4">
                <div className="bg-white shadow-xl rounded-lg overflow-hidden max-w-md w-full transform transition-transform duration-500 hover:scale-105 hover:shadow-2xl">
                    {/* Profile Image */}
                    <div className="bg-gradient-to-br from-pink-500 to-red-500 p-6 text-center relative">
                        {/* Subtle Animated Overlay */}
                        <div className="absolute inset-0 bg-gradient-to-r from-transparent to-white opacity-10 rounded-lg pointer-events-none animate-pulse"></div>
                        <img
                            src={paths.length > 0 ? paths[0] : "default-profile.jpg"}
                            alt="Profile"
                            className="w-32 h-32 mx-auto rounded-full border-4 border-white shadow-md transition-transform duration-500 hover:scale-110"
                        />
                        <h2 className="text-white text-2xl font-bold mt-4 transition-transform duration-300 hover:scale-105">
                            {data?.childName || "Child's Name"}
                        </h2>
                        <p className="text-gray-200 mt-2">
                            <span className="font-semibold">DOB: </span>
                            {data?.dob || "Not Available"}
                        </p>
                    </div>
        
                    {/* Details Section */}
                    <div className="p-6">
                        <ul className="space-y-4">
                            <li className="text-gray-700 hover:text-pink-500 transition-all duration-300 transform hover:scale-105">
                                <span className="font-semibold">Grade: </span>
                                {data?.grade || "Not Available"}
                            </li>
                            <li className="text-gray-700 hover:text-pink-500 transition-all duration-300 transform hover:scale-105">
                                <span className="font-semibold">School Name: </span>
                                {data?.schoolName || "Not Available"}
                            </li>
                            <li className="text-gray-700 hover:text-pink-500 transition-all duration-300 transform hover:scale-105">
                                <span className="font-semibold">School Address: </span>
                                {data?.schoolAddress || "Not Available"}
                            </li>
                            <li className="text-gray-700 hover:text-pink-500 transition-all duration-300 transform hover:scale-105">
                                <span className="font-semibold">Parent Name: </span>
                                {data?.parentName || "Not Available"}
                            </li>
                            <li className="text-gray-700 hover:text-pink-500 transition-all duration-300 transform hover:scale-105">
                                <span className="font-semibold">Contact: </span>
                                {data?.contactNumber || "Not Available"}
                            </li>
                            <li className="text-gray-700 hover:text-pink-500 transition-all duration-300 transform hover:scale-105">
                                <span className="font-semibold">Alternate Contact: </span>
                                {data?.alternateContactNumber || "Not Available"}
                            </li>
                            <li className="text-gray-700 hover:text-pink-500 transition-all duration-300 transform hover:scale-105">
                                <span className="font-semibold">Address: </span>
                                {data?.address || "Not Available"}
                            </li>
                        </ul>
        
                        {/* Map Link */}
                        <div className="mt-6 text-center">
                            <FontAwesomeIcon
                                icon={faMapMarkerAlt}
                                className={`mr-2 transition-transform duration-300 ${data?.mapLink
                                        ? "text-red-500 hover:scale-150"
                                        : "text-gray-400"
                                    }`}
                            />
                            {data?.mapLink ? (
                                <a
                                    href={data.mapLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-blue-500 underline transition-all duration-300 hover:scale-105 hover:underline-offset-4"
                                >
                                    View on Map
                                </a>
                            ) : (
                                <span className="text-gray-500">Not Available</span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
        
        

    };

    return <div>{renderKidDetails()}</div>;
};

export default KidsOutputWindow;
