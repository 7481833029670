import CustomAlert from "./CustomAlertForSubmision";
import { useState } from "react";

const ThankyouMessage = () => {
    const [showAlert,setShowAlert] = useState(true);
    const CustomAlertMessage = `Thank you For Choosing us
                    Welcome to ZM QR Code Services! Thank you for choosing us. We promise that your security is our top priority. We hope you have a great experience using our QR code services. 
                    If you have any questions or need assistance, feel free to reach out to our support team:  connect@zmqrcode.com.
                    Also, be sure to check out our website at https://zmqrcode.com.`
    localStorage.removeItem("freeMb");
    return (
        <div className="flex flex-col shadow-[0_5px_15px_rgba(0,0,0,0.35)]  rounded-[11px] bg-customBlue text-white m-4 p-4">
            <div className="flex flex-col items-center  ">
                <h3 className="font-raleway font-bold text-16">Thank You</h3>

            </div>
            <div className="flex flex-wrap gap-4 justify-center m-4 ">
                <p className="font-raleway">
                    Welcome to ZM QR Code Services! Thank you for choosing us. We promise that <strong>your security is our top priority</strong>. We hope you have a great experience using our QR code services.
                    <br />
                    <br />
                    If you have any questions or need assistance, feel free to reach out to our support team: <a href="mailto:connect@zmqrcode.com">connect@zmqrcode.com</a>.
                    Also, be sure to check out our website at <a href="https://zmqrcode.com" target="_blank" rel="noopener noreferrer">https://zmqrcode.com</a>.
                </p>
            </div>
          
            {showAlert 
            &&<CustomAlert message={CustomAlertMessage} onClose={()=>{setShowAlert(false)}}/>}
        </div>
    );
}

export default ThankyouMessage;
