import NavBar from '../components/NavBar';
import JoinUs from '../components/JoinUsModule';
import BestOffers from '../components/BestOffers';
import AboutUsHomePage from '../components/AboutUsHomePage';
import ContactUs from '../components/ContactUs';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setResetPrice } from './store/chooseBestOffers/BestOffersSlicer';
import { jwtDecode } from 'jwt-decode'; // Updated the import
import { setProfile } from './store/userProfile/userProfileActions'; // Adjust the path as needed
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const HomePage = () => {
  const dispatch = useDispatch();
  const location = useLocation(); // Fixed the typo from 'locatipn' to 'location'

  const value = JSON.parse(localStorage.getItem('freeMb'));
  if(value!==0){
  localStorage.setItem("freeMb",JSON.stringify(5));
  }
  useEffect(() => {
    // Dispatch the action when the component mounts
    dispatch(setResetPrice());
  }, [dispatch]); // Dependency array includes dispatch to avoid unnecessary re-renders


  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const tokenParam = query.get('token');

    if (tokenParam) {
        try {
            let decoded = jwtDecode(tokenParam);
            dispatch(setProfile({ userid: decoded.userid, username: decoded.username,email: decoded.email, usagecount: decoded.usagecount, token: tokenParam }));
            // Logging to check dispatched data

            // Fetch the updated profile state

        } catch (error) {
            console.error('Error decoding token:', error);
        }
    }
}, [location, dispatch]);


const profile = useSelector((state) => state.profile);

useEffect(() => {
  // Check if profile exists and each required field is not empty or undefined
  if (
    profile &&
    profile.username?.trim() !== "" &&
    profile.email?.trim() !== "" &&
    profile.mobile?.trim() !== "" &&
    profile.area?.trim() !== ""
  ) {
    // Store the profile object as a string in localStorage
    localStorage.setItem("profile", JSON.stringify(profile));
  }
}, [profile]);

useEffect(() => {
  const script = document.createElement('script');
  script.src = 'https://embed.tawk.to/6750357b4304e3196aec5711/1ie8l1qus';
  script.async = true;
  script.charset = 'UTF-8';
  script.setAttribute('crossorigin', '*');
  document.body.appendChild(script);

  return () => {
    document.body.removeChild(script);
  };
}, []);   
  
  return (
    <>
      <NavBar />
      <JoinUs />
      <BestOffers />
      <AboutUsHomePage />
      <ContactUs/>
    </>
  );
};

export default HomePage;
