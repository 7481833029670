import { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import QRCodeStyling from 'qr-code-styling';
import qrcustomizablebody from '../resources/qrcustomizablebody.json';
import { setDotsType, setData } from '../store/QrCodeCustmizable/QrCodecustmizableSlicer';
import "react-color-palette/css";
import CustomizableColorForBody from './CustomizableColorForBody';
import useMediaQuery from '../usematchqueryfortrackscreensize/useMediaQuery';
import LoginUp from "../UserRegistrayion/LoginUp";
import SignUp from "../UserRegistrayion/SignUP";
import styles from '../module/navbar.module.css'
import useLoadScript from "../reusableHook/useLoadScript";
import axios from 'axios';
import CustomAlert from '../CustomAlertForSubmision';
import AlertForAfterPayment from "../AlertForAfterPayment";

const CustomizableQrCodewindowContent = () => {
    const qrCodeRef = useRef(null);
    const qrCodeInstance = useRef(null);
    const [filetype, setFiletype] = useState("png");
    const qrCodeState = useSelector((state) => state.QrCodeCustmizable);
    const defaultUrl = "https://zmqrcode.in/";
    const dispatch = useDispatch();
    const isMobile = useMediaQuery('(max-width: 960px)');
    const userProfile = useSelector((state) => state.profile);
    const [isLogin, setIsLogin] = useState(false);
    const [switchButton, setSwitchButton] = useState(true);
    const { loaded, error } = useLoadScript("https://checkout.razorpay.com/v1/checkout.js");
    const [showAlert,setShowAlert] = useState();
    const [alertMessage, setAlertMessage] = useState("");
    const [showAlertForAfterPayment, setShowAlertForAfterPayment] = useState(false);
    const [showAlertForAfterPaymentMessage, setShowAlertForAfterPaymentMessage] = useState(false);



    const chooseQrBody = (newType) => () => {
        dispatch(setDotsType(newType));
    };
    const downloadText = useSelector((state)=> state.downloadText.downloadText);

    useEffect(() => {
        qrCodeInstance.current = new QRCodeStyling({
            width: qrCodeState.width,
            height: qrCodeState.height,
            image:qrCodeState.image,
            margin:"15",
            data: qrCodeState.data,
            dotsOptions: {
                color: qrCodeState.dotsOptions.color,
                type: qrCodeState.dotsOptions.type,
            },
            cornersDotOptions: {
                color: qrCodeState.cornersDotOptions.color,
                type: qrCodeState.cornersDotOptions.type,
            },
            cornersSquareOptions: {
                color: qrCodeState.cornersSquareOptions.color,
                type: qrCodeState.cornersSquareOptions.type,
            },
            imageOptions: {
                crossOrigin: "anonymous",
                margin: qrCodeState.imageOptions.margin,
                imageSize: qrCodeState.imageOptions.imageSize,
                hideBackgroundDots: qrCodeState.imageOptions.hideBackgroundDots,
            },
            errorCorrectionLevel: 'H'
        });

        // Append QR code to the referenced div
        if (qrCodeRef.current) {
            qrCodeInstance.current.append(qrCodeRef.current);
        }

        // Update the QR code with the default URL
        qrCodeInstance.current.update({ data: defaultUrl });

        // Cleanup QR code instance on component unmount
        return () => {
            qrCodeInstance.current = null;
        };
    }, [qrCodeState, defaultUrl]);


      // Update QR code instance when state changes
      useEffect(() => {
        if (qrCodeInstance.current) {
            qrCodeInstance.current.update({
                width: qrCodeState.width,
                height: qrCodeState.height,
                image: qrCodeState.image,
                margin: qrCodeState.margin,
                data: qrCodeState.data,
                dotsOptions: {
                    color: qrCodeState.dotsOptions.color,
                    type: qrCodeState.dotsOptions.type,
                },
                cornersDotOptions: {
                    color: qrCodeState.cornersDotOptions.color,
                    type: qrCodeState.cornersDotOptions.type,
                },
                cornersSquareOptions: {
                    color: qrCodeState.cornersSquareOptions.color,
                    type: qrCodeState.cornersSquareOptions.type,
                },
                backgroundOptions: {
                    color: qrCodeState.backgroundOptions.color,
                },
                imageOptions: {
                    crossOrigin: qrCodeState.imageOptions.crossOrigin,
                    margin: qrCodeState.imageOptions.margin,
                    imageSize: qrCodeState.imageOptions.imageSize,
                    hideBackgroundDots: qrCodeState.imageOptions.hideBackgroundDots,
                },
                 errorCorrectionLevel: 'H'
            },
        );
        }
    }, [qrCodeState]);


    const serviceInstanceId = useSelector((state) => state.serviceInstance.serviceId);

    const bestPlans = useSelector((state) => state.Bestplans);

    const profile = useSelector((state)=>state.profile);

    const backendUrl = process.env.REACT_APP_ENVIRONMENT ==='production'? 'https://backend.zmqrcode.com' : 'http://localhost:3000'; 
    
   
 
const handlePayment = async () => {
  // Check if the user is logged in
  if (userProfile.username !== '') {
    // Check if Razorpay script is loaded
    if (!loaded) {
      alert('Razorpay SDK is still loading...');
      return;
    }

    // Check if there was an error loading Razorpay script
    if (error) {
      alert('Failed to load Razorpay SDK. Please try again.');
      return;
    }

    try {
      // Get order ID from the backend
      const { data } = await axios.post(`${backendUrl}/orders`, {
        amount: bestPlans.price===0?1*100 : bestPlans.price * 100,
        currency: "INR",
      });

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID, // Live key
        amount: data.amount,
        currency: data.currency,
        order_id: data.order_id,
        name: `ZM QR Code Service - ${bestPlans.planName}`,
        description: "Test Transaction for QR Code",
        handler: async function (response) {
          alert("Payment Successful! Payment ID: " + response.razorpay_payment_id);
          try {
            const formData = {
              username: profile.username,
              email: profile.email,
              planName: bestPlans.planName,
              price: bestPlans.price,
              transactionId: response.razorpay_payment_id,
            };
          
            // POST request with formData
            const paymentRemainder = await axios.post(`${backendUrl}/paymentDone`, formData);
            
            if(paymentRemainder.status === 201){
              setShowAlertForAfterPayment(true);
            setShowAlertForAfterPaymentMessage(`Thank you choosing Our Plan ${bestPlans.planName} , price: ${bestPlans.price}`);
          }
            localStorage.removeItem('price');
            localStorage.removeItem('planName');
          } catch (error) {
            setShowAlert(true);
            setAlertMessage(error.message || "An unexpected error occurred.");
          }
          if(serviceInstanceId!==""){
 
          dispatch(setData(serviceInstanceId));
        }
        },
        prefill: {
          name: userProfile.username,
          email: userProfile.email,
        },
        theme: {
          color: "#F37254",
        },
      };
      if(serviceInstanceId!==""){
      // Open the Razorpay payment modal
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
      }
      else if(serviceInstanceId===""){
        setShowAlert(true);
        setAlertMessage("Please Submit the form");
      }
    } catch (error) {
      alert("Error while creating order. Please try again.");
    }
  } else {
    // If the user is not logged in, show the login modal
    setIsLogin(true);
  }
};
  

    const onExtensionChange = (event) => {
        setFiletype(event.target.value);
    };

    return (
        <div className="flex">
            <div className={`${isMobile ? "flex flex-col-reverse justify-center w-[80%]" : "flex flex-row justify-between w-full"}`}>
                <div className='flex flex-col'>
                    <div className='flex flex-col'>
                        <div className="flex flex-col p-2 m-2">
                            <h3 className="font-raleway text-sm cursor-pointer text-300 text-black flex justify-start font-bold">
                                Choose QR Code Body
                            </h3>
                            <ul className={`${isMobile ? "flex items-center justify-center flex-nowrap" : "flex flex-wrap p-2"}`}>
                                {qrcustomizablebody.map((item, index) => (
                                    <li key={index} className="p-2 m-2 flex-col font-raleway">
                                        <button
                                            className="bg-transparent border-none"
                                            aria-label={`Choose QR code body ${index}`}
                                            onClick={chooseQrBody(item.name)}
                                        >
                                            <img
                                                src={item.img}
                                                alt={`QR code body ${index}`}
                                                loading="lazy"
                                                className={`${isMobile ? "w-[35px] h-[35px] p-[15px]" : "w-[55px] h-[55px] p-4"} rounded-full border-2 border-solid border-gray-300 shadow-lg bg-white`}
                                            />
                                        </button>
                                        <p className={`font-raleway text-xs cursor-pointer text-300 text-black flex justify-center font-bold ${item.name === 'Choose Own Logo' ? 'text-customBlue p-[45px]' : 'text-customBlue'}`}>
                                            {item.name}
                                        </p>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        {/* <CustomizableColorForBody /> */}
                    </div>
                </div>
                <div className="flex flex-col  justify-center items-center p-4 m-4">
                    <div ref={qrCodeRef} style={{ borderRadius: '50%' }}></div>
                    <label className="block p-4">
                        <select
                            onChange={onExtensionChange}
                            value={filetype}
                            className="w-auto rounded-lg border border-gray-400 border-opacity-50 focus:outline-none text-black"
                        >
                            <option value="png">PNG</option>
                            <option value="jpeg">JPEG</option>
                            <option value="webp">WEBP</option>
                        </select>
                    </label>
                    <button
                        className="w-full p-2 font-raleway text-white rounded-md text-sm bg-[#1D91AA]"
                        onClick={handlePayment}
                    >
                        {downloadText===''?'Download':downloadText}
                    </button>
                </div>
                { ((userProfile.username === '') && isLogin) && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                <div className="bg-white p-8 rounded-lg w-96">
                  <div>
                    {/* Switch buttons */}
                    <div className="flex justify-end">
                    <button
                      onClick={() => setIsLogin(false)}
                      className="text-gray-600 hover:text-red-500"
                    >
                      X
                    </button>
                  </div>
                      <div className={styles.formsignupandlogin}>
                        <div className="flex flex-row">
                          <button
                          className={switchButton ? styles.selected : styles.notselected} style={{
                            fontSize: "14px", // font-size becomes fontSize
                            margin: "15px",   // margin
                            padding: "15px",  // padding
                          }}
                            onClick={() => setSwitchButton(true)}
                          >
                            Sign Up
                          </button>
                          <button
                          className={!switchButton ? styles.selected : styles.notselected} 
                            onClick={() => setSwitchButton(false)}
                            
                          >
                            Login
                          </button>
                        </div>
                      </div>
                 

                    {/* Conditional Form Rendering */}
                    {switchButton ? <SignUp /> : <LoginUp />}
                  </div>
                </div>
              </div>
            )}
            </div>
            {showAlert&&<CustomAlert message={alertMessage} onClose={()=>{setShowAlert(false)}}/>}
            {showAlertForAfterPayment && <AlertForAfterPayment message={showAlertForAfterPaymentMessage} onClose={()=>{setShowAlertForAfterPayment(false)}}/>}
        </div>
    );
};

export default CustomizableQrCodewindowContent;