import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { setInstanceId } from '../store/ServiceSavedInstance/ServiceInstanceReducer';
import LoginUp from "../UserRegistrayion/LoginUp";
import SignUp from "../UserRegistrayion/SignUP";
import { setPlanName, setPrice } from '../store/chooseBestOffers/BestOffersSlicer';
import { setReset } from '../store/downloadButtonRefernecSliceReducer';
import styles from "../module/navbar.module.css";

const MessageForm = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [serviceName, setServiceName] = useState("");
    const location = useLocation();
    const profile = useSelector((state) => state.profile);
    const dispatch = useDispatch();
    const [buttonText, setButtonText] = useState("Submit");
    const [isLogin, setIsLogin] = useState(false);
    const [switchButton, setSwitchButton] = useState(true);
    const userProfile = useSelector((state) => state.profile);

    // Get service name from URL query parameter
    useEffect(() => {
        const queryParam = new URLSearchParams(location.search).get("name");
        if (queryParam) {
            setServiceName(queryParam);
        }
    }, [location]);

    const backendUrl = process.env.REACT_APP_ENVIRONMENT === 'production' ? 'https://backend.zmqrcode.com' : 'http://localhost:3000';
    console.log(backendUrl);

    const handleSubmitForUrl = async (values, { resetForm }) => {
        if (profile.userid !== '') {
            const formData = {
                serviceName: serviceName,
                senderName: values.senderName || '',
                typeofmessage: values.typeofmessage || '',
                textmessage: values.textmessage || '',
                dateTime: values.dateTime || '',
                QrCodePassword: values.password || '',
                userId: profile.userid
            };
            console.log(formData);
            try {
                const response = await axios.post(`${backendUrl}/purchaseQrCodeUserForSms`, formData);
                const purchasedServiceUrl = response.data.link; // Assuming response.data.url contains the URL
                dispatch(setInstanceId(purchasedServiceUrl));
                setButtonText("Submitted");
                dispatch(setPrice(99));
                dispatch(setPlanName("Basic Starter plan"))
            } catch (error) {
                console.error(error);
            }
        } else {
            setIsLogin(true);
        }
    };

    // Toggle password visibility
    const togglePasswordVisibility = () => {
        setShowPassword(prev => !prev);
    };

    const CustomHandleChange = (handleChange) => (event) => {
        handleChange(event);
        if (event.target.value !== '') {
            setButtonText('Submit');
            dispatch(setReset())
        }
    }

    return (
        <div className="max-w-lg mx-auto">
            <h1 className="text-2xl font-bold text-center mb-6">QR Code Generator for SMS</h1>
            <Formik
                initialValues={{
                    senderName: '',
                    typeofmessage: '',
                    textmessage: '',
                    dateTime: '',
                    password: '',
                }}
                onSubmit={handleSubmitForUrl}
            >
                {({ values, handleChange, isSubmitting }) => (
                    <Form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="senderName">
                                Sender Name
                            </label>
                            <Field
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="text"
                                name="senderName"
                                placeholder="Enter the Sender Name"
                                onChange={CustomHandleChange(handleChange)}
                            />
                        </div>
                        {/* Type of Message input */}
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="typeofmessage">
                                Type of Message
                            </label>
                            <Field
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="text"
                                name="typeofmessage"
                                placeholder="Enter the Type of Message"
                                onChange={CustomHandleChange(handleChange)}
                            />
                        </div>

                        {/* Message input */}
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="textmessage">
                                Text Message
                            </label>
                            <Field
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                as="textarea"
                                name="textmessage"
                                placeholder="Enter the Message"
                                onChange={CustomHandleChange(handleChange)}
                            />

                        </div>
                        <div className="mb-4">
                            <label
                                className="block text-gray-700 text-sm font-bold mb-2"
                                htmlFor="dateTime"
                            >
                                Date and Time
                            </label>
                            <Field
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="dateTime-local"
                                name="dateTime"
                                placeholder="Enter the date and time "
                                onChange={CustomHandleChange(handleChange)}
                            />
                        </div>

                        {/* Password input */}
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                                Password
                            </label>
                            <div className="flex items-center relative">
                                <Field
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    type={showPassword ? 'text' : 'password'}
                                    name="password"
                                    placeholder="Enter the Password"
                                    onChange={CustomHandleChange(handleChange)}
                                />
                                {/* Password visibility toggle button outside, positioned next to the input */}
                                <button
                                    type="button"
                                    onClick={togglePasswordVisibility}
                                    className="ml-2 text-gray-600 hover:text-gray-900"
                                    style={{ width: "0px" }}
                                >
                                    <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} size="1x" color="#1D91AA" />
                                </button>
                            </div>
                        </div>


                        {/* Submit button */}
                        <div className="flex items-center justify-between">
                            <button
                                className="text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                type="submit"
                                style={{ backgroundColor: '#1D91AA' }}
                                disabled={isSubmitting}
                            >
                                {buttonText}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
            {isLogin && (
                <div className="flex justify-center items-center h-screen">
                    {/* Trigger button */}
                    <button
                        onClick={() => setIsLogin(true)}
                        className="bg-blue-500 text-white p-3 rounded-lg"
                    >
                        Open Sign-Up/Login
                    </button>


                    {/* Modal */}
                    {((userProfile.username === '') && isLogin) && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                            <div className="bg-white p-8 rounded-lg w-96">
                                <div>
                                    {/* Switch buttons */}
                                    <div className="flex justify-end">
                                        <button
                                            onClick={() => setIsLogin(false)}
                                            className="text-gray-600 hover:text-red-500"
                                        >
                                            X
                                        </button>
                                    </div>
                                    <div className={styles.formsignupandlogin}>
                                        <div className="flex flex-row">
                                            <button
                                                className={switchButton ? styles.selected : styles.notselected} style={{
                                                    fontSize: "14px", // font-size becomes fontSize
                                                    margin: "15px",   // margin
                                                    padding: "15px",  // padding
                                                }}
                                                onClick={() => setSwitchButton(true)}
                                            >
                                                Sign Up
                                            </button>
                                            <button
                                                className={!switchButton ? styles.selected : styles.notselected}
                                                onClick={() => setSwitchButton(false)}

                                            >
                                                Login
                                            </button>
                                        </div>
                                    </div>


                                    {/* Conditional Form Rendering */}
                                    {switchButton ? <SignUp /> : <LoginUp />}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default MessageForm;
