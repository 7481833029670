import React from "react";
import styles from "./module/navbar.module.css";
import NavBar from "./NavBar";

const AboutUsHomePage = () => {
  return (
    <>
      <NavBar />
      <div id="about-section" className={styles.AboutUsHomePage}>
        <label className="text-2xl font-bold font-raleway">About Us</label>
        <div className={styles.AboutUsHomePageContainer}>
          <img
            src="/close-up-hands-scanning-qr-code.jpg"
            className="rounded-full w-[150px] h-[150px]"
            alt="About Us"
          />

          <p className="text-[18px]  font-bold font-raleway ml-4 justify-center items-center">
            Zm Qr Codes Services does not provide QR codes. We provide secure and
            easy-to-access storage solutions with QR code support." is designed
            to highlight several key benefits of our service from a user's
            perspective: The assures potential customers that they will benefit
            from a reliable, secure, and user-friendly storage service, enhanced
            by the convenience and innovation of QR code technology. It speaks
            directly to the desires of customers looking for efficiency and
            modern solutions. "We provide": This shows that our company is
            offering a service, implying reliability and commitment to servicing
            the customer. "Secure": This reassures customers that their digital
            data will be kept safe, which is often one of the main concerns when
            it comes to storage services. It suggests our service is trustworthy
            and dependable. "easy-to-access":. Users can their storage solutions
            to be simple to use and easily accessible when they need them. It
            communicates that our service is user-friendly. "Storage solutions":
            This positions our offering as more than just a place to store
            things. The term "solutions" suggests that our service is tailored
            to meet the needs of the user, implying flexibility and
            problem-solving. "With QR code support": This is the unique selling
            point. It indicates that your storage service uses QR codes, likely
            to streamline access and tracking. QR codes are associated with
            technology and ease of use, which adds a modern, innovative touch to
            the users.
          </p>
        </div>
        <div className={styles.AboutUsHomePageContainer}>
          <img
            src="/Vission.jpg"
            className="rounded-full w-[150px] h-[150px]"
            alt="About Us"
          />

          <div className="flex flex-col items-center mt-2">
            <label className="text-2xl font-bold font-raleway">Vision</label>
            <p className="text-[18px] font-bold font-raleway justify-center items-center">
              We offer Advanced QR code Solutions for your businesses, including
              creating unique QR codes, providing analytics, and integrating
              videos and images. Our services also cover digital business cards,
              event ticketing, and social media integration for better
              connections with audiences, aiming to streamline operations and
              marketing strategies effectively.
            </p>
          </div>
        </div>
        <div className={styles.AboutUsHomePageContainer}>
          <img
            src="/mission.png"
            className="rounded-full w-[150px] h-[150px]"
            alt="mission"
          />
          <div className="flex flex-col items-center mt-2">
            <label className="text-2xl font-bold font-raleway">Mission</label>
            <div className="flex items-center mt-2">
              <p className="text-[18px] font-bold font-raleway justify-center items-center">
                To offer a comprehensive suite of QR code services that enhance
                user engagement, streamline operations, and provide actionable
                insights through advanced analytics.
              </p>
            </div>
          </div>
        </div>
        <div className={styles.AboutUsHomePageContainer}>
          <img
            src="/Goals.jpg"
            className="rounded-full w-[150px] h-[150px]"
            alt="Goals"
          />
          <div className="flex flex-col items-center mt-2">
            <label className="text-2xl font-bold font-raleway">Goals</label>
            <div className="flex items-center mt-2">
              <p className="text-[18px] font-bold font-raleway justify-center items-center">
                To continuously innovate and provide customized QR code designs
                that cater to the unique needs of each business. To maintain a
                user-friendly platform that simplifies the creation and
                management of QR codes for businesses of all sizes. To ensure
                customer satisfaction through reliable support and versatile
                services that cover various aspects of digital marketing and
                operations.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsHomePage;
