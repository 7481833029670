import { SET_LOGIN, SET_LOGOUT } from './userProfileactionTypes';

const initialState = {
    isAuthenticated: false,
};

const authLoginAndLogoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOGIN:
            return { ...state, isAuthenticated: true };
        case SET_LOGOUT:
            return { ...state, isAuthenticated: false };
        default:
            return state;
    }
};

export default authLoginAndLogoutReducer;
