import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import customizableqrcodedialoguenavbar from '../resources/qrcodebody.json'; // Ensure this path is correct
import { useState, useEffect } from 'react';
import CustomizableQrCodewindowContent from './CustomizableQrCodewindowContent';
import useMediaQuery from '../usematchqueryfortrackscreensize/useMediaQuery';
import CustomizableQrCodewindowContentforLogos from '../QRCODESERVICES/CustomizableQrCodewindowContentforLogos'
import OnlyForColor from './OnlyForColor';
import OnlyForOwnLogo from './OnlyForOwnLogo';

const CustomizableQrCodewindow = ({ onClose, CustomizableQrCodewindowChoice }) => {
    const [currentActive, setCurrentActive] = useState(CustomizableQrCodewindowChoice);




    const isMobile = useMediaQuery('(max-width: 960px)');

    // Function to update the currently active item
    const chooseCurrentSideBar = (name) => {
        setCurrentActive(name);
    };

    useEffect(() => {
        // Disable scrolling on the body when modal is open
        document.body.style.overflow = 'hidden';
        return () => {
            // Re-enable scrolling when modal is closed
            document.body.style.overflow = 'auto';
        };
    }, []);

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white text-black p-6 rounded-lg shadow-lg w-full m-4 p-4 h-[90vh] overflow-auto flex flex-col">
                <div className='flex justify-between items-center border-b-2 border-[#f0ebeb] mb-4 pb-2'>
                    <h3 className='text-black font-raleway text-xl'>Customize Your QR Code</h3>

                    {/* Close Icon */}
                    <FontAwesomeIcon
                        icon={faXmark}
                        size="2x"
                        onClick={onClose}
                        className="cursor-pointer text-[#828c8c]"
                    />
                </div>

                {/* Navigation Bar */}
                <nav className='flex w-full mb-4'>
                    <ul className='flex flex-row p-2 border-2 border-[#f0ebeb] rounded-[11px] w-full font-bold'>
                        {customizableqrcodedialoguenavbar.map((item, index) => {
                            const isActive = currentActive === item.name;

                            return (
                                <li
                                    key={index}
                                    className={`flex  ${isActive ? 'text-customBlue' : 'text-black'} ${isMobile ? 'text-md p-[5px]' : 'text-xl p-2 m-2'} font-raleway cursor-pointer`}
                                    onClick={() => chooseCurrentSideBar(item.name)}
                                >
                                    {item.name}
                                </li>
                            );
                        })}
                    </ul>
                </nav>
                 { currentActive === "Shapes" && <CustomizableQrCodewindowContent  />}
                 { currentActive === "Logo" && <CustomizableQrCodewindowContentforLogos/>}
                 { currentActive === "Color" && <OnlyForColor/>}
                 { currentActive === "Choose Own Logo" && <OnlyForOwnLogo/>}

            </div>
        </div>
    );
};

export default CustomizableQrCodewindow;
