const AlertMessageForTermsAndCondition = ({ onClose }) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="w-[363px] max-w-2xl mx-auto my-8 p-6 bg-[#1D91AA] text-white rounded-lg shadow-lg overflow-y-auto" style={{ maxHeight: '60vh' }}>
                <div className="flex justify-between items-center">
                    <h1 className="text-[1rem] font-bold mb-4">Privacy Policy of zmqrcode Services</h1>
                    <button className="border-none text-red cursor-pointer text-[25px] mb-[12px]" onClick={onClose}>x</button>
                </div>
                <h2 className="text-[1rem] font-semibold mb-2 text-left">1. Introduction</h2>
                <p className="text-5 mb-4 text-left">This privacy policy explains how we collect, use, and share your personal information when you visit our website.</p>

                <h2 className="text-[1rem] font-semibold mb-2 text-left">2. What information do we collect?</h2>
                <p className="text-5 mb-4 text-left">
                    We collect the following types of personal information from you when you visit our website: your contact information, which includes your name, email address, and phone number; usage information, which includes details about the pages you visit, the links you click, and the search terms you use; and payment information, such as your credit card number and billing address, if you make a purchase on our website.
                </p>

                <h2 className="text-[1rem] font-semibold mb-2 text-left">3. How do we use your information?</h2>
                <p className="text-5 mb-4 text-left">
                    Your personal information is used to provide you with the services you request, such as responding to inquiries or processing orders. We also use it to analyze website usage, send marketing communications about our products and services, and comply with legal obligations.
                </p>

                <h2 className="text-[1rem] font-semibold mb-2 text-left">4. Who has access to your information?</h2>
                <p className="text-5 mb-4 text-left">
                    We may share your personal information with third parties, including our marketing partners who assist with delivering communications, payment processors handling transactions, and government agencies when required by law.
                </p>

                <h2 className="text-[1rem] font-semibold mb-2 text-left">5. How do we protect your information?</h2>
                <p className="text-5 mb-4 text-left">
                    We use secure servers to store your information and encrypt it when transmitted over the internet. Access is limited to authorized employees, and we have security policies and procedures in place to safeguard your data.
                </p>

                <h2 className="text-[1rem] font-semibold mb-2 text-left">6. How long do we keep your information?</h2>
                <p className="text-5 mb-4 text-left">
                    Your information is retained for as long as necessary to provide the requested services or to meet legal requirements.
                </p>

                <h2 className="text-[1rem] font-semibold mb-2 text-left">7. Your rights</h2>
                <p className="text-5 mb-4 text-left">
                    You have the right to access, correct, or delete your personal data. You may also opt out of receiving promotional communications. To exercise these rights, contact us at <a href="mailto:connect@zmqrcode.com" className="text-[#FFD700]">connect@zmqrcode.com</a>.
                </p>

                <h2 className="text-[1rem] font-semibold mb-2 text-left">8. Changes to this privacy policy</h2>
                <p className="text-5 mb-4 text-left">
                    We may update this privacy policy periodically. The most recent version will always be available on our website.
                </p>

                <h2 className="text-[1rem] font-semibold mb-2 text-left">9. Contact us</h2>
                <p className="text-5 mb-4 text-left">
                    For any questions regarding this privacy policy, please reach out to us at <a href="mailto:connect@zmqrcode.com" className="text-[#FFD700]">connect@zmqrcode.com</a>.
                </p>

                <h2 className="text-[1rem] font-semibold mb-2 text-left">10. Governing law</h2>
                <p className="text-5 mb-4 text-left">
                    This privacy policy is governed by the laws of the Republic of India.
                </p>
            </div>
        </div>
    );
};

export default AlertMessageForTermsAndCondition;
