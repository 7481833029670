import { createSlice } from '@reduxjs/toolkit';

const initialStateOfQrCode = {
    width: 300, // Width of the QR code
    height: 300, // Height of the QR code
    image: "", // Default image path
    margin: 15, // Margin around the QR code
    data: 'https://zmqrcode.com/', // Data to encode in the QR code
    dotsOptions: {
        color: '#000000', // Dot color
        type: 'square', // Dot type
    },
    cornersDotOptions: {
        color: '#000000', // Corner dot color
        type: 'square', // Corner dot type
    },
    cornersSquareOptions: {
        color: '#000000', // Corner square color
        type: 'square', // Corner square type
    },
    backgroundOptions: {
        color: '#ffffff', // Background color
    },
    imageOptions: {
        crossOrigin: 'anonymous', // Allow cross-origin images
        margin: 10, // Padding around the logo
        imageSize: 0.8, // Set logo size to 20% of the QR code size
        hideBackgroundDots: false, // Show background dots
    },
};


const QrCodeCustmizablestoreReducer = createSlice({
    name: 'qrCode',
    initialState: initialStateOfQrCode,
    reducers: {
        // General QR code settings
        setWidth(state, action) {
            state.width = action.payload;
        },
        setHeight(state, action) {
            state.height = action.payload;
        },
        setData(state, action) {
            state.data = action.payload;
        },
        setImage(state, action) {
            state.image = action.payload;
        },
        setMargin(state, action) {
            state.margin = action.payload;
        },

        // Dots options
        setDotsColor(state, action) {
            state.dotsOptions.color = action.payload;
        },
        setDotsType(state, action) {
            state.dotsOptions.type = action.payload;
        },
        setDotsGradient(state, action) {
            state.dotsOptions.gradient = { ...state.dotsOptions.gradient, ...action.payload };
        },

        // Corners dot options
        setCornersDotColor(state, action) {
            state.cornersDotOptions.color = action.payload;
        },
        setCornersDotType(state, action) {
            state.cornersDotOptions.type = action.payload;
        },
        setCornersDotGradient(state, action) {
            state.cornersDotOptions.gradient = { ...state.cornersDotOptions.gradient, ...action.payload };
        },

        // Corners square options
        setCornersSquareColor(state, action) {
            state.cornersSquareOptions.color = action.payload;
        },
        setCornersSquareType(state, action) {
            state.cornersSquareOptions.type = action.payload;
        },
        setCornersSquareGradient(state, action) {
            state.cornersSquareOptions.gradient = { ...state.cornersSquareOptions.gradient, ...action.payload };
        },

        // Background options
        setBackgroundColor(state, action) {
            state.backgroundOptions.color = action.payload;
        },
        setBackgroundGradient(state, action) {
            state.backgroundOptions.gradient = { ...state.backgroundOptions.gradient, ...action.payload };
        },

        // Image options
        setImageOptions(state, action) {
            state.imageOptions = { ...state.imageOptions, ...action.payload };
        },
        setImageMargin(state, action) {
            state.imageOptions.margin = action.payload;
        },
        setImageSize(state, action) {
            state.imageOptions.imageSize = action.payload;
        },
        setImageCrossOrigin(state, action) {
            state.imageOptions.crossOrigin = action.payload;
        },
        setImageHideBackgroundDots(state, action) {
            state.imageOptions.hideBackgroundDots = action.payload;
        },

        // Reset action to reset the state back to the initial state
        setReset(state) {
            return initialStateOfQrCode;
        },
    },
});

export const {
    setWidth,
    setHeight,
    setData,
    setImage,
    setMargin,
    setDotsColor,
    setDotsType,
    setDotsGradient,
    setCornersDotColor,
    setCornersDotType,
    setCornersDotGradient,
    setCornersSquareColor,
    setCornersSquareType,
    setCornersSquareGradient,
    setBackgroundColor,
    setBackgroundGradient,
    setImageOptions,
    setImageMargin,
    setImageSize,
    setImageCrossOrigin,
    setImageHideBackgroundDots,
    setReset, // Exporting the reset action
} = QrCodeCustmizablestoreReducer.actions;

export default QrCodeCustmizablestoreReducer.reducer;
