import { useState, useEffect } from 'react';

// Reusable hook for loading external scripts
const useLoadScript = (src) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    // Check if script already exists to avoid re-loading
    const existingScript = document.querySelector(`script[src="${src}"]`);
    
    if (existingScript) {
      setLoaded(true);
      return;
    }

    // Create a new script element and load it
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => setLoaded(true);
    script.onerror = () => setError(true);

    document.body.appendChild(script);

    // Cleanup function in case the component unmounts before the script loads
    return () => {
      document.body.removeChild(script);
    };
  }, [src]);

  return { loaded, error };
};

export default useLoadScript;