/* eslint-disable jsx-a11y/img-redundant-alt */
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import useMediaQuery from "../usematchqueryfortrackscreensize/useMediaQuery";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faHome, faEnvelope, faMapMarkedAlt } from "@fortawesome/free-solid-svg-icons";

const OutputwindowforBussinessCard = () => {
  const location = useLocation();
  const [data, setData] = useState(null); // State to hold the fetched data
  const [loading, setLoading] = useState(true); // State to track loading status
  const [error, setError] = useState(null);
  const [paths, setFilePath] = useState([]); // State for file paths
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id"); // Get the 'id' parameter
  const isMobile = useMediaQuery("(max-width: 960px)");
  const cardRef = useRef(null);
  const [serviceName, setServiceName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [personName, setPersonName] = useState("");
  const [mail, setMail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [designation, setDesignation] = useState("");
  const [address, setAddress] = useState("");
  const [mapLink, setMapLink] = useState("");

  const backendUrl =
    process.env.REACT_APP_ENVIRONMENT === "production"
      ? "https://backend.zmqrcode.com"
      : "http://localhost:3000";


      const handleOpenmap = (url) => {
        const uniqueTarget = `_blank_${Date.now()}`; // Ensure a unique target for each call
        window.open(url, uniqueTarget);
      };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${backendUrl}/purchaseQrCodeUserForBussinessCard/${id}` // Replace with your API URL
        );
        setData(response.data); // Set the fetched data to state
        const filePaths = response.data.businessLogo;
        let parsedFilePaths = [];

        if (typeof filePaths === "string") {
          try {
            parsedFilePaths = JSON.parse(filePaths); // Parse string to array
          } catch (err) {
            console.error("Error parsing filePaths:", err);
          }
        } else {
          parsedFilePaths = filePaths; // If it's already an array, use it as is
        }

        setFilePath(parsedFilePaths); // Set the file paths to state
      } catch (err) {
        setError(err); // Set the error if the request fails
      } finally {
        setLoading(false); // Set loading to false after the request is complete
      }
    };

    fetchData(); // Call the fetch function
  }, [id]); // Adding id as a dependency to refetch if it changes

  useEffect(() => {
    if (data) {
      setServiceName(data.serviceName);
      setBusinessName(data.businessName || "Not Filled");
      setPersonName(data.personName);
      setMobileNumber(data.mobileNumber || "Not Filled");
      setDesignation(data.designation || "Not Filled");
      setMail(data.mail || "Not Filled");
      setAddress(data.address || "Not Filled");
      setMapLink(data.mapLink || "Not Filled");
    }
  }, [data]); // Dependency array with 'data'

  // Early return for loading and error states

  const renderBusinessCard = () => {
    if (paths.length === 0) {
      return <div>No Business Cards Available</div>;
    }



    return (
      <div
        ref={cardRef}
        className={`flex flex-col items-center  ${
          isMobile ? "m-[0]" : "m-[15px]"
        }`}
      >
        <div
          className={`flex inline bg-[#1b466b] h-auto rounded-[11px] m-[16px] justify-center p-[15px]  `}
        >
          <div className="flex flex-col justify-center items-center">
            <img
              src={paths[0]}
              alt=""
              className={`${
                isMobile ? "h-[82px] w-[100px]" : "h-[158px] w-[158px]"
              } border-[9px] border-[#FFA924] mb-[26px] ml-[26px] mt-[36px] rounded-full relative top-[18px] right-[15px]`}
            />
            <p
              className={`${
                isMobile ? "text-[21px]" : "text-[32px]"
              } text-[#FFA924] font-bold autowrap-text`}
            >
              {businessName}
            </p>
          </div>
          <div className="block rounded-[11px] m-[16px] w-[2px] bg-[#fff]"></div>
          <div className="flex flex-col ">
            <div className="flex flex-col items-start ">
              <p className={`${isMobile ? "text-[26px]":  "text-[32px]"}
              text-[#FFA924] font-[48px]`}>
                {personName}
              </p>
              <p className={`text-[#FFA924] ${isMobile ? "text-[20px]":  "text-[26px]"}`}>{designation}</p>
            </div>
            <div className="flex flex-col items-start mt-[15px]">
              <div className="flex items-center justify-start mb-2">
                <FontAwesomeIcon
                  icon={faPhone}
                  className="h-[16px] p-2  mr-[5px]  relative top-[2px] bg-[#FFA924] text-white rounded-full border-2 border-[#FFA924] text-[18px]"
                />
                <p className="text-[16px]  text-[#FFA924] break-words block w-[165px] flex-start flex">
                  {`${mobileNumber}`}
                </p>
              </div>

              <div className="flex items-center justify-start mb-2 ">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="text-[18px] p-2  mr-[5px]  relative top-[2px] bg-[#FFA924] text-white rounded-full border-2 border-[#FFA924]"
                />
                <p className="text-[16px] text-[#FFA924] break-words block w-[165px] ">
                  {`${mail}`}
                </p>
              </div>
              <div className="flex items-center justify-start mb-2 ">
                <FontAwesomeIcon
                  icon={faHome}
                  className="text-[18px] p-2 mr-[5px] relative top-[2px] bg-[#FFA924] text-white rounded-full border-2 border-[#FFA924]"
                />
                <p className="text-[16px] text-[#FFA924] break-words block">
                  <div>
                <span className="text-[#FFA924] break-words block ">{`${address}`}</span>
              
                  {mapLink && (
                      <button onClick={()=>handleOpenmap(mapLink)}>
                        <FontAwesomeIcon
                          icon={faMapMarkedAlt}
                          className="h-[16px] w-[20px] p-2 bg-[#4bdede] rounded-full text-white border-2 border-white"
                        />
                      </button>
                    )
                  }
                  </div>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return <div>{renderBusinessCard()}</div>;
};

export default OutputwindowforBussinessCard;
