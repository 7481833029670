/* eslint-disable jsx-a11y/img-redundant-alt */
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import useMediaQuery from "../usematchqueryfortrackscreensize/useMediaQuery";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkedAlt } from "@fortawesome/free-solid-svg-icons";

const OutPutWindowforStudentForm = () => {
  const location = useLocation();
  const [data, setData] = useState(null); // State to hold the fetched data
  const [loading, setLoading] = useState(true); // State to track loading status
  const [error, setError] = useState(null); // State for file paths
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id"); // Get the 'id' parameter
  const isMobile = useMediaQuery("(max-width: 960px)");
  // Reference to the container to capture
  const [studentInfo, setStudentInfo] = useState({
    studentName: "",
    studentDOB: "",
    parentName: "",
    address: "",
    mapLink: "",
    gradeInfo: "",
    schoolName: "",
    schoolAddress: "",
    schoolAddressMap: "",
    contactNumber: "",
    alternateContactNumber: "",
    filePathForLogo: "",
    filePaths: "",
  });

  const backendUrl =
    process.env.REACT_APP_ENVIRONMENT === "production"
      ? "https://backend.zmqrcode.com"
      : "http://localhost:3000";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${backendUrl}/purchaseQrCodeUserForStudentForm/${id}` // Replace with your API URL
        );
        setData(response.data);
        console.log(response.data)
        console.log(`${backendUrl}/purchaseQrCodeUserForStudentForm/${id}`);
      } catch (err) {
        setError(err); // Set the error if the request fails
      } finally {
        setLoading(false); // Set loading to false after the request is complete
      }
    };

    fetchData(); // Call the fetch function
  }, [id]); // Adding id as a dependency to refetch if it changes

  useEffect(() => {
    if (data && typeof data === "object") {
      // Ensure `data` is an object or array
      setStudentInfo((prevState) => ({
        ...prevState,
        ...data, // Merge fetched data with initial state
      }));
    }
  }, [data]);

  let widthOfMainCard;

  if (studentInfo.schoolName.length <= 30) {
    widthOfMainCard = "22rem";
  } else if (
    (studentInfo.schoolName.length > 30) &
    (studentInfo.schoolName.length <= 50)
  ) {
    widthOfMainCard = "25rem";
  } else if (
    (studentInfo.schoolName.length > 50) &
    (studentInfo.schoolName.length <= 70)
) {
    widthOfMainCard = "35rem";
  }

  const [widthOfCard, setwidthOfCard] = useState("");

  const handleOpenmap = (url) => {
    const uniqueTarget = `_blank_${Date.now()}`; // Ensure a unique target for each call
    window.open(url, uniqueTarget);
  };

  useEffect(() => {
    if (isMobile) {
      setwidthOfCard("22rem");
    } else {
      setwidthOfCard(widthOfMainCard);
    }
  });



  return (
    <div className="flex flex-col items-center">
      <div
        className="flex flex-col justify-center items-center m-[1rem] rounded-md"
        // Reference the div to capture it
        style={{
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          width: widthOfCard,
        }}
      >
        <div
          className={`flex flex-col rounded-md bg-[#3B3BCD] text-[#fff]`}
          style={{
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            width: widthOfCard,
          }}
        >
          <div className="bg-[#fff] flex">
            <div className="flex flex-col ">
              <div className="flex flex-row bg-blue   rounded-md justify-start items-center bg-[#1818e9] gap-[0.5rem] p-[0.9rem]" 
              style={{
                width: widthOfCard,
              }}>
                <img
                  src={studentInfo.filePathForLogo}
                  alt="filePathForLogo"
                  className="rounded-full w-[3.5rem] h-[3.5rem]"
                />
                <div className="flex flex-col">
                  <h3
                    className={`font-[1rem] ${
                      isMobile ? "text-[15px]" : "text-[16px]"
                    } font-[600]`}
                  >
                    {studentInfo.schoolName}
                  </h3>
                  <p className={`font-[1rem] ${
                      isMobile ? "text-[15px]" : "text-[16px]"
                    } font-[600]`}>{studentInfo.schoolAddress}</p>
                </div>
              </div>

              <div className="flex flex-row justify-space w-full p-[15px]">
                <div
                  className={`flex flex-col justify-center bg-[#fff] text-[11px] items-start relative top-[11px] 
                    left-[13px]   ${isMobile ? "gap-[0.5rem]" : "gap-[1rem]"}`}
                >
                  
                  <span className="text-[#000] font-semibold">{`Student's Name: ${studentInfo.studentName}`}</span>
                  <span className="text-[#000] font-semibold">{`DOB: ${studentInfo.studentDOB}`}</span>
                  <span className="text-[#000] font-semibold">{`Class: ${studentInfo.gradeInfo}`}</span>
                  {/* <span className="text-[#000] font-semibold">{`School Name: ${studentInfo.schoolName}`}</span> */}
                  <span className="text-[#000] font-semibold">{`Father's Name: ${studentInfo.parentName}`}</span>
                  <span className="text-[#000] font-semibold">{`Contact Number: ${studentInfo.contactNumber}`}</span>
                  <span className="text-[#000] font-semibold">{`Alternate : ${studentInfo.alternateNumber}`}</span>
                  <div className="flex justify-center items-center gap-[0.5rem] ">
                    <span className="text-[#000] font-semibold">{`Address: ${studentInfo.address}`}</span>
                    {studentInfo.mapLink && (
                      <button onClick={()=>handleOpenmap(studentInfo.mapLink)}>
                        <FontAwesomeIcon
                          icon={faMapMarkedAlt}
                          className="h-[16px] w-[20px] p-2 bg-[#4bdede] rounded-full text-white border-2 border-white"
                        />
                      </button>
                    )}
                  </div>
                  <div className="flex justify-center items-center gap-[0.5rem]">
                    <span className="text-[#000] font-semibold">{`College/School Address: ${studentInfo.schoolAddress}`}</span>

                    {studentInfo.schoolAddressMap && (
                      <button
                        onClick={()=>handleOpenmap(studentInfo.schoolAddressMap)}
                      >
                      
                        <FontAwesomeIcon
                          icon={faMapMarkedAlt}
                          className="h-[16px] w-[20px] p-2 bg-[#4bdede] rounded-full text-white border-2 border-white"
                        />
                      </button>
                    )}
                  </div>
                </div>
                <img
                  src={studentInfo.filePaths}
                  alt="student Image missing"
                  className="h-32 w-32 rounded-full p-[15px]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OutPutWindowforStudentForm;