import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './components/store/stickToggle'; // Import your Redux store
import './App.css';
import HomePage from './components/HomePage';
import QrCode from './components/QRCODESERVICES/QrCode';
import FAQ from './components/FAQ';
import TermsAndService from './components/TermsAndService';
import NavigationTracker from './components/NavigationTrackerForFindChangesinNavigation';
import ScrollEventTracker from './components/ScrollEventTracker';
import GenerateQrCodeServices from './components/QRCODESERVICES/GenerateQrCodeServices';
import UserProfile from './components/userProfile/UserProfile';
import QrcodePurchased from './components/userProfile/QrcodePurchased';
import BringYourFriend from './components/userProfile/BringYourFriend';
import Pricing from './components/Plans/Pricing';
import Industry  from './components/Plans/IndustryPlansForAdmins';
import PricingPage from './components/Plans/PricingPageForIndividuals';
import RefundPolicy from './components/RefundPolicy';
import ContactUs from './components/ContactUs';
import PrivacyPolicy from './components/PrivacyPolicy';
import OutPutWindow from './components/QrCodePurchsedOutput/Outputwindow';
import PasswordOfProduct from './components/QrCodePurchsedOutput/PasswordOfProduct';
import PasswordOfProductForUrlPassword from './components/QrCodePurchsedOutput/PasswordOfProductForUrlPassword';
import ClientRegistraionForm from './components/clientRegistrationForm/ClientRegistration';
import QrCodeGenerationForm from './components/clientRegistrationForm/QrCodeGenerationForm';
import ThankYouMessage from './components/ThankyouMessage';
import AboutUsHomePage from './components/AboutUsHomePage';
import ComingSoon from './components/ComingSoon';
import CustomizationOptions from './components/clientRegistrationForm/CustomizationOptions';
import ErrorBoundry from './components/ErrorBoundry'
import Footer from './components/Footer';
import PasswordOfProductForLinkPassword from './components/QrCodePurchsedOutput/PasswordOfProductForLinkPassword';
import PasswordOfProductForMeetingPassword from './components/QrCodePurchsedOutput/PasswordOfProductForMeetingPassword';
import OutPutWindowForMenu from './components/QrCodePurchsedOutput/OutPutWindowForMenu';
import PasswordOfProductForMenu from './components/QrCodePurchsedOutput/PasswordOfProductForMenu';
import OutputwindowforBussinessCard from './components/QrCodePurchsedOutput/OutputwindowforBussinessCard';
import PasswordOfProductForBussinessCard from './components/QrCodePurchsedOutput/PasswordOfProductForBussinessCard'
import EventOutputWindow from './components/QrCodePurchsedOutput/EventOutputWindow';
import PasswordOfProductForEvent from './components/QrCodePurchsedOutput/PasswordOfProductForEvent';
import TextOutputWindow from './components/QrCodePurchsedOutput/TextOutputWindow';
import PasswordOfProductForText from './components/QrCodePurchsedOutput/PasswordOfProductForText';
import OutPutWindowForDiscount from './components/QrCodePurchsedOutput/OutPutWindowForDiscount'
import PasswordOfProductForDiscountCard from './components/QrCodePurchsedOutput/PasswordOfProductForDiscountCard'
import SmsOutputWindow from './components/QrCodePurchsedOutput/SmsOutputWindow';
import PasswordOfProductForSms from './components/QrCodePurchsedOutput/PasswordOfProductForSms';
import PetOutputWindow from './components/QrCodePurchsedOutput/PetOutputWindow';
import PasswordOfProductForPet from './components/QrCodePurchsedOutput/PassWordOfProductForPet';
import KidsOutputWindow from './components/QrCodePurchsedOutput/KidsOutputWindow';
import PasswordOfProductForKids from './components/QrCodePurchsedOutput/PasswordOfProductForkids';
import VehicleOutputWindow from './components/QrCodePurchsedOutput/VehicleOutputWindow';
import PasswordOfProductForVehicle from './components/QrCodePurchsedOutput/PasswordOfProductForVehicle'
import PropertyOutputWindow from './components/QrCodePurchsedOutput/PropertyOutPutWindow';
import PasswordOfProductForProperty from './components/QrCodePurchsedOutput/PasswordOfProductForProperty';
import OutPutWindowforStudent from './components/QrCodePurchsedOutput/OutputwindowforStudentForm';
import PasswordOfProductForStudentFormPassword from './components/QrCodePurchsedOutput/PasswordOfProductForStudentFormPassword';
import OutputwindowforBusinessInfo from './components/QrCodePurchsedOutput/OutputwindowforBusinessInfo';
import PasswordOfProductForBusinessInfoPassword from './components/QrCodePurchsedOutput/PasswordOfProductForBusinessInfoPassword';

function App() {
  
  return (
    <ErrorBoundry>
    <Provider store={store}>
      <Router>
        <NavigationTracker /> {/* Track navigation changes */}
        <ScrollEventTracker /> {/* Track scroll events */}
        <div className="App">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/services" element={<QrCode />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/terms&co" element={<TermsAndService />} /> 
            <Route path="/privacypolicy" element={<PrivacyPolicy />} /> 
            <Route path="/generateQrCodeServices" element={<GenerateQrCodeServices />} />
            <Route path="/userprofile" element={<UserProfile />} />
            <Route path="/userprofile/qrcode" element={<QrcodePurchased />} />
            <Route path="/bringyourfriend" element={<BringYourFriend />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/industry" element={<Industry/>} />
            <Route path="/pricingpageforindividuals" element={<PricingPage/>} />
            <Route path="/refund" element={<RefundPolicy/>} />
            <Route path="/contact-us" element={<ContactUs/>}/> 
            <Route path="/outputwindow" element={<OutPutWindow />} /> 
            <Route path="/passwordEncrypt" element={<PasswordOfProduct/>}/>
            <Route path='/passwordEncryptForUrl' element={<PasswordOfProductForUrlPassword/>}/>
            <Route path='/passwordEncryptForLink' element={<PasswordOfProductForLinkPassword/>}/>
            <Route path='/passwordEncryptForMeeting' element={<PasswordOfProductForMeetingPassword/>}/>
            <Route path='/ClientRegistrationForm' element={<ClientRegistraionForm/>}/>
            <Route path='/QrCodeGenerationform' element={<QrCodeGenerationForm/>}/>
            <Route path='/ThankyouMessage' element={<ThankYouMessage/>}/>
            <Route path='/aboutus' element={<AboutUsHomePage/>}/>
            <Route path='/comingsoon' element={<ComingSoon/>}/>
            <Route path='/QrCustomization' element={<CustomizationOptions/>}/>
            <Route path='/outputwindowforMenu' element={<OutPutWindowForMenu/>}/>
            <Route path='/outputwindowforBussinessCard' element={<OutputwindowforBussinessCard/>}/>
            <Route path='/passwordEncryptForMenu' element={<PasswordOfProductForMenu/>}/>
            <Route path='/passwordEncryptForBussinessCard' element={<PasswordOfProductForBussinessCard/>}/>
            <Route path='/eventoutputwindow' element={<EventOutputWindow/>}/>
            <Route path='/eventPasswordEncrypt' element={<PasswordOfProductForEvent/>}/>
            <Route path='/textoutputwindow' element={<TextOutputWindow/>}/>
            <Route path='/textPasswordEncrypt' element={<PasswordOfProductForText/>}/>
            <Route path='/outputwindowforDiscount' element={<OutPutWindowForDiscount/>}/>
            <Route path='/passwordEncryptForDiscountCard' element= {<PasswordOfProductForDiscountCard/>}/>
            <Route path='/smsoutputwindow' element={<SmsOutputWindow/>}/>
            <Route path='/smsPasswordEncrypt' element={<PasswordOfProductForSms/>}/>
            <Route path='/petoutputwindow' element={<PetOutputWindow/>}/>
            <Route path='/petPasswordEncrypt' element={<PasswordOfProductForPet/>}/>
            <Route path='/kidsoutputwindow' element={<KidsOutputWindow/>}/>
            <Route path='/kidPasswordEncrypt' element={<PasswordOfProductForKids/>}/>
            <Route path='/vehicleoutputwindow' element={<VehicleOutputWindow/>}/>
            <Route path='/vehiclePasswordEncrypt' element={<PasswordOfProductForVehicle/>}/>
            <Route path='/propertyoutputwindow' element={<PropertyOutputWindow/>}/>
            <Route path='/propertyPasswordEncrypt' element={<PasswordOfProductForProperty/>}/>
            <Route path='/outputwindowforStudentForm' element={<OutPutWindowforStudent/>}/>
            <Route path='/passwordEncryptForStudentForm' element={<PasswordOfProductForStudentFormPassword/>}/>
            <Route path='/outputwindowforBusinessInfo' element={<OutputwindowforBusinessInfo />}/>
            <Route path='/passwordEncryptForBusinessInfo' element={<PasswordOfProductForBusinessInfoPassword/>}/>
          </Routes>
          <Footer/>
        </div>
      </Router>
    </Provider>
    </ErrorBoundry>
  );
}

export default App;
