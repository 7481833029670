import {
    faUser,
    faBagShopping,
    faQrcode,
    faHeadset,
    faShareSquare,
    faSignOut,
    faFolder,
  } from "@fortawesome/free-solid-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import sidebarInfo from "../resources/sidebarInfo.json";
  import { useEffect } from "react";
  import { useDispatch } from "react-redux";
  import { useNavigate, Link, useLocation } from "react-router-dom";
  import { resetProfile } from "../store/userProfile/userProfileActions";
  
  // Create a mapping between icon names in the JSON and the actual FontAwesome icons
  const iconMapping = {
    faUser: faUser,
    faBagShopping: faBagShopping,
    faQrcode: faQrcode,
    faHeadset: faHeadset,
    faShareSquare: faShareSquare,
    faSignOut: faSignOut,
    faFolder: faFolder,
  };
  
  const UserProfielsidebarForMobile = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
  
    useEffect(() => {
      if(profile){
      if (profile.username === "") {
        navigate("/");
      }
      }
    }, [navigate]);
  
    const handleLogout = () => {
      const queryParams = new URLSearchParams(location.search);
      queryParams.delete('token');
      localStorage.removeItem('profile');
      localStorage.removeItem('userProfile');
      localStorage.removeItem("freeMb");

      dispatch(resetProfile());
      navigate('/');
  };
  
    return (
      <div className="flex bg-customBlue w-full h-[90px] rounded-lg shadow-lg p-4 m-4">
        <ul className="list-none flex flex-row">
          {sidebarInfo.map((item, index) => {
            const isActive = location.pathname === item.Link;
            return (
              <li
                key={index}
                className={`flex w-full items-center p-[5px] rounded-md m-[5px] transition-colors duration-300
                                  ${
                                    isActive
                                      ? "bg-white  rounded-full  text-customBlue border-2 border-customBlue"
                                      : "text-white border-2 border-transparent"
                                  }
                              `}
              >
                {item.name === "Logout" ? (
                  <button
                    onClick={handleLogout}
                    className="flex items-center w-full text-white text-[15px] p-4 hover:bg-white hover:text-customBlue rounded"
                  >
                    <FontAwesomeIcon
                      icon={iconMapping[item.Icon]}
                      className="transition-colors p-2 duration-300 hover:bg-white hover:text-customBlue"
                    />
                  </button>
                ) : item.name === "Customer Support" ? (
                  <Link to={"/contact-us"}>
                    <FontAwesomeIcon
                      icon={iconMapping[item.Icon]}
                      className="transition-colors p-1 duration-300 hover:bg-white hover:text-customBlue"
                    />
                  </Link>
                ) : (
                  <Link to={item.Link} className="flex items-center w-full">
                    <FontAwesomeIcon
                      icon={iconMapping[item.Icon]}
                      className={`transition-colors p-2 duration-300 ${
                        isActive ? "text-customBlue" : "text-white"
                      }`}
                    />
                  </Link>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };
  
  export default UserProfielsidebarForMobile;
  