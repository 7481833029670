// src/components/userProfile/userProfileActions.js

import { SET_PROFILE, SET_LOGIN, SET_LOGOUT, RESET_PROFILE } from './userProfileactionTypes';

// Login Action Creator
export const Login = () => ({
    type: SET_LOGIN
});

// Logout Action Creator
export const Logout = () => ({
    type: SET_LOGOUT
});

// Set Profile Action Creator
export const setProfile = (profile) => {
    
    const userProfile = {
            username: profile.username || '',
            email: profile.email|| '',  // Initialize `username` properly
            usagecount: profile.usagecount || 0,
            token: profile.token || '', 
            userid: profile.userid || '', 
            image: profile.image 
    }

    localStorage.setItem(('userProfile'),JSON.stringify(userProfile));
    return {
        type: SET_PROFILE,
        payload: {
            username: profile.username || '',
            email: profile.email|| '',  // Initialize `username` properly
            usagecount: profile.usagecount || 0,
            token: profile.token || '', 
            userid: profile.userid || '', 
            image: profile.image       // Initialize `email` properly
        }

    };
};

export const resetProfile = () => ({
    type: RESET_PROFILE
});
